import "./Values.css";
import img8 from "../static/img-8.png";
import img9 from "../static/img-9.png";
import img10 from "../static/img-10.png";
import img11 from "../static/img-11.png";

function Values() {
  return (
    <div className="values-container">
      <h1>Our Values</h1>
      <div className="values-icons">
        <div className="value-item">
          <img src={img8} alt="Integrity" />
          <p>Integrity</p>
        </div>
        <div className="value-item">
          <img src={img9} alt="Excellence" />
          <p>Excellence</p>
        </div>
        <div className="value-item">
          <img src={img10} alt="Community" />
          <p>Community</p>
        </div>
        <div className="value-item">
          <img src={img11} alt="Innovation" />
          <p>Innovation</p>
        </div>
      </div>
      <div className="join-us">
        <h2>Join Us</h2>
        <p>
          Whether you are a maritime company looking to fill critical positions
          or a candidate ready to embark on an exciting career journey, Jobs
          Maritime is here to help you achieve your goals. Join us today and
          become part of our growing maritime network.
        </p>
      </div>
    </div>
  );
}

export default Values;
