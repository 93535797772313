import React, { useState, useEffect } from "react";
import "./FormEditModal.css";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

function FormEditModal({ modalOpen, closeModal }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const modalDisplay = modalOpen ? "block" : "none";

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    fathersName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    maritalStatus: "",
    email: "",
    mobile: "",
    address: "",
    height: "",
    weight: "",
    education: [],
    certificates: [],
    experience: [],
    postedBy: userData?.email, // Set postedBy to user's email from localStorage
  });

  const [educationFields, setEducationFields] = useState([
    { institute: "", degree: "", fromDate: "", toDate: "" },
  ]);
  const [certificateFields, setCertificateFields] = useState([
    { issuedDate: "", validUntil: "", issuedPlace: "", certificateID: "" },
  ]);
  const [experienceFields, setExperienceFields] = useState([
    { rank: "", vesselName: "", vesselType: "", company: "", engineType: "" },
  ]);

  const addEducationField = () => {
    const updatedFields = [
      ...educationFields,
      { institute: "", degree: "", fromDate: "", toDate: "" },
    ];
    setEducationFields(updatedFields);
    setFormData({ ...formData, education: updatedFields });
  };

  const addCertificateField = () => {
    const updatedFields = [
      ...certificateFields,
      { issuedDate: "", validUntil: "", issuedPlace: "", certificateID: "" },
    ];
    setCertificateFields(updatedFields);
    setFormData({ ...formData, certificates: updatedFields });
  };

  const addExperienceField = () => {
    const updatedFields = [
      ...experienceFields,
      { rank: "", vesselName: "", vesselType: "", company: "", engineType: "" },
    ];
    setExperienceFields(updatedFields);
    setFormData({ ...formData, experience: updatedFields });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/applicantFormDetails/${userData.email}`
        );
        // setFormData(response.data);
        const {
          education,
          certificates,
          experience,
          ...formDataWithoutEducation
        } = response.data;
        setFormData(formDataWithoutEducation);
        setEducationFields(education);
        setCertificateFields(certificates);
        setExperienceFields(experience);
      } catch (error) {
        console.error("Error fetching form data:", error);
        if (error.response) {
          if (error.response.status === 404) {
            toast.error(
              "Form data not found. Complete your information before updating.",
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
          } else {
            toast.error("Failed to fetch form data. Please try again.", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          toast.error("Failed to fetch form data. Please try again.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    };

    if (modalOpen) {
      fetchData();
    }
  }, [modalOpen]);

  const handleSave = async () => {
    const updatedFormData = {
      ...formData,
      education: educationFields,
      certificates: certificateFields,
      experience: experienceFields,
    };

    try {
      const response = await axios.put(
        `${apiUrl}/api/applicantFormDetails/${userData.email}`,
        updatedFormData
      );

      if (response.status === 200) {
        // toast.success("Form data updated successfully!", {
        //   position: "top-center",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        toast.success("Form data updated successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setFormData({
          name: "",
          lastName: "",
          fathersName: "",
          dateOfBirth: "",
          placeOfBirth: "",
          maritalStatus: "",
          email: "",
          mobile: "",
          address: "",
          height: "",
          weight: "",
          education: [],
          certificates: [],
          experience: [],
          postedBy: userData?.email,
        });
        setEducationFields([
          { institute: "", degree: "", fromDate: "", toDate: "" },
        ]);
        setCertificateFields([
          {
            issuedDate: "",
            validUntil: "",
            issuedPlace: "",
            certificateID: "",
          },
        ]);
        setExperienceFields([
          {
            rank: "",
            vesselName: "",
            vesselType: "",
            company: "",
            engineType: "",
          },
        ]);
      }
    } catch (error) {
      console.error("Error updating form data:", error);
      // toast.error("Failed to update form data. Please try again.", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
      toast.error("Failed to update form data. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Edit personal information</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Name:</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Last Name:</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your last name"
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData({ ...formData, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Father's Name:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your father's name"
                      value={formData.fathersName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fathersName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Date of Birth:
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="date"
                      className="form-control"
                      value={formData.dateOfBirth}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          dateOfBirth: e.target.value,
                        })
                      }
                    />
                  </div>
                  <label className="col-sm-3 col-form-label">
                    Place of Birth:
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your place of birth"
                      value={formData.placeOfBirth}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          placeOfBirth: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Marital Status:
                  </label>
                  <div className="col-sm-3">
                    <select
                      className="form-select"
                      value={formData.maritalStatus}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          maritalStatus: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                      <option value="widowed">Widowed</option>
                    </select>
                  </div>
                  <label className="col-sm-3 col-form-label">E-Mail:</label>
                  <div className="col-sm-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Mobile:</label>
                  <div className="col-sm-3">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      value={formData.mobile}
                      onChange={(e) =>
                        setFormData({ ...formData, mobile: e.target.value })
                      }
                    />
                  </div>
                  <label className="col-sm-3 col-form-label">Address:</label>
                  <div className="col-sm-3">
                    <textarea
                      className="form-control"
                      placeholder="Enter your address"
                      value={formData.address}
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Height:</label>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter your height"
                      value={formData.height}
                      onChange={(e) =>
                        setFormData({ ...formData, height: e.target.value })
                      }
                    />
                  </div>
                  <label className="col-sm-3 col-form-label">Weight:</label>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter your weight"
                      value={formData.weight}
                      onChange={(e) =>
                        setFormData({ ...formData, weight: e.target.value })
                      }
                    />
                  </div>
                  {/* Education */}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      EDUCATION:
                    </label>
                    <div className="col-sm-9">
                      {educationFields.map((field, index) => (
                        <div className="row mb-3" key={index}>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Institute"
                              value={field.institute}
                              onChange={(e) => {
                                const updatedFields = [...educationFields];
                                updatedFields[index].institute = e.target.value;
                                setEducationFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Stream/Degree/Course"
                              value={field.degree}
                              onChange={(e) => {
                                const updatedFields = [...educationFields];
                                updatedFields[index].degree = e.target.value;
                                setEducationFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Year from"
                              value={field.fromDate}
                              onChange={(e) => {
                                const updatedFields = [...educationFields];
                                updatedFields[index].fromDate = e.target.value;
                                setEducationFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Year to"
                              value={field.toDate}
                              onChange={(e) => {
                                const updatedFields = [...educationFields];
                                updatedFields[index].toDate = e.target.value;
                                setEducationFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addEducationField}
                      >
                        Add Education
                      </button>
                    </div>
                  </div>

                  {/* Certificates */}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      CERTIFICATE:
                    </label>
                    <div className="col-sm-9">
                      {certificateFields.map((field, index) => (
                        <div className="row mb-3" key={index}>
                          <div className="col-sm-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Issued Date"
                              value={field.issuedDate}
                              onChange={(e) => {
                                const updatedFields = [...certificateFields];
                                updatedFields[index].issuedDate =
                                  e.target.value;
                                setCertificateFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Valid Until"
                              value={field.validUntil}
                              onChange={(e) => {
                                const updatedFields = [...certificateFields];
                                updatedFields[index].validUntil =
                                  e.target.value;
                                setCertificateFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Issued Place"
                              value={field.issuedPlace}
                              onChange={(e) => {
                                const updatedFields = [...certificateFields];
                                updatedFields[index].issuedPlace =
                                  e.target.value;
                                setCertificateFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Certificate ID"
                              value={field.certificateID}
                              onChange={(e) => {
                                const updatedFields = [...certificateFields];
                                updatedFields[index].certificateID =
                                  e.target.value;
                                setCertificateFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addCertificateField}
                      >
                        Add Certificate
                      </button>
                    </div>
                  </div>

                  {/* Experience */}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      EXPERIENCE:
                    </label>
                    <div className="col-sm-9">
                      {experienceFields.map((field, index) => (
                        <div className="row mb-3" key={index}>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Rank"
                              value={field.rank}
                              onChange={(e) => {
                                const updatedFields = [...experienceFields];
                                updatedFields[index].rank = e.target.value;
                                setExperienceFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name of Vessel"
                              value={field.vesselName}
                              onChange={(e) => {
                                const updatedFields = [...experienceFields];
                                updatedFields[index].vesselName =
                                  e.target.value;
                                setExperienceFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type of vessel"
                              value={field.vesselType}
                              onChange={(e) => {
                                const updatedFields = [...experienceFields];
                                updatedFields[index].vesselType =
                                  e.target.value;
                                setExperienceFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Shipping Company / Crewing Agency"
                              value={field.company}
                              onChange={(e) => {
                                const updatedFields = [...experienceFields];
                                updatedFields[index].company = e.target.value;
                                setExperienceFields(updatedFields);
                              }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Engine Type"
                              value={field.engineType}
                              onChange={(e) => {
                                const updatedFields = [...experienceFields];
                                updatedFields[index].engineType =
                                  e.target.value;
                                setExperienceFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addExperienceField}
                      >
                        Add Experience
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
      /> */}
      </div>
    </>
  );
}

export default FormEditModal;
