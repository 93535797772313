import React, { useState, useEffect } from "react";
import { PiWifiSlash } from "react-icons/pi";
import "./NetworkStatus.css"; // Make sure to include a CSS file for custom styling

function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    !isOnline && (
      <div className="network-status-overlay">
        <div className="network-status-dialog">
          <PiWifiSlash className="network-icon" />
          <h2>Connection Lost</h2>
          <p>
            You are currently offline. Please check your network connection.
          </p>
        </div>
      </div>
    )
  );
}

export default NetworkStatus;
