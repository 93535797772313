import "./Hero.css";
import img1 from "../static/img-1.png";

function Hero() {
  return (
    <div className="Hero-Img">
      <img src={img1} alt="Hero" />
    </div>
  );
}

export default Hero;
