// import React, { useState, useEffect } from "react";
// import NavbarApplicant from "../../Components/NavbarApplicant/NavbarApplicant";
// import "./Applicant.css";

// function Applicant() {
//   const [showModal, setShowModal] = useState(false); // Initialize showModal state to false
//   const [selectedJobs, setSelectedJobs] = useState([]);

//   useEffect(() => {
//     const isModalOpenedBefore = localStorage.getItem("isModalOpenedBefore");
//     if (!isModalOpenedBefore) {
//       setShowModal(true); // If the modal has not been opened before, set showModal to true
//     }
//   }, []); // Run this effect only once when the component mounts

// const jobsList = [
//   "Captain",
//   "Chief Officer (First Mate)",
//   "Second Officer (Navigation Officer)",
//   "Third Officer (Deck Officer)",
//   "Deck Cadet",
//   "Chief Engineer",
//   "Second Engineer",
//   "Third Engineer",
//   "Engineering Cadet",
//   "Electro-Technical Officer (ETO)",
//   "Able Seaman (AB)",
//   "Ordinary Seaman (OS)",
//   "Boatswain (Bosun)",
//   "Pumpman",
//   "Fitter",
//   "Motorman",
//   "Electrician",
//   "Chief Steward",
//   "Steward",
//   "Cook",
//   "Messman",
//   "Ship Agent",
//   "Ship Broker",
//   "Marine Surveyor",
//   "Port Captain",
//   "Harbor Pilot",
//   "Tug Master",
//   "Marine Superintendent",
//   "Marine Mechanic",
//   "Ship Chandler",
//   "Crane Operator",
//   "Ship Inspector",
//   "Marine Biologist (for research vessels)",
//   "Salvage Master",
//   "Shipyard Worker",
//   "Naval Architect",
//   "Marine Electrician",
//   "Marine Welder",
//   "Marine Painter",
//   "Marine Carpenter",
//   "Ship Designer",
//   "Marine Safety Officer",
//   "Marine Environmental Specialist",
//   "Maritime Lawyer",
//   "Port Engineer",
//   "Marine Logistics Coordinator",
//   "Marine Traffic Controller",
//   "Marine Operations Manager",
//   "Marine Insurance Underwriter",
//   "Marine Communications Officer"
// ];

//   const toggleModal = () => {
//     setShowModal(!showModal);
//     localStorage.setItem("isModalOpenedBefore", "true"); // Set the flag in local storage indicating that the modal has been opened
//   };

//   const handleJobSelection = (e) => {
//     const value = e.target.value;
//     if (!selectedJobs.includes(value)) {
//       setSelectedJobs([...selectedJobs, value]);
//     }
//   };

//   return (
//     <>
//       <NavbarApplicant />
//       <div className="applicant-page">
//         {/* Bootstrap Modal */}
//         {showModal && (
//           <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
//             <div className="modal-dialog modal-dialog-centered" role="document">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title">Select Job Preferences</h5>
//                   <button type="button" className="close" onClick={toggleModal}>
//                     <span aria-hidden="true">&times;</span>
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <select
//                     multiple
//                     className="form-control"
//                     onChange={handleJobSelection}
//                   >
//                     {jobsList.map((job, index) => (
//                       <option key={index} value={job}>{job}</option>
//                     ))}
//                   </select>
//                 </div>
//                 <div className="modal-footer">
//                   <button type="button" className="btn btn-primary" onClick={toggleModal}>Save Preferences</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}

//         <div className="row">
//           <div className="col-lg-6 col-12">
//             <div className="row">
//               <div className="col-12 post-form-section">
//                 <div className="post-form-card">
//                   <h3>Post Application</h3>
//                 </div>
//               </div>
// <div className="col-12 applied-jobs-section">
//   <h4>Applied Jobs</h4>
//   <ul>
//     {selectedJobs.map((job, index) => (
//       <li key={index}>{job}</li>
//     ))}
//   </ul>
// </div>
//             </div>
//           </div>
//           <div className="col-lg-6 col-12">
//             <div className="jobs-section"></div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Applicant;

import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarApplicant from "../../Components/NavbarApplicant/NavbarApplicant";
import "./Applicant.css";
import FormPostModal from "./FormPostModal/FormPostModal";
import CVPostModal from "./CVPostModal/CVPostModal";
import { FcExpand } from "react-icons/fc";
import ApplicantJobCard from "./ApplicantJobCard/ApplicantJobCard";
import ApplicantLinkJobCard from "./ApplicantLinkJobCard/ApplicantLinkJobCard";
import ApplicantPdfJobCard from "./ApplicantPdfJobCard/ApplicantPdfJobCard";
import AppliedJobs from "./AppliedJobs/AppliedJobs";
import { Tooltip } from "react-tooltip";
import ApplicantProfilePicture from "./ApplicantProfilePicture/ApplicantProfilePicture";
import CVPostOCRModal from "./CVPostModal/CVPostOCRModal";
import ApplicantFooter from "../../Components/Footer/ApplicantFooter";
const apiUrl = process.env.REACT_APP_API_URL;

function Applicant() {
  const [showModal, setShowModal] = useState(false);
  const [selectedJobsPreferences, setselectedJobsPreferences] = useState([]);
  const [jobsPreferencesList, setJobsPreferencesList] = useState([]);
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData?.email;
  const [modalOpen, setModalOpen] = useState(false);
  const [isPageBlurred, setIsPageBlurred] = useState(false);
  const [modalTwoOpen, setModalTwoOpen] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [allLinkJobs, setAllLinkJobs] = useState([]);
  const [allPdfJobs, setAllPdfJobs] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [jobDivTitle, setJobDivTitle] = useState("All Jobs");

  const jobsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [profileModalOpen, setProfileModalOpen] = useState(false);

  useEffect(() => {
    fetchAllJobs();
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      // If search query is empty, fetch all jobs again
      fetchAllJobs();
    }
  }, [searchQuery]);

  const fetchAllJobs = async () => {
    try {
      const jobListingsResponse = await fetch(`${apiUrl}/api/joblistings`);
      const jobListingsData = await jobListingsResponse.json();

      const linkPostsResponse = await fetch(`${apiUrl}/api/linkposts`);
      const linkPostsData = await linkPostsResponse.json();

      const pdfPostsResponse = await fetch(`${apiUrl}/api/upload-pdf`);
      const pdfPostsData = await pdfPostsResponse.json();

      const mergedData = [...jobListingsData];
      mergedData.sort(
        (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
      );
      setAllJobs(mergedData);

      const mergedLinksData = [...linkPostsData];
      mergedLinksData.sort(
        (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
      );
      setAllLinkJobs(mergedLinksData);

      const mergedPdfData = [...pdfPostsData];
      mergedPdfData.sort(
        (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
      );
      setAllPdfJobs(mergedPdfData);
    } catch (error) {
      console.error("Error fetching job listings and link posts:", error);
    }
  };

  useEffect(() => {
    if (!userId) return;

    axios
      .get(`${apiUrl}/api/applicant-preferences/${userId}`)
      .then((res) => {
        setShowModal(false);
      })
      .catch((err) => {
        setShowModal(true);
        console.error(err);
      });

    setJobsPreferencesList([
      "Captain",
      "Chief Officer (First Mate)",
      "Second Officer (Navigation Officer)",
      "Third Officer (Deck Officer)",
      "Deck Cadet",
      "Chief Engineer",
      "Second Engineer",
      "Third Engineer",
      "Engineering Cadet",
      "Electro-Technical Officer (ETO)",
      "Able Seaman (AB)",
      "Ordinary Seaman (OS)",
      "Boatswain (Bosun)",
      "Pumpman",
      "Fitter",
      "Motorman",
      "Electrician",
      "Chief Steward",
      "Steward",
      "Cook",
      "Messman",
      "Ship Agent",
      "Ship Broker",
      "Marine Surveyor",
      "Port Captain",
      "Harbor Pilot",
      "Tug Master",
      "Marine Superintendent",
      "Marine Mechanic",
      "Ship Chandler",
      "Crane Operator",
      "Ship Inspector",
      "Marine Biologist (for research vessels)",
      "Salvage Master",
      "Shipyard Worker",
      "Naval Architect",
      "Marine Electrician",
      "Marine Welder",
      "Marine Painter",
      "Marine Carpenter",
      "Ship Designer",
      "Marine Safety Officer",
      "Marine Environmental Specialist",
      "Maritime Lawyer",
      "Port Engineer",
      "Marine Logistics Coordinator",
      "Marine Traffic Controller",
      "Marine Operations Manager",
      "Marine Insurance Underwriter",
      "Marine Communications Officer",
    ]);
  }, [userId]);

  useEffect(() => {
    // Filter all jobs based on search query
    const filteredJobs = allJobs.filter((job) =>
      job.jobTitle && job.companyName
        ? job.jobTitle.toLowerCase().includes(searchQuery) ||
          job.companyName.toLowerCase().includes(searchQuery)
        : false
    );
    setAllJobs(filteredJobs);

    // Filter link jobs based on search query
    const filteredLinkJobs = allLinkJobs.filter((job) =>
      job.jobTitle && job.companyName
        ? job.jobTitle.toLowerCase().includes(searchQuery) ||
          job.companyName.toLowerCase().includes(searchQuery)
        : false
    );
    setAllLinkJobs(filteredLinkJobs);

    // Filter PDF jobs based on search query
    // const filteredPdfJobs = allPdfJobs.filter((job) =>
    //   job.jobTitle && job.companyName
    //     ? job.jobTitle.toLowerCase().includes(searchQuery) ||
    //       job.companyName.toLowerCase().includes(searchQuery)
    //     : false
    // );
    const searchQueryLower = searchQuery.toLowerCase();
    const filteredPdfJobs = allPdfJobs.filter((job) => {
      const jobTitle = job.jobTitle ? job.jobTitle.toLowerCase() : "";
      const companyName = job.companyName ? job.companyName.toLowerCase() : "";
      return (
        jobTitle.includes(searchQueryLower) ||
        companyName.includes(searchQueryLower)
      );
    });
    setAllPdfJobs(filteredPdfJobs);
  }, [searchQuery]);

  const toggleModal = () => setShowModal(!showModal);

  const handleJobSelection = (e) => {
    const value = e.target.value;
    if (!selectedJobsPreferences.includes(value)) {
      setselectedJobsPreferences([...selectedJobsPreferences, value]);
    }
  };

  const removeJob = (jobToRemove) => {
    setselectedJobsPreferences(
      selectedJobsPreferences.filter((job) => job !== jobToRemove)
    );
  };

  const savePreferences = () => {
    axios
      .post(`${apiUrl}/api/applicant-preferences`, {
        user: userId,
        jobPreferences: selectedJobsPreferences,
      })
      .then((res) => {
        setShowModal(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openModal = () => {
    setModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsPageBlurred(false);
  };

  const openModalTwo = () => {
    setModalTwoOpen(true);
    setIsPageBlurred(true);
  };

  const closeModalTwo = () => {
    setModalTwoOpen(false);
    setIsPageBlurred(false);
  };

  const openProfileModal = () => {
    setProfileModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeProfileModal = () => {
    setProfileModalOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && modalOpen) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && modalTwoOpen) {
        closeModalTwo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalTwoOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && profileModalOpen) {
        closeProfileModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [profileModalOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const filterJobsByPreferences = () => {
    setJobDivTitle("Preferred Jobs");

    if (!userId) return;

    axios
      .get(`${apiUrl}/api/applicant-preferences/${userId}`)
      .then((res) => {
        const userPreferences = res.data.jobPreferences;

        // Filter job listings based on user preferences
        const filteredJobs = allJobs.filter((job) =>
          userPreferences.includes(job.jobTitle)
        );
        setAllJobs(filteredJobs);

        // Filter link posts based on user preferences
        const filteredLinkJobs = allLinkJobs.filter((job) =>
          userPreferences.includes(job.jobTitle)
        );
        setAllLinkJobs(filteredLinkJobs);

        // Filter PDF posts based on user preferences
        const filteredPdfJobs = allPdfJobs.filter((job) =>
          userPreferences.includes(job.jobTitle)
        );
        setAllPdfJobs(filteredPdfJobs);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const shouldRenderPagination =
  //   allJobs.length > 0 && allLinkJobs.length > 0 && allPdfJobs.length > 0;

  // const totalJobs = allJobs.length + allLinkJobs.length + allPdfJobs.length;
  // const totalPages = Math.ceil(totalJobs / jobsPerPage);

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   window.scrollTo({
  //     top: document.querySelector(".jobs-section-elements").offsetTop,
  //     behavior: "smooth",
  //   });
  // };

  // const renderJobCards = (jobs, cardComponent) => {
  //   const startIndex = (currentPage - 1) * jobsPerPage;
  //   const endIndex = Math.min(startIndex + jobsPerPage, jobs.length);

  //   const jobCards = [];
  //   for (let i = startIndex; i < endIndex; i++) {
  //     if (i < jobs.length) {
  //       jobCards.push(
  //         <React.Fragment key={i}>
  //           {React.createElement(cardComponent, { props: jobs[i] })}
  //         </React.Fragment>
  //       );
  //     }
  //   }

  //   return jobCards;
  // };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <>
      <NavbarApplicant />
      <div className={isPageBlurred ? "blur-background" : "applicant-page"}>
        {/* Bootstrap Modal */}
        {showModal && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content applicant-modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Select Job Preferences</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={toggleModal}
                  ></button>
                </div>
                <div className="modal-body applicant-modal-body">
                  <select
                    multiple
                    className="form-control"
                    onChange={handleJobSelection}
                  >
                    {jobsPreferencesList.map((job, index) => (
                      <option key={index} value={job}>
                        {job}
                      </option>
                    ))}
                  </select>
                  <div className="selected-jobs-container">
                    {selectedJobsPreferences.map((job, index) => (
                      <div key={index} className="selected-job-card">
                        <span className="selected-job-name">{job}</span>
                        <button
                          className="remove-btn"
                          onClick={() => removeJob(job)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={savePreferences}
                  >
                    Save Preferences
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-12">
                <div className="post-form-section">
                  <div className="post-form-card">
                    <div>
                      <h3>Post Application</h3>
                      <p>
                        Share your details or upload your resume to get started!
                      </p>
                    </div>
                    <div className="buttons-section">
                      <button className="btn" onClick={openModal}>
                        Post Form
                      </button>
                      <button className="btn" onClick={openModalTwo}>
                        Post CV
                      </button>
                      <button className="btn" onClick={openProfileModal}>
                        Profile Picture
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="applied-jobs-section">
                  <h4 className="m-2 mb-0 mt-0" style={{ userSelect: "none" }}>
                    Applied Jobs
                  </h4>
                  <AppliedJobs userEmail={userId} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="jobs-section">
              <div className="jobs-section-title">
                <div className="jobs-section-title-text">
                  <h3>{jobDivTitle}</h3>
                </div>
                <div
                  className="jobs-section-title-icon-applicant"
                  onClick={toggleDropdown}
                  data-tooltip-id="my-tooltip2"
                  data-tooltip-content="View by Preference"
                  data-tooltip-delay-show={10}
                  data-tooltip-place="top"
                >
                  <FcExpand />
                  <Tooltip id="my-tooltip2" effect="solid" />
                  {dropdownOpen && (
                    <div
                      className="dropdown-menu"
                      style={{
                        display: "block",
                        position: "absolute",
                        zIndex: 1000,
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "0.5rem",
                        boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
                        transition: "opacity 0.3s ease-in-out",
                        minWidth: "150px",
                      }}
                    >
                      <div
                        className="dropdown-item dropdown-element"
                        onClick={() => filterJobsByPreferences()}
                      >
                        As per preferences
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="search-bar-div">
                <div className="search">
                  <input
                    placeholder="Search..."
                    // placeholder="Job Title, Position, Company"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="input-search-bar"
                  />
                  <button type="submit">Search</button>
                </div>
              </div>
              <div className="jobs-section-elements">
                {allJobs.map((job) => (
                  <ApplicantJobCard key={job._id} props={job} />
                ))}
                {allLinkJobs.map((job) => (
                  <ApplicantLinkJobCard key={job._id} props={job} />
                ))}
                {allPdfJobs.map((job) => (
                  <ApplicantPdfJobCard key={job._id} props={job} />
                ))}

                {/* {allJobs.length > 0 &&
                  renderJobCards(allJobs, ApplicantJobCard)}
                
                {allLinkJobs.length > 0 &&
                  renderJobCards(allLinkJobs, ApplicantLinkJobCard)}
                
                {allPdfJobs.length > 0 &&
                  renderJobCards(allPdfJobs, ApplicantPdfJobCard)}
                {shouldRenderPagination && (
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 && "disabled"
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 && "active"
                          }`}
                          aria-current={
                            currentPage === index + 1 ? "page" : undefined
                          }
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages && "disabled"
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormPostModal modalOpen={modalOpen} closeModal={closeModal} />
      {/* <CVPostModal modalTwoOpen={modalTwoOpen} closeModalTwo={closeModalTwo} /> */}
      <CVPostOCRModal
        modalTwoOpen={modalTwoOpen}
        closeModalTwo={closeModalTwo}
      />
      <ApplicantProfilePicture
        profileModalOpen={profileModalOpen}
        closeProfileModal={closeProfileModal}
      />
      <ApplicantFooter />
    </>
  );
}

export default Applicant;
