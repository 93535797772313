import React from "react";
import Hero from "./Be Own Hero/Hero";
import Vision from "./Our Vision/Vision";
import Values from "./Our Values/Values";
import Team from "./Who Are We/Team";
import NavbarLanding from "../../Components/NavbarLanding/NavbarLanding";

function AboutUs() {
  return (
    <div>
      <NavbarLanding />
      <Hero />
      <Vision />
      <Team />
      <Values />
    </div>
  );
}

export default AboutUs;
