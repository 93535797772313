import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DetailsModal.css"; // Import CSS file for styling
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

function DetailsModal({ modalOpen, closeModal }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userEmail = userData?.email;
  const modalDisplay = modalOpen ? "block" : "none";
  const [formData, setFormData] = useState({
    companyName: "",
    industrySector: "",
    companyDescription: "",
    companyWebsite: "",
    contactEmail: "",
    companyAddress: "",
    city: "",
    pincode: "",
    country: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/company-details/${userEmail}`,
          {
            headers: {
              email: userEmail,
            },
          }
        );
        const userData = response.data; // Assuming response contains all user details
        console.log(userData); // Log user data to console
        setFormData(userData);
      } catch (error) {
        console.error("Error fetching user details:", error);
        if (error.response && error.response.status === 404) {
          toast.error("Company details not found for the provided email", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          toast.error(
            "Failed to fetch company details. Please try again later.",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        }
      }
    };

    if (modalOpen) {
      fetchUserData();
    }
  }, [modalOpen, userEmail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("companyName", formData.companyName);
      formDataToSend.append("industrySector", formData.industrySector);
      formDataToSend.append("companyDescription", formData.companyDescription);
      formDataToSend.append("companyWebsite", formData.companyWebsite);
      formDataToSend.append("contactEmail", formData.contactEmail);
      formDataToSend.append("city", formData.city);
      formDataToSend.append("pincode", formData.pincode);
      formDataToSend.append("country", formData.country);

      await axios.put(`${apiUrl}/api/company-details/${userEmail}`, formData, {
        headers: {
          "Content-Type": "application/json",
          email: userEmail,
        },
      });
      toast.success("Details updated successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error updating details:", error);
      toast.error("Failed to update details. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Edit Details</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Industry Sector</label>
                  <input
                    type="text"
                    className="form-control"
                    name="industrySector"
                    value={formData.industrySector}
                    onChange={handleChange}
                  />
                </div>
                {/* Add similar input fields for other details */}
                <div className="mb-3">
                  <label className="form-label">Company Description</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyDescription"
                    value={formData.companyDescription}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Company Website</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyWebsite"
                    value={formData.companyWebsite}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Contact Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Company Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyAddress"
                    value={formData.companyAddress}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Pincode</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsModal;
