import React, { useEffect, useState } from "react";
import "./AppliedJobs.css";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

function AppliedJobs({ userEmail }) {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [jobDetails, setJobDetails] = useState([]);

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      try {
        // Fetch the applied job IDs from the backend API
        const response = await fetch(
          `${apiUrl}/api/applied-jobs/${userEmail}`
        );
        const data = await response.json();

        // Update the state with the fetched job IDs
        setAppliedJobs(data);
      } catch (error) {
        console.error("Error fetching applied jobs:", error);
      }
    };

    fetchAppliedJobs();
  }, [userEmail]);

  useEffect(() => {
    const fetchAllJobDetails = async () => {
      try {
        // Fetch all job details from the three APIs
        const [jobListingsRes, linkPostsRes, pdfPostsRes] = await Promise.all([
          fetch(`${apiUrl}/api/joblistings`),
          fetch(`${apiUrl}/api/linkposts`),
          fetch(`${apiUrl}/api/upload-pdf`),
        ]);

        // Parse the responses
        const [jobListings, linkPosts, pdfPosts] = await Promise.all([
          jobListingsRes.json(),
          linkPostsRes.json(),
          pdfPostsRes.json(),
        ]);

        // Filter job details based on the applied job IDs
        const filteredJobDetails = jobListings
          .concat(linkPosts, pdfPosts) // Merge all job details
          .filter((detail) => appliedJobs.includes(detail._id)); // Filter based on applied job IDs

        // Update the state with the filtered job details
        setJobDetails(filteredJobDetails);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchAllJobDetails();
  }, [appliedJobs]);

  const formatDeadlineDate = (deadline) => {
    const date = new Date(deadline);
    const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
    return formattedDate;
  };

  // Function to handle withdrawal of applied job
  const withdrawJob = async (jobId) => {
    // Confirm withdrawal action
    if (
      window.confirm(
        "Are you sure you want to withdraw your application? This action cannot be undone."
      )
    ) {
      try {
        // Send a DELETE request to the backend API to delete the applied job
        await fetch(
          `${apiUrl}/api/apply-jobs/${userEmail}/${jobId}`,
          {
            method: "DELETE",
          }
        );

        toast.success("Application withdrawn successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Update the state to remove the withdrawn job
        setJobDetails((prevJobDetails) =>
          prevJobDetails.filter((job) => job._id !== jobId)
        );
      } catch (error) {
        console.error("Error withdrawing applied job:", error);
        toast.error("Failed to withdraw application. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          {jobDetails.map((detail) => (
            <div className="col-12 mb-4" key={detail._id}>
              <div className="applied-card-body">
                <div className="card-body">
                  <h5 className="card-title">{detail.jobTitle}</h5>
                  <div className="card-text-details mt-3">
                    <div>
                      <p className="">
                        <strong>Deadline:</strong>{" "}
                        {formatDeadlineDate(detail.applicationDeadline)}
                      </p>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => withdrawJob(detail._id)}
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        containerId="containerApplicantAppliedJob"
      /> */}
    </div>
  );
}

export default AppliedJobs;
