// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import NavbarApplicant from "../../../../Components/NavbarApplicant/NavbarApplicant";
// import "./ApplicantPdfJobDetailsPage.css";
// import { FaDownload } from "react-icons/fa6";
// import { toast, ToastContainer } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";

// function PdfDetailsPage() {
//   const { id } = useParams();
//   const [pdfDetails, setPdfDetails] = useState(null);
//   const navigate = useNavigate();
//   const [jobPostedBy, setJobPostedBy] = useState("");

//   const [companyInfo, setCompanyInfo] = useState({
//     companyName: "",
//     industrySector: "",
//     companyDescription: "",
//     companyWebsite: "",
//     contactEmail: "",
//     companyAddress: "",
//     city: "",
//     pincode: "",
//     country: "",
//   });
//   const [companyJobTitle, setCompanyJobTitle] = useState("");
//   const userDataString = localStorage.getItem("user");
//   const userData = JSON.parse(userDataString);
//   const applicantName = userData?.fname + " " + userData?.lname;

//   const fetchPdfDetails = async () => {
//     try {
//       const response = await fetch(
//         `http://localhost:5000/api/upload-pdf/${id}`
//       );
//       const data = await response.json();
//       setPdfDetails(data);
//       setJobPostedBy(data[0].postedBy);
//       setCompanyJobTitle(data[0].jobTitle);
//     } catch (error) {
//       console.error("Error fetching PDF details:", error);
//     }
//   };

//   useEffect(() => {
//     fetchPdfDetails();

//     async function fetchCompanyDetails() {
//       try {
//         const response = await fetch(
//           `http://localhost:5000/api/company-details/${jobPostedBy}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch company details");
//         }
//         const companyDetails = await response.json();
//         setCompanyInfo(companyDetails);
//       } catch (error) {
//         console.error("Error fetching company details:", error);
//         // toast.error(
//         //   "Failed to fetch company details. Please try again later.",
//         //   {
//         //     position: "top-center",
//         //     autoClose: 3000,
//         //     hideProgressBar: false,
//         //     closeOnClick: true,
//         //     pauseOnHover: true,
//         //     draggable: true,
//         //     progress: undefined,
//         //     theme: "dark",
//         //   }
//         // );
//       }
//     }

//     if (jobPostedBy) {
//       fetchCompanyDetails();
//     }
//   }, [id, jobPostedBy]);

//   if (!pdfDetails) {
//     return <div>Loading...</div>;
//   }

//   const downloadPdf = () => {
//     const pdfData = pdfDetails[0].pdfFile;
//     const blob = new Blob([Uint8Array.from(pdfData.data)], {
//       type: "application/pdf",
//     });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${pdfDetails[0].jobTitle}.pdf`;
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
//     document.body.removeChild(a);
//   };

//   const date = new Date(pdfDetails[0].applicationDeadline);
//   const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
//     month: "long",
//   })} ${date.getFullYear()}`;

//   const handleApply = async () => {
//     const userDataString = localStorage.getItem("user");
//     const userData = JSON.parse(userDataString);
//     const userEmail = userData?.email;
//     const jobId = id; // Assuming id is accessible in this component

//     try {
//       const response = await axios.get("http://localhost:5000/api/upload-cv", {
//         headers: {
//           email: userEmail,
//         },
//       });

//       if (response.data.length === 0) {
//         toast.info("Please upload your CV to apply for jobs", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         return;
//       }

//       await axios.post("http://localhost:5000/api/apply-job", {
//         userEmail,
//         jobId,
//       });
//       await axios.post("http://localhost:5000/api/send-confirmation-email", {
//         userEmail,
//         companyJobTitle,
//         companyInfo,
//         applicantName,
//       });
//       toast.success("Job application successful", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//     } catch (error) {
//       // console.error("Error applying for job:", error);
//       // toast.error("Failed to apply for job. Please try again later.", {
//       //   position: "top-center",
//       //   autoClose: 3000,
//       //   hideProgressBar: false,
//       //   closeOnClick: true,
//       //   pauseOnHover: true,
//       //   draggable: true,
//       //   progress: undefined,
//       //   theme: "dark",
//       // });
//       if (error.response && error.response.status === 400) {
//         toast.info("You have already applied for this job", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       } else {
//         console.error("Error applying for job:", error);
//         toast.error("Failed to apply for job. Please try again later.", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       }
//     }
//   };

//   return (
//     <div className="job-link-details-page">
//       <NavbarApplicant />
//       <div className="pdf-details-section">
//         <div className="alert details-container" role="alert">
//           <div className="row">
//             <div className="col-12 visible-pdf-section">
//               <div className="row">
//                 <div className="col-lg-6 col-md-6 col-12 detail-field">
//                   <br />
//                   <strong>Title - </strong>
//                   {pdfDetails[0].jobTitle}
//                 </div>
//                 <div className="col-lg-6 col-md-6 col-12 detail-field">
//                   <br />
//                   <strong>Job location - </strong>
//                   {pdfDetails[0].jobLocation}
//                 </div>
//                 <div className="col-lg-6 col-md-6 col-12 detail-field">
//                   <br />
//                   <strong>Deadline - </strong>
//                   {formattedDate}
//                 </div>
//                 <div className="col-lg-6 col-md-6 col-12 detail-field">
//                   <br />
//                   <div className=" download-button-div">
//                     <strong>Download PDF - </strong>
//                     <div className="download-button" onClick={downloadPdf}>
//                       <FaDownload />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <br />
//             </div>
//             <div className="col-12">
//               <button
//                 className="delete-button-4"
//                 style={{ backgroundColor: "#46FFA6" }}
//                 onClick={handleApply}
//               >
//                 Apply
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <ToastContainer
//         position="top-center"
//         autoClose={3000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//         containerId="containerApplicantPDFJobDetailsPage"
//       /> */}
//     </div>
//   );
// }

// export default PdfDetailsPage;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavbarApplicant from "../../../../Components/NavbarApplicant/NavbarApplicant";
import "./ApplicantPdfJobDetailsPage.css";
import { FaDownload } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ApplicantPdfJobCard from "../ApplicantPdfJobCard";
import ApplicantFooter from "../../../../Components/Footer/ApplicantFooter";
const apiUrl = process.env.REACT_APP_API_URL;

function PdfDetailsPage() {
  const { id } = useParams();
  const [pdfDetails, setPdfDetails] = useState(null);
  const navigate = useNavigate();
  const [jobPostedBy, setJobPostedBy] = useState("");

  const [allPdfJobs, setAllPdfJobs] = useState([]);

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    industrySector: "",
    companyDescription: "",
    companyWebsite: "",
    contactEmail: "",
    companyAddress: "",
    city: "",
    pincode: "",
    country: "",
  });
  const [companyJobTitle, setCompanyJobTitle] = useState("");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const applicantName = userData?.fname + " " + userData?.lname;

  useEffect(() => {
    fetchAllJobs();
  }, []);

  const fetchAllJobs = async () => {
    try {
      const pdfPostsResponse = await fetch(`${apiUrl}/api/upload-pdf`);
      const pdfPostsData = await pdfPostsResponse.json();
      const mergedPdfData = [...pdfPostsData];
      mergedPdfData.sort(
        (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
      );
      setAllPdfJobs(mergedPdfData);
    } catch (error) {
      console.error("Error fetching job listings and link posts:", error);
    }
  };
  const fetchPdfDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/upload-pdf/${id}`);
      const data = await response.json();
      setPdfDetails(data);
      setJobPostedBy(data[0].postedBy);
      setCompanyJobTitle(data[0].jobTitle);
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    }
  };

  useEffect(() => {
    fetchPdfDetails();

    async function fetchCompanyDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/company-details/${jobPostedBy}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company details");
        }
        const companyDetails = await response.json();
        setCompanyInfo(companyDetails);
      } catch (error) {
        console.error("Error fetching company details:", error);
        // toast.error(
        //   "Failed to fetch company details. Please try again later.",
        //   {
        //     position: "top-center",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "dark",
        //   }
        // );
      }
    }

    if (jobPostedBy) {
      fetchCompanyDetails();
    }
  }, [id, jobPostedBy]);

  if (!pdfDetails) {
    return <div>Loading...</div>;
  }

  const downloadPdf = () => {
    const pdfData = pdfDetails[0].pdfFile;
    const blob = new Blob([Uint8Array.from(pdfData.data)], {
      type: "application/pdf",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${pdfDetails[0].jobTitle}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const date = new Date(pdfDetails[0].applicationDeadline);
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
    month: "long",
  })} ${date.getFullYear()}`;

  const handleApply = async () => {
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userEmail = userData?.email;
    const jobId = id; // Assuming id is accessible in this component

    try {
      const response = await axios.get(`${apiUrl}/api/upload-extracted-cv`, {
        headers: {
          email: userEmail,
        },
      });

      if (response.data.length === 0) {
        toast.info("Please upload your CV to apply for jobs", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      await axios.post(`${apiUrl}/api/apply-job`, {
        userEmail,
        jobId,
      });
      await axios.post(`${apiUrl}/api/send-confirmation-email`, {
        userEmail,
        companyJobTitle,
        companyInfo,
        applicantName,
      });
      toast.success("Job application successful", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      // console.error("Error applying for job:", error);
      // toast.error("Failed to apply for job. Please try again later.", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
      if (error.response && error.response.status === 400) {
        toast.info("You have already applied for this job", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        console.error("Error applying for job:", error);
        toast.error("Failed to apply for job. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  const getSimilarJobs = (
    jobs,
    currentJobTitle,
    currentJobId,
    maxResults = 5
  ) => {
    // Basic similarity function
    const similarity = (a, b) => {
      if (!a || !b) return 0;
      a = a.toLowerCase().split(" ");
      b = b.toLowerCase().split(" ");
      const intersection = a.filter((value) => b.includes(value));
      return intersection.length;
    };

    console.log("Current Job Title:", currentJobTitle);

    // Adding debugging information
    jobs.forEach((job) => {
      console.log(
        `Job ID: ${job._id}, Job Title: ${
          job.jobTitle
        }, Similarity: ${similarity(job.jobTitle, currentJobTitle)}`
      );
    });

    return jobs
      .map((job) => ({
        ...job,
        similarity: similarity(job.jobTitle, currentJobTitle),
      }))
      .filter((job) => job.similarity > 0 && job._id !== currentJobId) // Filter out the current job
      .sort((a, b) => b.similarity - a.similarity)
      .slice(0, maxResults);
  };

  const similarJobs = getSimilarJobs(
    allPdfJobs,
    pdfDetails[0].jobTitle,
    pdfDetails[0]._id,
    2
  );

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: pdfDetails.jobTitle,
          text: `Check out this job: ${pdfDetails.jobTitle} at ${pdfDetails.companyName}`,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      console.error("Web Share API is not supported in your browser.");
    }
  };
  return (
    <>
      <div className="job-link-details-page">
        <NavbarApplicant />
        <div className="pdf-details-section">
          <div className="alert details-container" role="alert">
            <div className="row">
              <div className="col-12 visible-pdf-section">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <strong>Title - </strong>
                    {pdfDetails[0].jobTitle}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <strong>Job location - </strong>
                    {pdfDetails[0].jobLocation}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <strong>Deadline - </strong>
                    {formattedDate}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <div className=" download-button-div">
                      <strong>Download PDF - </strong>
                      <div className="download-button" onClick={downloadPdf}>
                        <FaDownload />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <div className="col-12">
                <button
                  className="delete-button-4"
                  style={{ backgroundColor: "#46FFA6" }}
                  onClick={handleApply}
                >
                  Apply
                </button>
                <button onClick={handleShare} className="delete-button-4">
                  Share
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        containerId="containerApplicantPDFJobDetailsPage"
      /> */}
        <div className="similar-jobs-card">
          <h3>Similar Jobs</h3>
          <div className="similar-jobs-list">
            {similarJobs.length > 0 ? (
              similarJobs.map((similarJob) => (
                <ApplicantPdfJobCard key={similarJob._id} props={similarJob} />
              ))
            ) : (
              <p>No Similar Jobs Available</p>
            )}
          </div>
        </div>
      </div>
      <ApplicantFooter />
    </>
  );
}

export default PdfDetailsPage;
