import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavbarCompany from "../../../../Components/NavbarCompany/NavbarCompany";
import "./PdfDetailsPage.css";
import { FaDownload } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import PdfEditModal from "./PdfEditModal/PdfEditModal";
import { CiCircleRemove } from "react-icons/ci";
import { Tooltip } from "react-tooltip";
import { IoFilterCircleOutline } from "react-icons/io5";
import { FaRegCircleUser } from "react-icons/fa6";
import CompanyFooter from "../../../../Components/Footer/CompanyFooter";
import ApplicantDetailsModal from "../../MatchedCandidate/ApplicantDetailsModal";
const apiUrl = process.env.REACT_APP_API_URL;

function PdfDetailsPage() {
  const { id } = useParams();
  const [pdfDetails, setPdfDetails] = useState(null);
  const [showPdfEditModal, setShowPdfEditModal] = useState(false);
  const navigate = useNavigate();
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [cvPosts, setCvPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [userDetails, setUserDetails] = useState(null);
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const currentUserEmail = userData?.email;
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    industrySector: "",
    companyDescription: "",
    companyWebsite: "",
    contactEmail: "",
    companyAddress: "",
    city: "",
    pincode: "",
    country: "",
  });
  const [profileImages, setProfileImages] = useState({});

  const [isPageBlurred, setIsPageBlurred] = useState(false);
  const [applicantDetailsModalOpen, setApplicantDetailsModalOpen] =
    useState(false);

  const [selectedCvPost, setSelectedCvPost] = useState(null);

  const openApplicantDetailsModal = (cvPost) => {
    setSelectedCvPost(cvPost); // Store the selected CV post
    setApplicantDetailsModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeApplicantDetailsModal = () => {
    setApplicantDetailsModalOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && applicantDetailsModalOpen) {
        closeApplicantDetailsModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [applicantDetailsModalOpen]);

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/user?email=${currentUserEmail}`
        );
        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
        } else {
          console.error("Failed to fetch user details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    fetchUserDetails();
  }, [currentUserEmail]);

  useEffect(() => {
    async function fetchCompanyDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/company-details/${currentUserEmail}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company details");
        }
        const companyDetails = await response.json();
        setCompanyInfo(companyDetails);
      } catch (error) {
        console.error("Error fetching company details:", error);
        toast.error(
          "Failed to fetch company details. Please try again later.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }
    if (currentUserEmail) {
      fetchCompanyDetails();
    }
  }, [currentUserEmail]);

  const fetchPdfDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/upload-pdf/${id}`);
      const data = await response.json();
      setPdfDetails(data);
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    }
  };

  // const fetchAppliedJobs = async () => {
  //   try {
  //     const response = await fetch(`http://localhost:5000/api/apply-job/${id}`);
  //     const data = await response.json();
  //     setAppliedJobs(data);
  //   } catch (error) {
  //     console.error("Error fetching applied jobs:", error);
  //   }
  // };

  // fetchAppliedJobs();

  useEffect(() => {
    fetchPdfDetails();
  }, [id]);

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/apply-job/${id}`);
        const data = await response.json();
        setAppliedJobs(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching applied jobs:", error);
        setLoading(false);
      }
    };

    fetchAppliedJobs();
  }, [id]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && showPdfEditModal) {
        togglePdfEditModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showPdfEditModal]);

  useEffect(() => {
    const fetchCvPosts = async () => {
      try {
        setLoading(true);
        const cvData = [];
        for (const appliedJob of appliedJobs) {
          const response = await fetch(`${apiUrl}/api/upload-extracted-cv`, {
            headers: {
              email: appliedJob,
            },
          });
          const data = await response.json();
          cvData.push(data);
        }
        setCvPosts(cvData);
        // Call the API for each email to fetch profile images
        const profileImagesData = {};
        for (const cvPost of cvData) {
          try {
            const imageResponse = await fetch(
              `${apiUrl}/api/upload-applicant-profile/${cvPost[0].email}`
            );
            if (imageResponse.ok) {
              const blob = await imageResponse.blob();
              profileImagesData[cvPost[0].email] = URL.createObjectURL(blob);
            } else {
              console.error(
                "Failed to fetch profile image:",
                imageResponse.statusText
              );
            }
          } catch (error) {
            console.error("Error fetching applicant profile:", error);
          }
        }
        setProfileImages(profileImagesData);
      } catch (error) {
        console.error("Error fetching CV posts:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching CV posts
      }
    };

    if (appliedJobs.length > 0) {
      fetchCvPosts();
    } else {
      setLoading(false); // Set loading to false if no candidates applied
    }
  }, [appliedJobs]);

  if (!pdfDetails) {
    return <div>Loading...</div>;
  }

  const downloadPdf = () => {
    const pdfData = pdfDetails[0].pdfFile;
    const blob = new Blob([Uint8Array.from(pdfData.data)], {
      type: "application/pdf",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${pdfDetails[0].jobTitle}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const date = new Date(pdfDetails[0]?.applicationDeadline);
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
    month: "long",
  })} ${date.getFullYear()}`;

  const handleDelete = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this PDF post?"
    );

    if (confirmation) {
      try {
        const response = await fetch(`${apiUrl}/api/upload-pdf/${id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          toast.success("Deleted successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          // Redirect to a different page after successful deletion
          navigate("/jobs-posted");
        } else {
          toast.error("Failed to delete job. Please try again later.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } catch (error) {
        console.error("Error deleting PDF:", error);
        toast.error("Failed to delete job. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const togglePdfEditModal = () => {
    setShowPdfEditModal(!showPdfEditModal);
  };

  const handleUpdatePdfDetails = async (updatedDetails) => {
    console.log(updatedDetails);
    try {
      const response = await fetch(`${apiUrl}/api/upload-pdf/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedDetails),
      });

      if (response.ok) {
        toast.success("PDF details updated successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // Refetch the updated PDF details
        fetchPdfDetails();
      } else {
        toast.error("Failed to update PDF details. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error updating PDF details:", error);
      toast.error("Failed to update PDF details. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // Function to download PDF
  const downloadCVPdf = (pdfFile, jobTitle) => {
    const blob = new Blob([Uint8Array.from(pdfFile.data)], {
      type: "application/pdf",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${jobTitle}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Function to open Gmail with pre-filled draft
  const openGmail = (cvPost) => {
    if (!userDetails || !companyInfo) {
      console.error("User details or company info not fetched yet.");
      return;
    }

    const subject = encodeURIComponent(
      `Regarding Your Job Application at ${companyInfo.companyName}`
    );
    const body = encodeURIComponent(`
Dear ${cvPost.name},

I hope this email finds you well. My name is ${userDetails.firstName} ${userDetails.lastName}, and I am a representative from ${companyInfo.companyName}.

I am writing to you regarding your recent application for the ${pdfDetails[0].jobTitle} position at our company. We appreciate your interest in joining our team.

${companyInfo.companyDescription}

You can find more information about our company on our website: ${companyInfo.companyWebsite}

I would like to discuss your application further and potentially schedule an interview. Could you please provide your availability for a meeting?

Looking forward to hearing from you.

Best regards,
${userDetails.firstName} ${userDetails.lastName}
${companyInfo.companyName}
${userDetails.email}`);

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${cvPost.email}&su=${subject}&body=${body}`;
    window.open(gmailUrl, "_blank");
  };

  const removeApplicant = async (userEmail, jobId, cvPost) => {
    if (
      window.confirm(
        "Are you sure you want to reject the applicant? This action cannot be undone."
      )
    ) {
      try {
        const response = await fetch(
          `${apiUrl}/api/apply-jobs/${userEmail}/${jobId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          toast.success("Applicant rejected successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          // Remove the rejected applicant from cvPosts
          // const updatedCvPosts = [...cvPosts]; // Create a copy of cvPosts
          // updatedCvPosts.forEach((cvPostArray, index) => {
          //   updatedCvPosts[index] = cvPostArray.filter(
          //     (cvPost) => cvPost.jobId !== jobId
          //   );
          // });

          // setCvPosts(updatedCvPosts);
          // setCvPosts((prevCvPosts) =>
          //   prevCvPosts.map((cvPostArray) =>
          //     cvPostArray.filter((cvPost) => cvPost.jobId !== jobId)
          //   )
          // );

          // Send rejection email
          const emailResponse = await fetch(
            `${apiUrl}/api/send-rejection-email`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                cvPostEmail: cvPost.email,
                cvPostName: cvPost.name,
                userFirstName: userDetails.firstName,
                userLastName: userDetails.lastName,
                userEmail: userDetails.email,
                companyName: companyInfo.companyName,
                jobTitle: pdfDetails[0].jobTitle,
              }),
            }
          );

          if (!emailResponse.ok) {
            throw new Error("Failed to send rejection email");
          }
          // Refresh the page after 1 second
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          throw new Error("Failed to reject applicant");
        }
      } catch (error) {
        console.error("Error rejecting applicant:", error);
        toast.error("Failed to reject applicant. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <>
      <div className="job-link-details-page">
        <NavbarCompany />
        <div className="pdf-details-section">
          <div className="alert details-container" role="alert">
            <div className="row">
              <div className="col-12 visible-pdf-section">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <strong>Title - </strong>
                    {pdfDetails[0].jobTitle}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <strong>Job location - </strong>
                    {pdfDetails[0].jobLocation}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <strong>Deadline - </strong>
                    {formattedDate}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 detail-field">
                    <br />
                    <div className="posted-jobs-download-button-div">
                      <strong>Download PDF - </strong>
                      <div
                        className="posted-jobs-download-button"
                        onClick={downloadPdf}
                      >
                        <FaDownload />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <div className="col-12">
                <button
                  className="delete-button-4"
                  style={{ backgroundColor: "#ff2b4e", color: "#fff" }}
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  className="delete-button-4"
                  style={{ backgroundColor: "#fffab2", color: "black" }}
                  onClick={togglePdfEditModal}
                >
                  Edit Details
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="applied-applicants-div p-4 pt-2">
          <h3 className="mb-4">Applied Candidates</h3>
          {/* {cvPosts.map((cvPostArray, outerIndex) => (
          <div key={outerIndex}>
            {cvPostArray.map((cvPost, innerIndex) => (
              <div className="col-12 mb-3" key={`${outerIndex}-${innerIndex}`}>
                <div className="card-cv-div cv-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h4 className="card-title">{`${cvPost.name} ${cvPost.lastName}`}</h4>
                        <p className="card-text">
                          <div>
                            <strong>Email:</strong> {cvPost.email}
                            <br />
                            <strong>Mobile:</strong> {cvPost.mobile}
                            <br />
                          </div>
                          <div>
                            <strong>Experience:</strong> {cvPost.experience}{" "}
                            Years
                            <br />
                            <strong>Gender:</strong> {cvPost.gender}
                            <br />
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-primary"
                              onClick={() =>
                                downloadCVPdf(
                                  cvPost.cvFile,
                                  `${cvPost.name}_${cvPost.lastName}`
                                )
                              }
                            >
                              Download CV
                            </button>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))} */}

          {/* {cvPosts.length === 0 ? (
          <div className="loading-screen">Loading...</div>
        ) : ( */}
          {appliedJobs.length === 0 ? (
            <div className="no-candidates">
              No candidate has applied to this job yet.
            </div>
          ) : loading ? (
            <div className="loading-screen">Loading...</div>
          ) : (
            cvPosts.map((cvPostArray, outerIndex) => (
              <div key={outerIndex}>
                {cvPostArray.map((cvPost, innerIndex) => (
                  <div
                    className="col-12 mb-3"
                    key={`${outerIndex}-${innerIndex}`}
                    onClick={() => openApplicantDetailsModal(cvPost)}
                    data-tooltip-id="to-view-details"
                    data-tooltip-content="Click for more"
                    data-tooltip-delay-show={10}
                    data-tooltip-place="bottom"
                  >
                    <div className="card-cv-div cv-card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="name-profile-applicant">
                              {profileImages[cvPost.email] ? (
                                <img
                                  src={profileImages[cvPost.email]}
                                  alt="Profile"
                                  className="company-side-applicant-profile-image"
                                />
                              ) : (
                                <FaRegCircleUser />
                              )}
                              <h4 className="card-title mb-2">{`${cvPost.name} ${cvPost.lastName}`}</h4>
                            </div>
                            <div className="card-text">
                              <div>
                                <strong>Email:</strong> {cvPost.email}
                                <br />
                                <strong>Mobile:</strong> {cvPost.mobile}
                                <br />
                              </div>
                              <div>
                                <strong>Current Rank:</strong>{" "}
                                {cvPost.rankOrPosition}
                              </div>
                              <div onClick={(e) => e.stopPropagation()}>
                                <button
                                  className="btn btn-outline-primary m-2"
                                  onClick={() => openGmail(cvPost)}
                                >
                                  Contact
                                </button>
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={() =>
                                    downloadCVPdf(
                                      cvPost.cvFile,
                                      `${cvPost.name}_${cvPost.lastName}`
                                    )
                                  }
                                >
                                  Download CV
                                </button>
                                <div
                                  className="btn btn-outline-danger m-2 remove-applicant-button"
                                  data-tooltip-id="my-tooltip7"
                                  data-tooltip-content="Reject Applicant"
                                  data-tooltip-delay-show={10}
                                  data-tooltip-place="top"
                                  onClick={() =>
                                    removeApplicant(cvPost.email, id, cvPost)
                                  }
                                >
                                  <CiCircleRemove />
                                  <Tooltip id="my-tooltip7" effect="solid" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tooltip id="to-view-details" effect="solid" />
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
        {showPdfEditModal && (
          <PdfEditModal
            pdfDetails={pdfDetails[0]}
            onCancel={togglePdfEditModal}
            onUpdate={handleUpdatePdfDetails}
          />
        )}
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      </div>
      <ApplicantDetailsModal
        modalThreeOpen={applicantDetailsModalOpen}
        closeModalThree={closeApplicantDetailsModal}
        selectedCvPost={selectedCvPost} // Pass the selected CV post to the modal
        profileImages={profileImages}
        openGmail={openGmail}
        downloadPdf={downloadCVPdf}
      />
      <CompanyFooter />
    </>
  );
}

export default PdfDetailsPage;
