import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./NavbarCompany.css";
import { FaRegCircleUser } from "react-icons/fa6";
import { toast } from "react-toastify";
import DetailsModal from "../../Pages/Company/EditModal/DetailsModal/DetailsModal";
import CompanyProfilePictureEditModal from "../../Pages/Company/CompanyProfilePicture/CompanyProfilePictureEditModal/CompanyProfilePictureEditModal";
const apiUrl = process.env.REACT_APP_API_URL;

function NavbarCompany() {
  const [dropdownTwoOpen, setDropdownTwoOpen] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isPageBlurred, setIsPageBlurred] = useState(false);

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData?.email;
  const userEmail = userId;

  const [profileImage, setProfileImage] = useState(null);
  const [profilePictureEditModalOpen, setProfilePictureEditModalOpen] =
    useState(false);

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await fetch(`${apiUrl}/api/user?email=${userId}`);
        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);

          const imageResponse = await fetch(
            `${apiUrl}/api/upload-company-profile/${userEmail}`
          );

          if (imageResponse.ok) {
            setProfileImage(URL.createObjectURL(await imageResponse.blob()));
          } else {
            console.error(
              "Failed to fetch profile image:",
              imageResponse.statusText
            );
          }
        } else {
          console.error("Failed to fetch user details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    // fetchUserDetails();
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  const openDetailsModal = () => {
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
  };

  const openProfilePictureEditModal = () => {
    setProfilePictureEditModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeProfilePictureEditModal = () => {
    setProfilePictureEditModalOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && detailsModalOpen) {
        closeDetailsModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [detailsModalOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && profilePictureEditModalOpen) {
        closeProfilePictureEditModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [profilePictureEditModalOpen]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
    toast.success("Logout successful!", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const toggleDropdownTwo = () => {
    setDropdownTwoOpen(!dropdownTwoOpen);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  const deleteProfile = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );
    if (!confirmation) return;

    try {
      const response = await axios.delete(`${apiUrl}/api/user/${userEmail}`);
      if (response.status === 200) {
        toast.success("Profile deleted successfully.", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Log the user out and navigate to the homepage
        handleLogout();
      }
    } catch (error) {
      console.error("Error deleting profile:", error);
      toast.error("Failed to delete profile. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <div>
      <nav className="navbar border-bottom navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/company">
            Jobs Maritime
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item link-navbar-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/company"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item link-navbar-item">
                <Link className="nav-link active" to="/jobs-posted">
                  Posted
                </Link>
              </li>
            </ul>
            <div
              className="d-flex ms-auto flex-row-reverse navbar-profile-icon"
              onClick={toggleDropdownTwo}
            >
              {/* <FaRegCircleUser /> */}
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="company-profile-image"
                />
              ) : (
                <FaRegCircleUser />
              )}
              {dropdownTwoOpen && (
                <div
                  className="company-nav-bar-dropdown-menu"
                  style={{
                    display: "block",
                    position: "absolute",
                    zIndex: 1001,
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    borderRadius: "0.5rem",
                    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
                    transition: "opacity 0.3s ease-in-out",
                    minWidth: "280px",
                  }}
                  onClick={stopPropagation}
                >
                  <div className="navbar-dropdown-item company-navbar-dropdown-element">
                    <div className="profile-details">
                      <div>
                        <span className="name">
                          {userDetails.firstName} {userDetails.lastName}
                        </span>
                      </div>
                      <div className="email">
                        <span>{userId}</span>
                      </div>
                    </div>
                    <div className="profile-account">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Manage Account
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li
                            className="dropdown-item"
                            onClick={openDetailsModal}
                          >
                            Edit Details
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={openProfilePictureEditModal}
                          >
                            Update Profile Picture
                          </li>
                          <li className="dropdown-item" onClick={deleteProfile}>
                            Delete Account
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex ms-auto flex-row-reverse">
                      <button className="button-4" onClick={handleLogout}>
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <DetailsModal
        modalOpen={detailsModalOpen}
        closeModal={closeDetailsModal}
      />
      <CompanyProfilePictureEditModal
        modalOpen={profilePictureEditModalOpen}
        closeModal={closeProfilePictureEditModal}
      />
    </div>
  );
}

export default NavbarCompany;
