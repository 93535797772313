// import React, { useState, useEffect } from "react";
// import "./MatchedCandidate.css";
// import { FaDownload } from "react-icons/fa6";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function MatchedCandidate({ selectedItemId }) {
//   const [appliedJobs, setAppliedJobs] = useState([]);
//   const [cvPosts, setCvPosts] = useState([]);

//   // useEffect(() => {
//   //   const fetchAppliedJobs = async () => {
//   //     try {
//   //       const response = await fetch(
//   //         `http://localhost:5000/api/apply-job/${selectedItemId}`
//   //       );
//   //       const data = await response.json();
//   //       setAppliedJobs(data);
//   //     } catch (error) {
//   //       console.error("Error fetching applied jobs:", error);
//   //     }
//   //   };
//   //   fetchAppliedJobs();
//   // }, [selectedItemId]);

//   // useEffect(() => {
//   //   const fetchCvPosts = async () => {
//   //     setCvPosts(null);
//   //     try {
//   //       const cvData = [];
//   //       for (const appliedJob of appliedJobs) {
//   //         const response = await fetch(`http://localhost:5000/api/upload-cv`, {
//   //           headers: {
//   //             email: appliedJob,
//   //           },
//   //         });
//   //         const data = await response.json();
//   //         cvData.push(data);
//   //       }
//   //       setCvPosts(cvData);
//   //     } catch (error) {
//   //       console.error("Error fetching CV posts:", error);
//   //     }
//   //   };

//   //   if (appliedJobs.length > 0) {
//   //     fetchCvPosts();
//   //   }
//   // }, [appliedJobs]);

//   useEffect(() => {
//     const fetchCvPosts = async () => {
//       try {
//         const response = await fetch(
//           `http://localhost:5000/api/apply-job/${selectedItemId}`
//         );
//         const data = await response.json();
//         if (data.length === 0) {
//           toast.info("No candidates have applied to this job.");
//         } else {
//           const cvData = [];
//           for (const appliedJob of data) {
//             const cvResponse = await fetch(
//               `http://localhost:5000/api/upload-cv`,
//               {
//                 headers: {
//                   email: appliedJob,
//                 },
//               }
//             );
//             const cvPost = await cvResponse.json();
//             cvData.push(cvPost);
//           }
//           setCvPosts(cvData);
//         }
//       } catch (error) {
//         console.error("Error fetching CV posts:", error);
//       }
//     };

//     if (selectedItemId) {
//       fetchCvPosts();
//     }
//   }, [selectedItemId]);

//   // Function to download PDF
//   const downloadPdf = (pdfFile, jobTitle) => {
//     const blob = new Blob([Uint8Array.from(pdfFile.data)], {
//       type: "application/pdf",
//     });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${jobTitle}.pdf`;
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
//     document.body.removeChild(a);
//   };

//   return (
//     <div>
//       {cvPosts === null ? (
//         <div className="loading-screen">Loading...</div>
//       ) : (
//         cvPosts.map((cvPostArray, outerIndex) => (
//           <div key={outerIndex}>
//             {cvPostArray.map((cvPost, innerIndex) => (
//               <div className="col-12 mb-3" key={`${outerIndex}-${innerIndex}`}>
//                 <div className="card-cv-div cv-card">
//                   <div className="card-body">
//                     <div className="row">
//                       <div className="col-12">
//                         <h4 className="card-title">{`${cvPost.name} ${cvPost.lastName}`}</h4>
//                         <p className="card-text">
//                           <div>
//                             <strong>Email:</strong> {cvPost.email}
//                             <br />
//                             <strong>Mobile:</strong> {cvPost.mobile}
//                             <br />
//                           </div>
//                           <div>
//                             <strong>Experience:</strong> {cvPost.experience}{" "}
//                             Years
//                             <br />
//                             <strong>Gender:</strong>{" "}
//                             {cvPost.gender.charAt(0).toUpperCase() +
//                               cvPost.gender.slice(1)}{" "}
//                             <br />
//                           </div>
//                           <div>
//                             <button
//                               className="btn btn-outline-primary"
//                               onClick={() =>
//                                 downloadPdf(
//                                   cvPost.cvFile,
//                                   `${cvPost.name}_${cvPost.lastName}`
//                                 )
//                               }
//                             >
//                               <FaDownload />
//                             </button>
//                           </div>
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ))
//       )}
//       <ToastContainer
//         position="top-center"
//         autoClose={3000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//       />
//     </div>
//   );
// }

// export default MatchedCandidate;

import React, { useState, useEffect } from "react";
import "./MatchedCandidate.css";
import { FaDownload } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { FiSend } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { IoFilterCircleOutline } from "react-icons/io5";
import { FaRegCircleUser } from "react-icons/fa6";
import ApplicantDetailsModal from "./ApplicantDetailsModal";
const apiUrl = process.env.REACT_APP_API_URL;

function MatchedCandidate({ selectedItemId, selectedJobTitle }) {
  const [cvPosts, setCvPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const currentUserEmail = userData?.email;
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    industrySector: "",
    companyDescription: "",
    companyWebsite: "",
    contactEmail: "",
    companyAddress: "",
    city: "",
    pincode: "",
    country: "",
  });
  const [dropdownTwoOpen, setDropdownTwoOpen] = useState(false);
  const [filters, setFilters] = useState({ gender: "", experience: "" });
  const [filteredCvPosts, setFilteredCvPosts] = useState([]);
  const [profileImages, setProfileImages] = useState({});

  const [isPageBlurred, setIsPageBlurred] = useState(false);
  const [applicantDetailsModalOpen, setApplicantDetailsModalOpen] =
    useState(false);

  const [selectedCvPost, setSelectedCvPost] = useState(null);

  const openApplicantDetailsModal = (cvPost) => {
    setSelectedCvPost(cvPost); // Store the selected CV post
    setApplicantDetailsModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeApplicantDetailsModal = () => {
    setApplicantDetailsModalOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && applicantDetailsModalOpen) {
        closeApplicantDetailsModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [applicantDetailsModalOpen]);

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/user?email=${currentUserEmail}`
        );
        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
        } else {
          console.error("Failed to fetch user details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    fetchUserDetails();
  }, [currentUserEmail]);

  useEffect(() => {
    const fetchCvPosts = async () => {
      if (!selectedItemId) return; // If no job is selected, return without fetching
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiUrl}/api/apply-job/${selectedItemId}`
        );
        const data = await response.json();
        if (data.length === 0) {
          // toast.info("No candidates have applied to this job.", {
          //   position: "top-center",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          // });
          // Reset the cvPosts state when no candidates are found
          setCvPosts([]);
          setFilteredCvPosts([]);
        } else {
          const cvData = [];
          for (const appliedJob of data) {
            console.log(appliedJob);
            // const cvResponse = await fetch(`${apiUrl}/api/upload-cv`, {
            const cvResponse = await fetch(
              `${apiUrl}/api/upload-extracted-cv`,
              {
                headers: {
                  email: appliedJob,
                },
              }
            );
            const cvPost = await cvResponse.json();
            cvData.push(cvPost);
          }
          setCvPosts(cvData);
          setFilteredCvPosts(cvData);

          // Call the API for each email to fetch profile images
          const profileImagesData = {};
          for (const cvPost of cvData) {
            try {
              const imageResponse = await fetch(
                `${apiUrl}/api/upload-applicant-profile/${cvPost[0].email}`
              );
              if (imageResponse.ok) {
                const blob = await imageResponse.blob();
                profileImagesData[cvPost[0].email] = URL.createObjectURL(blob);
              } else {
                console.error(
                  "Failed to fetch profile image:",
                  imageResponse.statusText
                );
              }
            } catch (error) {
              console.error("Error fetching applicant profile:", error);
            }
          }
          setProfileImages(profileImagesData);
        }
      } catch (error) {
        console.error("Error fetching CV posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCvPosts();
  }, [selectedItemId]);

  useEffect(() => {
    async function fetchCompanyDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/company-details/${currentUserEmail}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company details");
        }
        const companyDetails = await response.json();
        setCompanyInfo(companyDetails);
      } catch (error) {
        console.error("Error fetching company details:", error);
        // toast.error(
        //   "Failed to fetch company details. Please try again later.",
        //   {
        //     position: "top-center",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "dark",
        //   }
        // );
      }
    }
    if (currentUserEmail) {
      fetchCompanyDetails();
    }
  }, [currentUserEmail]);

  // Function to download PDF
  const downloadPdf = (pdfFile, jobTitle) => {
    const blob = new Blob([Uint8Array.from(pdfFile.data)], {
      type: "application/pdf",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${jobTitle}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Function to open Gmail with pre-filled draft
  const openGmail = (cvPost) => {
    if (!userDetails || !companyInfo) {
      console.error("User details or company info not fetched yet.");
      return;
    }

    const subject = encodeURIComponent(
      `Regarding Your Job Application at ${companyInfo.companyName}`
    );
    //   const body = encodeURIComponent(`
    // Dear ${cvPost.name},

    // I hope this email finds you well. My name is ${userDetails.firstName} ${userDetails.lastName}, and I am a representative from ${companyInfo.companyName}.

    // I am writing to you regarding your recent application for the ${selectedJobTitle} position at our company. We appreciate your interest in joining our team.

    // ${companyInfo.companyDescription}

    // You can find more information about our company on our website: ${companyInfo.companyWebsite}

    // I would like to discuss your application further and potentially schedule an interview. Could you please provide your availability for a meeting?

    // Looking forward to hearing from you.

    // Best regards,
    // ${userDetails.firstName} ${userDetails.lastName}
    // ${companyInfo.companyName}
    // ${userDetails.email}`);

    const body = encodeURIComponent(`
Dear ${cvPost.name},

I hope this email finds you well. My name is ${userDetails.firstName} ${userDetails.lastName}, and I am a representative from ${companyInfo.companyName}.

I am writing to you regarding your recent application for the ${selectedJobTitle} position at our company. We appreciate your interest in joining our team.

${companyInfo.companyDescription}

You can find more information about our company on our website: ${companyInfo.companyWebsite}

I would like to discuss your application further and potentially schedule an interview. Could you please provide your availability for a meeting?

Looking forward to hearing from you.

Best regards,
${userDetails.firstName} ${userDetails.lastName}
${companyInfo.companyName}
${userDetails.email}`);

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${cvPost.email}&su=${subject}&body=${body}`;
    window.open(gmailUrl, "_blank");
  };

  const toggleDropdownTwo = () => {
    setDropdownTwoOpen(!dropdownTwoOpen);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const clearFilters = () => {
    setFilters({ gender: "", experience: "" });
    setFilteredCvPosts(cvPosts);
    setDropdownTwoOpen(!dropdownTwoOpen);
  };

  useEffect(() => {
    const filterCandidates = () => {
      let filtered = cvPosts;

      if (filters.gender) {
        filtered = filtered.filter((cvPostArray) =>
          cvPostArray.some(
            (cvPost) =>
              cvPost.gender &&
              cvPost.gender.toLowerCase() === filters.gender.toLowerCase()
          )
        );
      }

      if (filters.experience) {
        filtered = filtered.filter((cvPostArray) =>
          cvPostArray.some(
            (cvPost) =>
              parseInt(cvPost.experience) >= parseInt(filters.experience)
          )
        );
      }

      setFilteredCvPosts(filtered);
    };

    filterCandidates();
  }, [filters, cvPosts]);

  return (
    <div>
      {/* <div className="filter-section" style={{ position: "relative" }}>
        <IoFilterCircleOutline
          data-tooltip-id="my-tooltip5"
          data-tooltip-content="Apply Filter"
          data-tooltip-delay-show={10}
          data-tooltip-place="left"
          onClick={toggleDropdownTwo}
        />
        <Tooltip id="my-tooltip5" effect="solid" />
        {dropdownTwoOpen && (
          <div
            className="dropdown-menu"
            style={{
              display: "block",
              position: "absolute",
              left: "64%",
              top: "125%",
              zIndex: 999,
              backgroundColor: "#fff",
              border: "1px solid #ddd",
              borderRadius: "0.5rem",
              padding: "1rem",
              boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <div className="filter-option">
              <label className="filter-label" htmlFor="gender">
                Gender:
              </label>
              <select
                id="gender"
                name="gender"
                className="form-select"
                value={filters.gender}
                onChange={handleFilterChange}
              >
                <option value="">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="filter-option">
              <label className="filter-label" htmlFor="experience">
                Experience:
              </label>
              <select
                id="experience"
                name="experience"
                className="form-select"
                value={filters.experience}
                onChange={handleFilterChange}
              >
                <option value="">All</option>
                <option value="1">1+ Years</option>
                <option value="3">3+ Years</option>
                <option value="5">5+ Years</option>
                <option value="10">10+ Years</option>
              </select>
            </div>
            <button
              className="btn btn-outline-primary mt-3 "
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        )}
      </div> */}
      {isLoading ? (
        <div className="loading-screen">Loading...</div>
      ) : (
        <>
          {filteredCvPosts.length === 0 && selectedItemId ? (
            <div className="no-candidates">
              No candidate has applied to this job yet.
            </div>
          ) : (
            filteredCvPosts.map((cvPostArray, outerIndex) => (
              <div key={outerIndex} className="mt-2">
                {cvPostArray.map((cvPost, innerIndex) => (
                  <div
                    className="col-12 mb-3 "
                    key={`${outerIndex}-${innerIndex}`}
                    onClick={() => openApplicantDetailsModal(cvPost)}
                    data-tooltip-id="to-view-details"
                    data-tooltip-content="Click for more"
                    data-tooltip-delay-show={10}
                    data-tooltip-place="bottom"
                  >
                    <div className="card-cv-div cv-card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="name-profile-applicant">
                              {profileImages[cvPost.email] ? (
                                <img
                                  src={profileImages[cvPost.email]}
                                  alt="Profile"
                                  className="company-side-applicant-profile-image"
                                />
                              ) : (
                                <FaRegCircleUser />
                              )}
                              <h4 className="card-title mb-2">{`${cvPost.name} ${cvPost.lastName}`}</h4>
                            </div>
                            <div className="card-text">
                              <div>
                                <strong>Email:</strong> {cvPost.email}
                                <br />
                                <strong>Current Rank:</strong>{" "}
                                {cvPost.rankOrPosition}
                                <br />
                              </div>
                              <div>
                                <strong>Mobile:</strong> {cvPost.mobile}
                                <br />
                                {/* <strong>Gender:</strong>{" "}
                                {cvPost.gender.charAt(0).toUpperCase() +
                                  cvPost.gender.slice(1)}{" "} */}
                                <br />
                              </div>
                              <div onClick={(e) => e.stopPropagation()}>
                                <button
                                  className="btn btn-outline-primary  mt-4 cta-buttons-matched-candidates"
                                  onClick={() => openGmail(cvPost)}
                                  data-tooltip-id="my-tooltip3"
                                  data-tooltip-content="Send Email"
                                  data-tooltip-delay-show={10}
                                  data-tooltip-place="top-start"
                                >
                                  <FiSend />
                                  <Tooltip id="my-tooltip3" effect="solid" />
                                </button>
                                <button
                                  className="btn btn-outline-primary mt-4 cta-buttons-matched-candidates"
                                  onClick={() =>
                                    downloadPdf(
                                      cvPost.cvFile,
                                      `${cvPost.name}_${cvPost.lastName}`
                                    )
                                  }
                                  data-tooltip-id="my-tooltip4"
                                  data-tooltip-content="Download CV"
                                  data-tooltip-delay-show={10}
                                  data-tooltip-place="top-start"
                                >
                                  <FaDownload />
                                  <Tooltip id="my-tooltip4" effect="solid" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tooltip id="to-view-details" effect="solid" />
                  </div>
                ))}
              </div>
            ))
          )}
        </>
      )}
      <ApplicantDetailsModal
        modalThreeOpen={applicantDetailsModalOpen}
        closeModalThree={closeApplicantDetailsModal}
        selectedCvPost={selectedCvPost} // Pass the selected CV post to the modal
        profileImages={profileImages}
        openGmail={openGmail}
        downloadPdf={downloadPdf}
      />
      {/* <ToastContainer 
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
    </div>
  );
}

export default MatchedCandidate;
