import React, { useState, useEffect } from "react";
import NavbarCompany from "../../../Components/NavbarCompany/NavbarCompany";
import "./PostedJobs.css";
import JobCard from "./JobCard/JobCard";
import JobLinkCard from "./JobLinkCard/JobLinkCard";
import PdfCard from "./PdfCard/PdfCard";
import axios from "axios";
import CompanyFooter from "../../../Components/Footer/CompanyFooter";
const apiUrl = process.env.REACT_APP_API_URL;

function PostedJobs() {
  const [jobListings, setJobListings] = useState([]);
  const [linkPosts, setLinkPosts] = useState([]);
  const [pdfPosts, setPdfPosts] = useState([]);
  const [filteredJobListings, setFilteredJobListings] = useState([]);
  const [filteredLinkPosts, setFilteredLinkPosts] = useState([]);
  const [filteredPdfPosts, setFilteredPdfPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch job listings from backend when component mounts
  useEffect(() => {
    fetchJobListings();
  }, []);

  // Fetch link posts from backend when component mounts
  useEffect(() => {
    fetchLinkPosts();
  }, []);

  useEffect(() => {
    fetchPdfPosts();
  }, []);

  useEffect(() => {
    filterAllPosts();
  }, [searchQuery, jobListings, linkPosts, pdfPosts]);

  // const fetchJobListings = async () => {
  //   try {
  //     const response = await fetch("http://localhost:5000/api/joblistings");
  //     let data = await response.json();

  //     // Sort jobListings array by postedDate in descending order
  //     data = data.sort(
  //       (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
  //     );

  //     setJobListings(data);
  //   } catch (error) {
  //     console.error("Error fetching job listings:", error);
  //   }
  // };

  // const fetchLinkPosts = async () => {
  //   try {
  //     const response = await fetch("http://localhost:5000/api/linkposts");
  //     let data = await response.json();

  //     setLinkPosts(data);
  //   } catch (error) {
  //     console.error("Error fetching link posts:", error);
  //   }
  // };

  const fetchJobListings = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const email = userData?.email;

      if (!email) {
        console.error("User email not found in local storage");
        return;
      }

      const response = await fetch(`${apiUrl}/api/joblistings`, {
        headers: {
          email: email,
        },
      });
      const data = await response.json();

      setJobListings(data);
    } catch (error) {
      console.error("Error fetching job listings:", error);
    }
  };

  const fetchLinkPosts = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const email = userData?.email;

      if (!email) {
        console.error("User email not found in local storage");
        return;
      }

      const response = await fetch(`${apiUrl}/api/linkposts`, {
        headers: {
          email: email,
        },
      });
      const data = await response.json();

      setLinkPosts(data);
    } catch (error) {
      console.error("Error fetching link posts:", error);
    }
  };

  const fetchPdfPosts = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const email = userData?.email;

      if (!email) {
        console.error("User email not found in local storage");
        return;
      }

      const response = await fetch(`${apiUrl}/api/upload-pdf`, {
        headers: {
          email: email,
        },
      });
      const data = await response.json();

      setPdfPosts(data);
    } catch (error) {
      console.error("Error fetching PDF posts:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterAllPosts = () => {
    const query = searchQuery.toLowerCase();
    setFilteredJobListings(
      jobListings.filter((post) => {
        const title = post.jobTitle || "";
        return title.toLowerCase().includes(query);
      })
    );
    setFilteredLinkPosts(
      linkPosts.filter((post) => {
        const title = post.jobTitle || "";
        return title.toLowerCase().includes(query);
      })
    );
    setFilteredPdfPosts(
      pdfPosts.filter((post) => {
        const title = post.jobTitle || "";
        return title.toLowerCase().includes(query);
      })
    );
  };
  return (
    <>
      <div className="posted-jobs">
        <NavbarCompany />
        <div className="jobs-card-section">
          <div className="row">
            <div className="col-12 jobs-title-section">
              <div style={{ paddingLeft: "1rem" }}>
                <h1>Jobs Created</h1>
              </div>
              <div className="matched-candidates-search-bar">
                <div className="company-search-bar-div">
                  <div className="company-search">
                    <input
                      placeholder="Search..."
                      type="text"
                      className="input-search-bar"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <button type="submit">Search</button>
                  </div>
                </div>
              </div>
            </div>
            {filteredJobListings.map((job) => (
              <div
                key={job._id}
                className="col-lg-3 col-md-6 col-12 job-card-item"
              >
                <JobCard job={job} />
              </div>
            ))}
            {filteredLinkPosts.map((linkPost) => (
              <div
                key={linkPost._id}
                className="col-lg-3 col-md-6 col-12 job-card-item"
              >
                <JobLinkCard linkPost={linkPost} />
              </div>
            ))}

            {filteredPdfPosts.map((pdfPost) => (
              <div
                key={pdfPost._id}
                className="col-lg-3 col-md-6 col-12 job-card-item"
              >
                <PdfCard pdfPost={pdfPost} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <CompanyFooter /> */}
    </>
  );
}

export default PostedJobs;
