import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import NavbarLanding from "../../../Components/NavbarLanding/NavbarLanding";
import "./ForgotPassword.css";
const apiUrl = process.env.REACT_APP_API_URL;

function ForgotPassword() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/forgot-password`, { email });
      setStep(2);
      toast.success("OTP has been sent on your email.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to send OTP", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/verify-otp`, { email, otp });
      setStep(3);
    } catch (error) {
      console.error(error);
      toast.error("Failed to verify OTP", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/reset-password`, {
        email,
        password: newPassword,
      });
      toast.success("Password has been reset", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setStep(1); // Optionally, reset the step to the beginning
      navigate("/");
    } catch (error) {
      console.error(error);
      toast.error("Failed to reset password", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div className="forgot-password-page">
      {/* <NavbarLanding />
      <div className="container mt-5">
        {step === 1 && (
          <form onSubmit={handleEmailSubmit}>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Send OTP
            </button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleOtpSubmit}>
            <div className="mb-3">
              <label className="form-label">OTP</label>
              <input
                type="text"
                className="form-control"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Verify OTP
            </button>
          </form>
        )}
        {step === 3 && (
          <form onSubmit={handlePasswordSubmit}>
            <div className="mb-3">
              <label className="form-label">New Password</label>
              <input
                type="password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Reset Password
            </button>
          </form>
        )}
      </div> */}
      <NavbarLanding />
      <div className="password-container">
        <div
          className={`password-card progress-${
            step === 1 ? "0" : step === 2 ? "50" : "100"
          }`}
        >
          <div className="progress-bar">
            <div className="progress-bar-inner"></div>
          </div>

          {step === 1 && (
            <form onSubmit={handleEmailSubmit}>
              <div className="password-inner-card mb-3">
                <label className="password-form-label">Email</label>
                <input
                  type="email"
                  className="password-form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-outline-primary">
                Send OTP
              </button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleOtpSubmit}>
              <div className="password-inner-card mb-3">
                <label className="password-form-label">OTP</label>
                <input
                  type="text"
                  className="password-form-control"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-outline-primary">
                Verify OTP
              </button>
            </form>
          )}
          {step === 3 && (
            <form onSubmit={handlePasswordSubmit}>
              <div className="password-inner-card mb-3">
                <label className="password-form-label">New Password</label>
                <input
                  type="password"
                  className="password-form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-outline-primary">
                Reset Password
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
