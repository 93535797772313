// components/CompanyDetailsModal.js
import React, { useState } from "react";
import axios from "axios";
import "./CompanyDetailsModal.css";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

function CompanyDetailsModal({ modalOpen, closeModal }) {
  const [formData, setFormData] = useState({
    companyName: "",
    industrySector: "",
    companyDescription: "",
    companyWebsite: "",
    contactEmail: "",
    companyAddress: "",
    city: "",
    pincode: "",
    country: "",
  });

  const [postedDate, setPostedDate] = useState(new Date());

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [postedBy, setPostedBy] = useState(userData?.email);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await axios.post(`${apiUrl}/api/company-details`, {
        ...formData,
        postedBy,
        postedDate,
      });
      console.log("Company details saved successfully");
      toast.success("Login successful!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setFormData({
        companyName: "",
        industrySector: "",
        companyDescription: "",
        companyWebsite: "",
        contactEmail: "",
        companyAddress: "",
        city: "",
        pincode: "",
        country: "",
      });
      // closeModal();
    } catch (error) {
      console.error("Error saving company details:", error);
      // toast.error("Error logging in: " + error.message, {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    }
  };

  const modalDisplay = modalOpen ? "block" : "none";

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Enter company details</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="industrySector" className="form-label">
                    Industry Sector
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="industrySector"
                    name="industrySector"
                    value={formData.industrySector}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyDescription" className="form-label">
                    Company Description
                  </label>
                  <textarea
                    className="form-control"
                    id="companyDescription"
                    name="companyDescription"
                    value={formData.companyDescription}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="companyWebsite" className="form-label">
                    Company Website
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyWebsite"
                    name="companyWebsite"
                    value={formData.companyWebsite}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contactEmail" className="form-label">
                    Contact Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="contactEmail"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyAddress" className="form-label">
                    Company Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyAddress"
                    name="companyAddress"
                    value={formData.companyAddress}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="pincode" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      </div>
    </>
  );
}

export default CompanyDetailsModal;
