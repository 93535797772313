// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";

// const apiUrl = process.env.REACT_APP_API_URL;

// function CVEditOCRModal({ modalOpen, closeModal, userEmail }) {
//   const [formValues, setFormValues] = useState({
//     name: "",
//     lastName: "",
//     nationality: "",
//     mobile: "",
//     address: "",
//     email: "",
//     dob: "",
//     education: "",
//     engineType: "",
//     vesselName: "",
//     typeOfVessel: "",
//     rankOrPosition: "",
//     companyName: "",
//     lookingForPosition: "",
//   });

//   const [cvFile, setCvFile] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (userEmail) {
//       axios
//         .get(`${apiUrl}/api/upload-extracted-cv`, {
//           headers: {
//             email: userEmail,
//           },
//         })
//         .then((response) => {
//           if (response.data.length > 0) {
//             const cvData = response.data[0]; // Assume the first result is the CV data you want to edit
//             setFormValues({
//               name: cvData.name || "",
//               lastName: cvData.lastName || "",
//               nationality: cvData.nationality || "",
//               mobile: cvData.mobile || "",
//               address: cvData.address || "",
//               email: cvData.email || "",
//               dob: cvData.dob || "",
//               education: cvData.education || "",
//               engineType: cvData.engineType || "",
//               vesselName: cvData.vesselName || "",
//               typeOfVessel: cvData.typeOfVessel || "",
//               rankOrPosition: cvData.rankOrPosition || "",
//               companyName: cvData.companyName || "",
//               lookingForPosition: cvData.lookingForPosition || "",
//             });
//           }
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching CV data:", error);
//           setLoading(false);
//         });
//     }
//   }, [userEmail]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({
//       ...formValues,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     setCvFile(e.target.files[0]);
//   };

//   const handleSubmit = async () => {
//     const formData = new FormData();
//     Object.keys(formValues).forEach((key) => {
//       formData.append(key, formValues[key]);
//     });

//     if (cvFile) {
//       formData.append("cvFile", cvFile);
//     }

//     try {
//       await axios.post(`${apiUrl}/api/update-cv`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       toast.success("CV updated successfully!");
//       closeModal();
//     } catch (error) {
//       console.error("Error updating CV:", error);
//       toast.error("Failed to update CV");
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="modal" style={{ display: modalOpen ? "block" : "none" }}>
//       <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5">Edit CV Information</h1>
//             <button
//               type="button"
//               className="btn-close"
//               onClick={closeModal}
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             <form>
//               <div className="mb-3">
//                 <label htmlFor="cvFile" className="form-label">
//                   Upload New CV
//                 </label>
//                 <input
//                   type="file"
//                   className="form-control"
//                   id="cvFile"
//                   onChange={handleFileChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="name" className="form-label">
//                   First Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="name"
//                   name="name"
//                   value={formValues.name}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="lastName" className="form-label">
//                   Last Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="lastName"
//                   name="lastName"
//                   value={formValues.lastName}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="nationality" className="form-label">
//                   Nationality
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="nationality"
//                   name="nationality"
//                   value={formValues.nationality}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="mobile" className="form-label">
//                   Mobile
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="mobile"
//                   name="mobile"
//                   value={formValues.mobile}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="address" className="form-label">
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="address"
//                   name="address"
//                   value={formValues.address}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="email" className="form-label">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   id="email"
//                   name="email"
//                   value={formValues.email}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="dob" className="form-label">
//                   Date of Birth
//                 </label>
//                 <input
//                   type="date"
//                   className="form-control"
//                   id="dob"
//                   name="dob"
//                   value={formValues.dob}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="education" className="form-label">
//                   Education
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="education"
//                   name="education"
//                   value={formValues.education}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="engineType" className="form-label">
//                   Engine Type
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="engineType"
//                   name="engineType"
//                   value={formValues.engineType}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="vesselName" className="form-label">
//                   Vessel Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="vesselName"
//                   name="vesselName"
//                   value={formValues.vesselName}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="typeOfVessel" className="form-label">
//                   Type of Vessel
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="typeOfVessel"
//                   name="typeOfVessel"
//                   value={formValues.typeOfVessel}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="rankOrPosition" className="form-label">
//                   Rank or Position
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="rankOrPosition"
//                   name="rankOrPosition"
//                   value={formValues.rankOrPosition}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="companyName" className="form-label">
//                   Company Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="companyName"
//                   name="companyName"
//                   value={formValues.companyName}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="lookingForPosition" className="form-label">
//                   Looking for Position
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="lookingForPosition"
//                   name="lookingForPosition"
//                   value={formValues.lookingForPosition}
//                   onChange={handleInputChange}
//                 />
//               </div>
//             </form>
//           </div>
//           <div className="modal-footer">
//             <button
//               type="button"
//               className="btn btn-primary"
//               onClick={handleSubmit}
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CVEditOCRModal;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./CVEditModal.css";
// import { toast } from "react-toastify";

// const apiUrl = process.env.REACT_APP_API_URL;

// function CVEditOCRModal({ modalOpen, closeModal, userEmail }) {
//   const userDataString = localStorage.getItem("user");
//   const userData = JSON.parse(userDataString);
//   const [postedBy, setPostedBy] = useState(userData?.email);
//   const [postedDate, setPostedDate] = useState(new Date());
//   const [pdfFile, setPdfFile] = useState(null);
//   const [name, setName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [nationality, setNationality] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [address, setAddress] = useState("");
//   const [email, setEmail] = useState("");
//   const [dob, setDob] = useState("");
//   const [education, setEducation] = useState("");
//   const [engineType, setEngineType] = useState("");
//   const [vesselName, setVesselName] = useState("");
//   const [typeOfVessel, setTypeOfVessel] = useState("");
//   const [rankOrPosition, setRankOrPosition] = useState("");
//   const [companyName, setCompanyName] = useState("");
//   const [lookingForPosition, setLookingForPosition] = useState("");

//   const [step, setStep] = useState(1);

//   const modalDisplay = modalOpen ? "block" : "none";

//   const handleUpload = (event) => {
//     setPdfFile(event.target.files[0]);
//   };

//   const handleExtract = async () => {
//     const formData = new FormData();
//     formData.append("cv", pdfFile);

//     toast.promise(
//       axios
//         .put(`${apiUrl}/api/get-cv-info`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         })
//         .then((response) => {
//           const data = response.data.document.entities;

//           data.forEach((entity) => {
//             switch (entity.type) {
//               case "first-name":
//                 setName(entity.mentionText);
//                 break;
//               case "last-name":
//                 setLastName(entity.mentionText);
//                 break;
//               case "nationality":
//                 setNationality(entity.mentionText);
//                 break;
//               case "mobile-no":
//                 setMobile(entity.mentionText);
//                 break;
//               case "address":
//                 setAddress(entity.mentionText);
//                 break;
//               case "e-mail":
//                 setEmail(entity.mentionText);
//                 break;
//               case "date-of-birth":
//                 const dobText = entity.mentionText;
//                 let formattedDob = "";

//                 if (/^\d{2}-\d{2}-\d{4}$/.test(dobText)) {
//                   const [day, month, year] = dobText.split("-");
//                   formattedDob = `${year}-${month}-${day}`;
//                 } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dobText)) {
//                   const [day, month, year] = dobText.split("/");
//                   formattedDob = `${year}-${month}-${day}`;
//                 } else {
//                   formattedDob = dobText;
//                 }

//                 setDob(formattedDob);
//                 break;
//               case "education":
//                 setEducation(entity.mentionText);
//                 break;
//               case "engine-type":
//                 setEngineType(entity.mentionText);
//                 break;
//               case "vessel-name":
//                 setVesselName(entity.mentionText);
//                 break;
//               case "type-of-vessel":
//                 setTypeOfVessel(entity.mentionText);
//                 break;
//               case "rank-or-position":
//                 setRankOrPosition(entity.mentionText);
//                 break;
//               case "company-name":
//                 setCompanyName(entity.mentionText);
//                 break;
//               case "looking-for-position":
//                 setLookingForPosition(entity.mentionText);
//                 break;
//               default:
//                 break;
//             }
//           });

//           setStep(2);
//         })
//         .catch((error) => {
//           console.error("Error extracting CV:", error);
//           throw new Error("Failed to extract CV. Please try again later.");
//         }),
//       {
//         pending: "Processing your CV...",
//         success: "CV processed successfully!",
//         error: {
//           render({ data }) {
//             return data.message;
//           },
//         },
//       },
//       {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       }
//     );
//   };

//   const handleSubmit = async () => {
//     const formData = new FormData();
//     formData.append("cv", pdfFile);
//     formData.append("postedDate", postedDate.toISOString());
//     formData.append("postedBy", postedBy);
//     formData.append("name", name);
//     formData.append("lastName", lastName);
//     formData.append("nationality", nationality);
//     formData.append("mobile", mobile);
//     formData.append("address", address);
//     formData.append("email", email);
//     formData.append("dob", dob);
//     formData.append("education", education);
//     formData.append("engineType", engineType);
//     formData.append("vesselName", vesselName);
//     formData.append("typeOfVessel", typeOfVessel);
//     formData.append("rankOrPosition", rankOrPosition);
//     formData.append("companyName", companyName);
//     formData.append("lookingForPosition", lookingForPosition);

//     try {
//       await axios.post(`${apiUrl}/api/upload-extracted-cv`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       setPdfFile(null);
//       setName("");
//       setLastName("");
//       setNationality("");
//       setMobile("");
//       setAddress("");
//       setEmail("");
//       setDob("");
//       setEducation("");
//       toast.success("CV uploaded successfully", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//       closeModal();
//       setStep(1);
//     } catch (error) {
//       console.error("Error uploading CV:", error);
//       if (error.response && error.response.status === 409) {
//         toast.info("CV already uploaded for this user", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       } else {
//         const errorMessage =
//           error.response && error.response.data
//             ? error.response.data
//             : "Failed to upload CV. Please try again later.";
//         toast.error(errorMessage, {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       }
//     }
//   };

//   return (
//     <div className="modal" style={{ display: modalDisplay }}>
//       <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5">
//               {step === 1 ? "Upload CV" : "Extracted CV Details"}
//             </h1>
//             <button
//               type="button"
//               className="btn-close"
//               onClick={closeModal}
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             {step === 1 ? (
//               <form>
//                 <div className="m-3 row">
//                   <label htmlFor="cvUpload" className="col-sm-3 col-form-label">
//                     Upload Resume/CV:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="file"
//                       className="form-control"
//                       id="cvUpload"
//                       accept=".pdf"
//                       onChange={handleUpload}
//                       required
//                     />
//                   </div>
//                 </div>
//               </form>
//             ) : (
//               <form>
//                 <div className="mb-3 row">
//                   <label htmlFor="name" className="col-sm-3 col-form-label">
//                     First Name:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="name"
//                       placeholder="Enter your first name"
//                       value={name}
//                       onChange={(e) => setName(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label htmlFor="lastName" className="col-sm-3 col-form-label">
//                     Last Name:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="lastName"
//                       placeholder="Enter your last name"
//                       value={lastName}
//                       onChange={(e) => setLastName(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="nationality"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Nationality:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nationality"
//                       placeholder="Enter your nationality"
//                       value={nationality}
//                       onChange={(e) => setNationality(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label htmlFor="mobile" className="col-sm-3 col-form-label">
//                     Mobile No.:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="mobile"
//                       placeholder="Enter your mobile number"
//                       value={mobile}
//                       onChange={(e) => setMobile(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label htmlFor="address" className="col-sm-3 col-form-label">
//                     Address:
//                   </label>
//                   <div className="col-sm-9">
//                     <textarea
//                       type="text"
//                       className="form-control"
//                       id="address"
//                       placeholder="Enter your address"
//                       value={address}
//                       onChange={(e) => setAddress(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label htmlFor="email" className="col-sm-3 col-form-label">
//                     E-Mail:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="email"
//                       placeholder="Enter your email"
//                       value={email}
//                       onChange={(e) => setEmail(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label htmlFor="dob" className="col-sm-3 col-form-label">
//                     Date of Birth:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="date"
//                       className="form-control"
//                       id="dob"
//                       value={dob}
//                       onChange={(e) => setDob(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="education"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Education:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="education"
//                       placeholder="Enter your education"
//                       value={education}
//                       onChange={(e) => setEducation(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <h4 className="mb-3">Recent Sea Service : </h4>
//                   <label
//                     htmlFor="engineType"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Engine Type:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="engineType"
//                       placeholder="Enter engine type"
//                       value={engineType}
//                       onChange={(e) => setEngineType(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="vesselName"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Vessel Name:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="vesselName"
//                       placeholder="Enter vessel name"
//                       value={vesselName}
//                       onChange={(e) => setVesselName(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="typeOfVessel"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Type of Vessel:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="typeOfVessel"
//                       placeholder="Enter type of vessel"
//                       value={typeOfVessel}
//                       onChange={(e) => setTypeOfVessel(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="rankOrPosition"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Rank or Position:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="rankOrPosition"
//                       placeholder="Enter rank or position"
//                       value={rankOrPosition}
//                       onChange={(e) => setRankOrPosition(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="companyName"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Company Name:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="companyName"
//                       placeholder="Enter company name"
//                       value={companyName}
//                       onChange={(e) => setCompanyName(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-3 row">
//                   <label
//                     htmlFor="lookingForPosition"
//                     className="col-sm-3 col-form-label"
//                   >
//                     Looking for Position:
//                   </label>
//                   <div className="col-sm-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="lookingForPosition"
//                       placeholder="Enter desired position"
//                       value={lookingForPosition}
//                       onChange={(e) => setLookingForPosition(e.target.value)}
//                     />
//                   </div>
//                 </div>
//               </form>
//             )}
//           </div>
//           <div className="modal-footer">
//             <button
//               type="button"
//               className="btn btn-outline-danger"
//               onClick={closeModal}
//             >
//               Close
//             </button>
//             {step === 1 ? (
//               <button
//                 type="button"
//                 className="btn btn-primary"
//                 onClick={handleExtract}
//                 disabled={!pdfFile}
//               >
//                 Extract Details
//               </button>
//             ) : (
//               <button
//                 type="button"
//                 className="btn btn-primary"
//                 onClick={handleSubmit}
//               >
//                 Submit
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CVEditOCRModal;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./CVEditModal.css";
// import { toast } from "react-toastify";

// const apiUrl = process.env.REACT_APP_API_URL;

// function CVEditOCRModal({ modalOpen, closeModal, userEmail }) {
//   const userDataString = localStorage.getItem("user");
//   const userData = JSON.parse(userDataString);
//   const [postedBy, setPostedBy] = useState(userData?.email);
//   const [postedDate, setPostedDate] = useState(new Date());
//   const [pdfFile, setPdfFile] = useState(null);
//   const [formValues, setFormValues] = useState({
//     name: "",
//     lastName: "",
//     nationality: "",
//     mobile: "",
//     address: "",
//     email: "",
//     dob: "",
//     education: "",
//     engineType: "",
//     vesselName: "",
//     typeOfVessel: "",
//     rankOrPosition: "",
//     companyName: "",
//     lookingForPosition: "",
//   });

//   const [step, setStep] = useState(1);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (userEmail) {
//       axios
//         .get(`${apiUrl}/api/upload-extracted-cv`, {
//           headers: {
//             email: userEmail,
//           },
//         })
//         .then((response) => {
//           if (response.data.length > 0) {
//             const cvData = response.data; // Assume the first result is the CV data you want to edit
//             setFormValues({
//               name: cvData.name || "",
//               lastName: cvData.lastName || "",
//               nationality: cvData.nationality || "",
//               mobile: cvData.mobile || "",
//               address: cvData.address || "",
//               email: cvData.email || "",
//               dob: cvData.dob || "",
//               education: cvData.education || "",
//               engineType: cvData.engineType || "",
//               vesselName: cvData.vesselName || "",
//               typeOfVessel: cvData.typeOfVessel || "",
//               rankOrPosition: cvData.rankOrPosition || "",
//               companyName: cvData.companyName || "",
//               lookingForPosition: cvData.lookingForPosition || "",
//             });
//           }
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching CV data:", error);
//           setLoading(false);
//         });
//     }
//   }, [userEmail]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({
//       ...formValues,
//       [name]: value,
//     });
//   };

//   const modalDisplay = modalOpen ? "block" : "none";

//   const handleUpload = (event) => {
//     setPdfFile(event.target.files[0]);
//   };

//   const handleExtract = async () => {
//     const formData = new FormData();
//     formData.append("cv", pdfFile);

//     toast.promise(
//       axios
//         .post(`${apiUrl}/api/get-cv-info`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         })
//         .then((response) => {
//           const data = response.data.document.entities;

//           const updatedFormValues = { ...formValues };

//           data.forEach((entity) => {
//             switch (entity.type) {
//               case "first-name":
//                 updatedFormValues.name = entity.mentionText;
//                 break;
//               case "last-name":
//                 updatedFormValues.lastName = entity.mentionText;
//                 break;
//               case "nationality":
//                 updatedFormValues.nationality = entity.mentionText;
//                 break;
//               case "mobile-no":
//                 updatedFormValues.mobile = entity.mentionText;
//                 break;
//               case "address":
//                 updatedFormValues.address = entity.mentionText;
//                 break;
//               case "e-mail":
//                 updatedFormValues.email = entity.mentionText;
//                 break;
//               case "date-of-birth":
//                 const dobText = entity.mentionText;
//                 let formattedDob = "";

//                 if (/^\d{2}-\d{2}-\d{4}$/.test(dobText)) {
//                   const [day, month, year] = dobText.split("-");
//                   formattedDob = `${year}-${month}-${day}`;
//                 } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dobText)) {
//                   const [day, month, year] = dobText.split("/");
//                   formattedDob = `${year}-${month}-${day}`;
//                 } else {
//                   formattedDob = dobText;
//                 }

//                 updatedFormValues.dob = formattedDob;
//                 break;
//               case "education":
//                 updatedFormValues.education = entity.mentionText;
//                 break;
//               case "engine-type":
//                 updatedFormValues.engineType = entity.mentionText;
//                 break;
//               case "vessel-name":
//                 updatedFormValues.vesselName = entity.mentionText;
//                 break;
//               case "type-of-vessel":
//                 updatedFormValues.typeOfVessel = entity.mentionText;
//                 break;
//               case "rank-or-position":
//                 updatedFormValues.rankOrPosition = entity.mentionText;
//                 break;
//               case "company-name":
//                 updatedFormValues.companyName = entity.mentionText;
//                 break;
//               case "looking-for-position":
//                 updatedFormValues.lookingForPosition = entity.mentionText;
//                 break;
//               default:
//                 break;
//             }
//           });

//           setFormValues(updatedFormValues);
//           setStep(2);
//         })
//         .catch((error) => {
//           console.error("Error extracting CV:", error);
//           throw new Error("Failed to extract CV. Please try again later.");
//         }),
//       {
//         pending: "Processing your CV...",
//         success: "CV processed successfully!",
//         error: {
//           render({ data }) {
//             return data.message;
//           },
//         },
//       },
//       {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       }
//     );
//   };

//   const handleSubmit = async () => {
//     const formData = new FormData();
//     formData.append("cv", pdfFile);
//     formData.append("postedDate", postedDate.toISOString());
//     formData.append("postedBy", postedBy);
//     Object.keys(formValues).forEach((key) => {
//       formData.append(key, formValues[key]);
//     });

//     try {
//       await axios.post(`${apiUrl}/api/upload-extracted-cv`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       setPdfFile(null);
//       setFormValues({
//         name: "",
//         lastName: "",
//         nationality: "",
//         mobile: "",
//         address: "",
//         email: "",
//         dob: "",
//         education: "",
//         engineType: "",
//         vesselName: "",
//         typeOfVessel: "",
//         rankOrPosition: "",
//         companyName: "",
//         lookingForPosition: "",
//       });
//       toast.success("CV updated successfully", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//       closeModal();
//       setStep(1);
//     } catch (error) {
//       console.error("Error uploading CV:", error);
//       if (error.response && error.response.status === 409) {
//         toast.info("CV already uploaded for this user", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       } else {
//         const errorMessage =
//           error.response && error.response.data
//             ? error.response.data
//             : "Failed to upload CV. Please try again later.";
//         toast.error(errorMessage, {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       }
//     }
//   };

//   return (
//     <div className="modal" style={{ display: modalDisplay }}>
//       <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5">
//               {step === 1 ? "Upload CV" : "Extracted CV Details"}
//             </h1>
//             <button
//               type="button"
//               className="btn-close"
//               onClick={closeModal}
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             {step === 1 ? (
//               <form>
//                 <div className="mb-3">
//                   <label htmlFor="cv-upload" className="form-label">
//                     Upload CV
//                   </label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="cv-upload"
//                     onChange={handleUpload}
//                   />
//                 </div>
//                 <button
//                   type="button"
//                   className="btn btn-primary"
//                   onClick={handleExtract}
//                   disabled={!pdfFile}
//                 >
//                   Extract Details
//                 </button>
//               </form>
//             ) : (
//               <form>
//                 {loading ? (
//                   <p>Loading...</p>
//                 ) : (
//                   <>
//                     <div className="mb-3">
//                       <label htmlFor="name" className="form-label">
//                         Name
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="name"
//                         name="name"
//                         value={formValues.name}
//                         onChange={handleInputChange}
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <label htmlFor="lastName" className="form-label">
//                         Last Name
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="lastName"
//                         name="lastName"
//                         value={formValues.lastName}
//                         onChange={handleInputChange}
//                       />
//                     </div>
//                     <div className="mb-3">
//                 <label htmlFor="nationality" className="form-label">
//                   Nationality
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="nationality"
//                   name="nationality"
//                   value={formValues.nationality}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="mobile" className="form-label">
//                   Mobile
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="mobile"
//                   name="mobile"
//                   value={formValues.mobile}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="address" className="form-label">
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="address"
//                   name="address"
//                   value={formValues.address}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="email" className="form-label">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   id="email"
//                   name="email"
//                   value={formValues.email}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="dob" className="form-label">
//                   Date of Birth
//                 </label>
//                 <input
//                   type="date"
//                   className="form-control"
//                   id="dob"
//                   name="dob"
//                   value={formValues.dob}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="education" className="form-label">
//                   Education
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="education"
//                   name="education"
//                   value={formValues.education}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="engineType" className="form-label">
//                   Engine Type
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="engineType"
//                   name="engineType"
//                   value={formValues.engineType}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="vesselName" className="form-label">
//                   Vessel Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="vesselName"
//                   name="vesselName"
//                   value={formValues.vesselName}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="typeOfVessel" className="form-label">
//                   Type of Vessel
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="typeOfVessel"
//                   name="typeOfVessel"
//                   value={formValues.typeOfVessel}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="rankOrPosition" className="form-label">
//                   Rank or Position
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="rankOrPosition"
//                   name="rankOrPosition"
//                   value={formValues.rankOrPosition}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="companyName" className="form-label">
//                   Company Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="companyName"
//                   name="companyName"
//                   value={formValues.companyName}
//                   onChange={handleInputChange}
//                 />
//               </div>

//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleSubmit}
//                     >
//                       Save Changes
//                     </button>
//                   </>
//                 )}
//               </form>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CVEditOCRModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CVEditModal.css";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

function CVEditOCRModal({ modalOpen, closeModal, userEmail }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [postedBy, setPostedBy] = useState(userData?.email);
  const [postedDate, setPostedDate] = useState(new Date());
  const [pdfFile, setPdfFile] = useState(null);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [education, setEducation] = useState("");
  const [engineType, setEngineType] = useState("");
  const [vesselName, setVesselName] = useState("");
  const [typeOfVessel, setTypeOfVessel] = useState("");
  const [rankOrPosition, setRankOrPosition] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [lookingForPosition, setLookingForPosition] = useState("");

  const [step, setStep] = useState(1);

  const modalDisplay = modalOpen ? "block" : "none";

  const handleUpload = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const handleExtract = async () => {
    const formData = new FormData();
    formData.append("cv", pdfFile);

    toast.promise(
      axios
        .post(`${apiUrl}/api/get-cv-info`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const data = response.data.document.entities;

          data.forEach((entity) => {
            switch (entity.type) {
              case "first-name":
                setName(entity.mentionText);
                break;
              case "last-name":
                setLastName(entity.mentionText);
                break;
              case "nationality":
                setNationality(entity.mentionText);
                break;
              case "mobile-no":
                setMobile(entity.mentionText);
                break;
              case "address":
                setAddress(entity.mentionText);
                break;
              case "e-mail":
                setEmail(entity.mentionText);
                break;
              case "date-of-birth":
                const dobText = entity.mentionText;
                let formattedDob = "";

                if (/^\d{2}-\d{2}-\d{4}$/.test(dobText)) {
                  const [day, month, year] = dobText.split("-");
                  formattedDob = `${year}-${month}-${day}`;
                } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dobText)) {
                  const [day, month, year] = dobText.split("/");
                  formattedDob = `${year}-${month}-${day}`;
                } else {
                  formattedDob = dobText;
                }

                setDob(formattedDob);
                break;
              case "education":
                setEducation(entity.mentionText);
                break;
              case "engine-type":
                setEngineType(entity.mentionText);
                break;
              case "vessel-name":
                setVesselName(entity.mentionText);
                break;
              case "type-of-vessel":
                setTypeOfVessel(entity.mentionText);
                break;
              case "rank-or-position":
                setRankOrPosition(entity.mentionText);
                break;
              case "company-name":
                setCompanyName(entity.mentionText);
                break;
              case "looking-for-position":
                setLookingForPosition(entity.mentionText);
                break;
              default:
                break;
            }
          });

          setStep(2);
        })
        .catch((error) => {
          console.error("Error extracting CV:", error);
          throw new Error("Failed to extract CV. Please try again later.");
        }),
      {
        pending: "Processing your CV...",
        success: "CV processed successfully!",
        error: {
          render({ data }) {
            return data.message;
          },
        },
      },
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("cv", pdfFile);
    formData.append("postedDate", postedDate.toISOString());
    formData.append("postedBy", postedBy);
    formData.append("name", name);
    formData.append("lastName", lastName);
    formData.append("nationality", nationality);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("email", userEmail); // Use userEmail
    formData.append("dob", dob);
    formData.append("education", education);
    formData.append("engineType", engineType);
    formData.append("vesselName", vesselName);
    formData.append("typeOfVessel", typeOfVessel);
    formData.append("rankOrPosition", rankOrPosition);
    formData.append("companyName", companyName);
    formData.append("lookingForPosition", lookingForPosition);

    try {
      await axios.put(`${apiUrl}/api/upload-extracted-cv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPdfFile(null);
      setName("");
      setLastName("");
      setNationality("");
      setMobile("");
      setAddress("");
      setEmail("");
      setDob("");
      setEducation("");
      toast.success("CV updated successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      closeModal();
      setStep(1);
    } catch (error) {
      console.error("Error updating CV:", error);
      if (error.response && error.response.status === 409) {
        toast.info("CV already updated for this user", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data
            : "Failed to update CV. Please try again later.";
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">
                {step === 1 ? "Edit CV" : "Extracted CV Details"}
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {step === 1 ? (
                <form>
                  <div className="m-3 row">
                    <label
                      htmlFor="cvUpload"
                      className="col-sm-3 col-form-label"
                    >
                      Upload Resume/CV:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control"
                        id="cvUpload"
                        accept=".pdf"
                        onChange={handleUpload}
                        required
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <form>
                  <div className="mb-3 row">
                    <label htmlFor="name" className="col-sm-3 col-form-label">
                      First Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your first name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="lastName"
                      className="col-sm-3 col-form-label"
                    >
                      Last Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="nationality"
                      className="col-sm-3 col-form-label"
                    >
                      Nationality:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="nationality"
                        placeholder="Enter your nationality"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="mobile" className="col-sm-3 col-form-label">
                      Mobile:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="address"
                      className="col-sm-3 col-form-label"
                    >
                      Address:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="email" className="col-sm-3 col-form-label">
                      Email:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="dob" className="col-sm-3 col-form-label">
                      Date of Birth:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="education"
                      className="col-sm-3 col-form-label"
                    >
                      Education:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="education"
                        placeholder="Enter your education"
                        value={education}
                        onChange={(e) => setEducation(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="engineType"
                      className="col-sm-3 col-form-label"
                    >
                      Engine Type:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="engineType"
                        placeholder="Enter engine type"
                        value={engineType}
                        onChange={(e) => setEngineType(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="vesselName"
                      className="col-sm-3 col-form-label"
                    >
                      Vessel Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="vesselName"
                        placeholder="Enter vessel name"
                        value={vesselName}
                        onChange={(e) => setVesselName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="typeOfVessel"
                      className="col-sm-3 col-form-label"
                    >
                      Type of Vessel:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="typeOfVessel"
                        placeholder="Enter type of vessel"
                        value={typeOfVessel}
                        onChange={(e) => setTypeOfVessel(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="rankOrPosition"
                      className="col-sm-3 col-form-label"
                    >
                      Rank or Position:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="rankOrPosition"
                        placeholder="Enter rank or position"
                        value={rankOrPosition}
                        onChange={(e) => setRankOrPosition(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="companyName"
                      className="col-sm-3 col-form-label"
                    >
                      Company Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        placeholder="Enter company name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="lookingForPosition"
                      className="col-sm-3 col-form-label"
                    >
                      Looking For Position:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="lookingForPosition"
                        placeholder="Enter looking for position"
                        value={lookingForPosition}
                        onChange={(e) => setLookingForPosition(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className="modal-footer">
              {step === 1 ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleExtract}
                >
                  Extract Information
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Update CV
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CVEditOCRModal;
