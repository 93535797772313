import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "./Forum.css";
import NavbarApplicant from "../NavbarApplicant/NavbarApplicant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FaRegCircleUser } from "react-icons/fa6";

import {
  faTimes,
  faEdit,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import ApplicantFooter from "../Footer/ApplicantFooter";
const apiUrl = process.env.REACT_APP_API_URL;

const Forum = () => {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [messages, setMessages] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [replyInputs, setReplyInputs] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [editingMessage, setEditingMessage] = useState(null);
  const [editInput, setEditInput] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const [profileImage, setProfileImage] = useState({});
  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/user?email=${userData?.email}`
        );
        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
          // const imageResponse = await fetch(
          //   `${apiUrl}/api/upload-applicant-profile/${userData?.email}`
          // );
          // if (imageResponse.ok) {
          //   setProfileImage(URL.createObjectURL(await imageResponse.blob()));
          // } else {
          //   console.error(
          //     "Failed to fetch profile image:",
          //     imageResponse.statusText
          //   );
          // }
        } else {
          console.error("Failed to fetch user details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    fetchUserDetails();
  }, [userData?.email]);

  const [formData, setFormData] = useState({
    name: userDetails ? userDetails.firstName + " " + userDetails.lastName : "",
    email: userData?.email,
    message: "",
  });

  const isFormValid = () => {
    const requiredFields = ["message"];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field} is required.`);
        return false;
      }
    }
    return true;
  };

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (!isFormValid()) {
      return;
    }

    try {
      const formDataWithDetails = {
        ...formData,
        name: userDetails
          ? userDetails.firstName + " " + userDetails.lastName
          : "",
      };

      const response = await axios.post(
        `${apiUrl}/api/message`,
        formDataWithDetails
      );

      if (response.status === 201) {
        setFormData({
          ...formDataWithDetails,
          message: "",
        });

        toast.success("Message posted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        fetchMessages();
        fetchUserMessages();
      }
    } catch (error) {
      console.error("Error saving message:", error);
      toast.error("Failed to post message. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/messages`);
      const fetchedMessages = response.data;
      setMessages(response.data);
      // Fetch profile images for all users
      const profileImagePromises = fetchedMessages.map(async (msg) => {
        const imageResponse = await fetch(
          `${apiUrl}/api/upload-applicant-profile/${msg.email}`
        );
        if (imageResponse.ok) {
          const imageBlob = await imageResponse.blob();
          return { email: msg.email, imageUrl: URL.createObjectURL(imageBlob) };
        }
        return { email: msg.email, imageUrl: null };
      });

      const profileImagesArray = await Promise.all(profileImagePromises);
      const profileImagesObject = profileImagesArray.reduce(
        (acc, curr) => ({ ...acc, [curr.email]: curr.imageUrl }),
        {}
      );
      setProfileImage(profileImagesObject);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchUserMessages = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/messages/user/${userData.email}`
      );
      setUserMessages(response.data);
    } catch (error) {
      console.error("Error fetching user messages:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
    fetchUserMessages();
  }, [userData.email]);

  const handleTextareaChange = (e) => {
    const textarea = e.target;
    setFormData({ ...formData, message: textarea.value });

    // Adjust textarea height automatically
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyChange = (e, messageId) => {
    const reply = e.target.value;
    setReplyInputs({ ...replyInputs, [messageId]: reply });
  };

  const handleReplySave = async (messageId) => {
    if (!replyInputs[messageId]) {
      toast.error("Reply is required.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/messages/${messageId}/reply`,
        {
          name: userDetails
            ? userDetails.firstName + " " + userDetails.lastName
            : "",
          reply: replyInputs[messageId],
        }
      );

      if (response.status === 201) {
        // Update replyInputs state to remove the textarea for the messageId
        setReplyInputs((prevInputs) => ({
          ...prevInputs,
          [messageId]: "",
        }));

        toast.success("Reply posted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        fetchMessages();
      }
    } catch (error) {
      console.error("Error posting reply:", error);
      toast.error("Failed to post reply. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const toggleExpand = (messageId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };
  const closeReply = (messageId) => {
    setReplyInputs((prevInputs) => ({
      ...prevInputs,
      [messageId]: undefined,
    }));
  };

  const handleEditClick = (message) => {
    setEditingMessage(message);
    setEditInput(message.message);
  };

  const handleEditChange = (e) => {
    setEditInput(e.target.value);
  };

  const handleEditSave = async () => {
    if (!editInput.trim()) {
      toast.error("Message cannot be empty.");
      return;
    }

    try {
      const response = await axios.put(
        `${apiUrl}/api/messages/${editingMessage._id}`,
        {
          message: editInput,
        }
      );

      if (response.status === 200) {
        toast.success("Message updated successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setEditingMessage(null);
        setEditInput("");
        fetchMessages();
      }
    } catch (error) {
      console.error("Error updating message:", error);
      toast.error("Failed to update message. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleDeleteClick = async (messageId) => {
    if (!window.confirm("Are you sure you want to delete this message?")) {
      return;
    }

    try {
      const response = await axios.delete(
        `${apiUrl}/api/messages/${messageId}`
      );

      if (response.status === 200) {
        toast.success("Message deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        fetchMessages();
        fetchUserMessages();
      }
    } catch (error) {
      console.error("Error deleting message:", error);
      toast.error("Failed to delete message. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const calculateTimeElapsed = (createdAt) => {
    const now = new Date().getTime();
    const messageTime = new Date(createdAt).getTime();
    return now - messageTime;
  };

  return (
    <>
      <NavbarApplicant />
      <div className="forum-container">
        <div className="discussion-section">
          <h2>Community Threads</h2>
          <div className="messages-list">
            {messages.map((msg, index) => (
              <div key={index} className="message-container">
                <div className="message-header">
                  {profileImage[msg.email] ? (
                    <img
                      src={profileImage[msg.email]}
                      alt="Profile"
                      className="applicant-profile-image"
                    />
                  ) : (
                    // <FontAwesomeIcon
                    //   icon={FaRegCircleUser}
                    //   className="fallback-profile-icon"
                    // />

                    <div className="fallback-profile-icon">
                      <FaRegCircleUser />
                    </div>
                  )}
                  <h5>{msg.name}</h5>{" "}
                </div>

                <p className="message-time">
                  {new Date(msg.createdAt).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  }) +
                    " at " +
                    new Date(msg.createdAt).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })}
                </p>

                <p className="message-content">{msg.message}</p>
                <div class="parent-div">
                  <div
                    className="comment-count"
                    onClick={() => toggleExpand(msg._id)}
                  >
                    {msg.replies ? msg.replies.length : 0} Comments
                  </div>
                  <FontAwesomeIcon
                    className="reply-button"
                    icon={faReply}
                    onClick={() =>
                      setReplyInputs({ ...replyInputs, [msg._id]: "" })
                    }
                  >
                    Reply
                  </FontAwesomeIcon>
                </div>
                {msg.email === userData.email && (
                  <div className="message-actions">
                    {calculateTimeElapsed(msg.createdAt) < 900000 && (
                      <button
                        className="edit-button"
                        onClick={() => handleEditClick(msg)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    )}
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteClick(msg._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                )}

                {replyInputs[msg._id] !== undefined && (
                  <div className="reply-container">
                    <button
                      className="close-button"
                      onClick={() => closeReply(msg._id)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <textarea
                      value={replyInputs[msg._id]}
                      onChange={(e) => handleReplyChange(e, msg._id)}
                      placeholder="Your Reply"
                    />
                    <button
                      className="post-reply-button"
                      onClick={() => handleReplySave(msg._id)}
                    >
                      Post Reply
                    </button>
                  </div>
                )}

                {msg.replies &&
                  msg.replies
                    .slice(0, expandedReplies[msg._id] ? msg.replies.length : 0)
                    .map((reply, idx) => (
                      <div key={idx} className="reply">
                        <p>
                          <strong>{reply.name}</strong>{" "}
                        </p>
                        <p className="reply-time">
                          {new Date(reply.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          ) +
                            " at " +
                            new Date(reply.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              }
                            )}
                        </p>
                        <div className="reply-box">
                          <p className="reply-text">{reply.reply}</p>
                        </div>
                      </div>
                    ))}
                {/* {msg.replies && msg.replies.length > 1 && (
                  <button
                    className="reply-show-more"
                    onClick={() => toggleExpand(msg._id)}
                  >
                    {expandedReplies[msg._id] ? "Show Less" : "Show More"}
                  </button>
                )} */}
              </div>
            ))}
          </div>
        </div>
        <div className="post-section">
          <div className="post-message-section">
            {/* <h2>Post a Message</h2> */}
            <div className="post-form-container">
              <form onSubmit={handleSave}>
                <textarea
                  value={formData.message}
                  onChange={handleTextareaChange}
                  placeholder="Type Your Message..."
                  required
                />
                <button className="post-button" type="submit">
                  Post
                </button>
              </form>
            </div>
          </div>

          <div className="forum-content">
            <h2>My Threads</h2>
            <div className="messages-container">
              {userMessages.map((msg) => (
                <div key={msg._id} className="message-card">
                  <p>
                    {" "}
                    <strong>{msg.name}</strong>
                  </p>
                  <p className="message-date">
                    {new Date(msg.createdAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    }) +
                      " at " +
                      new Date(msg.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                  </p>

                  <p>{msg.message}</p>
                  <div className="message-actions">
                    {calculateTimeElapsed(msg.createdAt) < 900000 && (
                      <button
                        className="edit-button"
                        onClick={() => handleEditClick(msg)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    )}
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteClick(msg._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {editingMessage && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <button
              className="edit-modal-close"
              onClick={() => setEditingMessage(null)}
            >
              &times;
            </button>
            <h2>Edit Message</h2>
            <textarea value={editInput} onChange={handleEditChange} />
            <button className="save-edit-button" onClick={handleEditSave}>
              Save
            </button>
            <button
              className="cancel-edit-button"
              onClick={() => setEditingMessage(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* <ToastContainer /> */}
      <ApplicantFooter />
    </>
  );
};

export default Forum;
