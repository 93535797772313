import React from "react";
import { Link } from "react-router-dom";
import "./PdfCard.css";

function PdfCard({ pdfPost }) {
  const date = new Date(pdfPost.postedDate);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;
  return (
    <div>
      <Link
        to={{
          pathname: `/pdf-details/${pdfPost._id}`,
          state: { pdfPost: pdfPost },
        }}
        className="link-job-details-page"
      >
        <div className="pdf-card">
          <div className="pdf-card-content">
            <h5>{pdfPost.jobTitle}</h5>
            <span>{formattedDate}</span>
            <p>JD attached</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PdfCard;
