import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "./PdfPostModal.css";

function PdfModal({ modalThreeOpen, closeModalThree }) {
  const [pdfUploaded, setPdfUploaded] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [applicationDeadline, setDeadline] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [postedDate, setPostedDate] = useState(new Date());
  const [companyDetailsExist, setCompanyDetailsExist] = useState(false);

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [postedBy, setPostedBy] = useState(userData?.email);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchCompanyDetails() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/company-details/${postedBy}`
        );
        if (response.status === 200) {
          setCompanyDetailsExist(true);
        } else {
          setCompanyDetailsExist(false);
          throw new Error("Company details not found");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
        toast.error(
          "Company details not found. Please upload company details before posting a PDF.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }
    if (postedBy) {
      fetchCompanyDetails();
    }
  }, [postedBy]);

  const modalDisplay = modalThreeOpen ? "block" : "none";

  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    setPdfUploaded(file);
  };

  const handleSubmit = async () => {
    if (!companyDetailsExist) {
      toast.error("You need to upload company details before posting a PDF.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!pdfUploaded) {
      console.error("No file selected");
      return;
    }

    // Ask for confirmation
    const isConfirmed = window.confirm("Are you sure you want to submit?");
    if (!isConfirmed) {
      return;
    }

    const formData = new FormData();
    formData.append("pdf", pdfUploaded);
    formData.append("jobTitle", jobTitle);
    formData.append("applicationDeadline", applicationDeadline);
    formData.append("jobLocation", jobLocation);
    formData.append("postedDate", postedDate);
    formData.append("postedBy", postedBy);

    try {
      await axios.post(`${apiUrl}/api/upload-pdf`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // closeModalThree();
      setJobTitle("");
      setDeadline("");
      setJobLocation("");
      setPdfUploaded(false);
      toast.success("PDF posted successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error posting PDF:", error);
      toast.error("Failed to post job listing. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleNext = () => {
    if (pdfUploaded) {
      // If PDF is uploaded, show the form
      setShowForm(true);
    } else {
      // Handle next logic when PDF is not uploaded (if needed)
    }
  };

  return (
    <>
      {modalThreeOpen && (
        <div className="modal-overlay" onClick={closeModalThree}></div>
      )}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Upload PDF and Enter details</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalThree}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!showForm && (
                <div className="upload-pdf-section">
                  <div className="upload-input-div">
                    <input
                      className="pdf-input form-control"
                      type="file"
                      name="pdf"
                      onChange={handlePdfUpload}
                    />
                    <p>
                      Drag 'n' drop a PDF file here, or click to select a file
                    </p>
                  </div>
                </div>
              )}
              {showForm && pdfUploaded && (
                <div className="upload-text-section">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="jobTitle" className="form-label">
                        Job Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="deadline" className="form-label">
                        Application Deadline
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="deadline"
                        value={applicationDeadline}
                        onChange={(e) => setDeadline(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="jobLocation" className="form-label">
                        Job Location
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobLocation"
                        value={jobLocation}
                        onChange={(e) => setJobLocation(e.target.value)}
                        required
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModalThree}
              >
                Close
              </button>
              {!showForm && (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleNext}
                >
                  Next
                </button>
              )}
              {showForm && pdfUploaded && (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      </div>
    </>
  );
}

export default PdfModal;
