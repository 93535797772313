import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./NavbarLanding.css";

const NavbarLanding = () => {
  return (
    <nav className="navbar border-bottom navbar-expand-lg">
      <div className="container-fluid" style={{ userSelect: "none" }}>
        <Link className="navbar-brand" to="/">
          Jobs Maritime
        </Link>
      </div>
    </nav>
  );
};

export default NavbarLanding;
