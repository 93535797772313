import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./Pages/Landing/Landing";
import Company from "./Pages/Company/Company";
import Admin from "./Pages/Admin/Admin";
import PostedJobs from "./Pages/Company/PostedJobs/PostedJobs";
import JobDetailsPage from "./Pages/Company/PostedJobs/JobDetailsPage/JobDetailsPage";
import JobLinkDetailsPage from "./Pages/Company/PostedJobs/JobLinkDetailsPage/JobLinkDetailsPage";
import PdfDetailsPage from "./Pages/Company/PostedJobs/PdfDetailsPage/PdfDetailsPage";
import Applicant from "./Pages/Applicant/Applicant";
import ApplicantJobDetailsPage from "./Pages/Applicant/ApplicantJobCard/ApplicantJobDetailsPage/ApplicantJobDetailsPage";
import ApplicantLinkJobDetailsPage from "./Pages/Applicant/ApplicantLinkJobCard/ApplicantLinkJobDetailsPage/ApplicantLinkJobDetailsPage";
import ApplicantPdfJobDetailsPage from "./Pages/Applicant/ApplicantPdfJobCard/ApplicantPdfJobDetailsPage/ApplicantPdfJobDetailsPage";
import ForgotPassword from "./Pages/Login/ForgotPassword/ForgotPassword";
import Alternates from "./Components/TabularData/Alternates";
import Forum from "./Components/ForumSection/Forum";
// import Test from "./Components/Test";
import Feedback from "./Components/FeedbackForm/Feedback.jsx";
import AboutUs from "./Pages/AboutUs/AboutUs.jsx";
import ResumeBuilder from "./Pages/Applicant/ResumeBuilder/ResumeBuilder.jsx";
import NetworkStatus from "./Components/NetworkStatus/NetworkStatus.jsx";

function App() {
  // ProtectedRoute component for 'admin' role
  const ProtectedRouteAdmin = ({ element }) => {
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const role = userData?.role;
    if (role !== "admin") {
      return <Navigate to="/" />;
    }
    return element;
  };

  // ProtectedRoute component for 'applicant' role
  const ProtectedRouteApplicant = ({ element }) => {
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const role = userData?.role;
    if (role !== "applicant") {
      return <Navigate to="/" />;
    }
    return element;
  };

  // ProtectedRoute component for 'company' role
  const ProtectedRouteCompany = ({ element }) => {
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const role = userData?.role;
    if (role !== "company") {
      return <Navigate to="/" />;
    }
    return element;
  };

  return (
    <>
      <NetworkStatus />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route
          path="/admin"
          element={<ProtectedRouteAdmin element={<Admin />} />}
        />
        <Route
          path="/applicant"
          element={<ProtectedRouteApplicant element={<Applicant />} />}
        />
        <Route
          path="/applicant-job/:id"
          element={
            <ProtectedRouteApplicant element={<ApplicantJobDetailsPage />} />
          }
        />
        <Route
          path="/applicant-job-link/:id"
          element={
            <ProtectedRouteApplicant
              element={<ApplicantLinkJobDetailsPage />}
            />
          }
        />
        <Route
          path="/applicant-job-pdf/:id"
          element={
            <ProtectedRouteApplicant element={<ApplicantPdfJobDetailsPage />} />
          }
        />
        <Route
          path="/applicant/alternates"
          element={<ProtectedRouteApplicant element={<Alternates />} />}
        />
        <Route
          path="/applicant/forums"
          element={<ProtectedRouteApplicant element={<Forum />} />}
        />
        <Route
          path="/applicant/resume-builder"
          element={<ProtectedRouteApplicant element={<ResumeBuilder />} />}
        />
        {/* <Route
        path="/test"
        element={<ProtectedRouteApplicant element={<Test />} />}
      /> */}
        <Route
          path="/company"
          element={<ProtectedRouteCompany element={<Company />} />}
        />
        <Route
          path="/jobs-posted"
          element={<ProtectedRouteCompany element={<PostedJobs />} />}
        />
        <Route
          path="/job/:id"
          element={<ProtectedRouteCompany element={<JobDetailsPage />} />}
        />
        <Route
          path="/job-link/:id"
          element={<ProtectedRouteCompany element={<JobLinkDetailsPage />} />}
        />
        <Route
          path="/pdf-details/:id"
          element={<ProtectedRouteCompany element={<PdfDetailsPage />} />}
        />
      </Routes>
    </>
  );
}

export default App;
