import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./LoginModal.css";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const apiUrl = process.env.REACT_APP_API_URL;

function LoginModalTwo({ modalTwoOpen, closeModalTwo, jobId }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Please select the role");
  const [alertColor, setAlertColor] = useState("primary");
  const navigate = useNavigate();

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert email to lowercase and handle role selection
    if (name === "role") {
      if (value === "applicant") {
        setAlertMessage("Applicant role selected");
        setAlertColor("success");
      } else if (value === "company") {
        setAlertMessage("Company role selected");
        setAlertColor("success");
      }
    }
    // Convert email to lowercase
    setFormData({
      ...formData,
      [name]: name === "email" ? value.toLowerCase() : value,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/login`, formData);
      console.log(response.data);

      // Store user data in localStorage
      localStorage.setItem("user", JSON.stringify(response.data));

      setFormData({
        email: "",
        password: "",
        role: "",
      });

      // Redirect to appropriate page based on the role
      switch (response.data.role) {
        case "admin":
          navigate("/admin");
          break;
        case "applicant":
          navigate(`/applicant-job/${jobId}`);
          break;
        case "company":
          navigate(`/job/${jobId}`);
          break;
        default:
          navigate("/");
          break;
      }

      toast.success("Login successful!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error logging in:", error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Incorrect email or password!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (
          error.response.status === 400 &&
          error.response.data.message === "User not found"
        ) {
          toast.error("User not found. Please check your email address.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (error.response.status === 404) {
          toast.error("Invalid role. Please select a valid role.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        toast.error("Error logging in: " + error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const modalDisplay = modalTwoOpen ? "block" : "none";

  return (
    <div className="modal" style={{ display: modalDisplay }}>
      <div className="modal-dialog modal-dialog-scrollable modal-fullscreen-md-down">
        <div className="modal-content">
          <div className="modal-header bg-info">
            <h1 className="modal-title fs-5">Login - Ad</h1>
            <button
              type="button"
              className="btn-close"
              onClick={closeModalTwo}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-div">
              <div>
                <form className="form-tag" onSubmit={handleSubmit}>
                  <div className="mb-3 form-check">
                    {/* <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="inlineRadio1_2"
                        value="admin"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1_2"
                      >
                        Admin
                      </label>
                    </div> */}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="inlineRadio2_2"
                        value="applicant"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2_2"
                      >
                        Applicant
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="inlineRadio3_2"
                        value="company"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio3_2"
                      >
                        Company
                      </label>
                    </div>
                  </div>

                  <div className="alert-div">
                    <div className={`alert alert-${alertColor}`} role="alert">
                      {alertMessage}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1_2"
                      className="form-label"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1_2"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1_2"
                      className="form-label"
                    >
                      Password
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="exampleInputPassword1_2"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <div className="eye-icon" onClick={handleTogglePassword}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </div>
                  <Link to="/forgot-password" className="forgot-password-link">
                    Forgot Password?
                  </Link>
                  <div className="form-button-div">
                    <button
                      type="submit"
                      className="btn btn-outline-primary mt-3"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={closeModalTwo}
            >
              Close
            </button>
            {/* <button type="button" className="btn btn-outline-primary">
              Post Job
            </button> */}
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
    </div>
  );
}

export default LoginModalTwo;
