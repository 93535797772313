import React, { useState, useEffect } from "react";
import "./Company.css";
import { IoIosArrowDropdown } from "react-icons/io";
import CompanyDetailsModal from "./CompanyDetails/CompanyDetailsModal";
import LinkPostModal from "./LinkPostModal/LinkPostModal";
import PdfPostModal from "./PdfPostModal/PdfPostModal";
import JobPostModal from "./JobPostModal/JobPostModal";
import NavbarCompany from "../../Components/NavbarCompany/NavbarCompany";
import { IoBusinessOutline } from "react-icons/io5";
import { LuUserCheck2 } from "react-icons/lu";
import { GrDocumentPerformance } from "react-icons/gr";
import MatchedCandidate from "./MatchedCandidate/MatchedCandidate";
import { Tooltip } from "react-tooltip";
import CompanyProfilePicture from "./CompanyProfilePicture/CompanyProfilePicture";
import CompanyFooter from "../../Components/Footer/CompanyFooter";
const apiUrl = process.env.REACT_APP_API_URL;

function Company() {
  const [CompanyModalOpen, setCompanyModalOpen] = useState(false);
  const [isPageBlurred, setIsPageBlurred] = useState(false);
  const [modalOneOpen, setModalOneOpen] = useState(false);
  const [modalTwoOpen, setModalTwoOpen] = useState(false);
  const [modalThreeOpen, setModalThreeOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [jobListings, setJobListings] = useState([]);
  const [linkPosts, setLinkPosts] = useState([]);
  const [pdfPosts, setPdfPosts] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);

  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const openCompanyModal = () => {
    setCompanyModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeCompanyModal = () => {
    setCompanyModalOpen(false);
    setIsPageBlurred(false);
  };

  const openModalOne = () => {
    setModalOneOpen(true);
    setIsPageBlurred(true);
  };

  const closeModalOne = () => {
    setModalOneOpen(false);
    setIsPageBlurred(false);
  };

  const openModalTwo = () => {
    setModalTwoOpen(true);
    setIsPageBlurred(true);
  };

  const closeModalTwo = () => {
    setModalTwoOpen(false);
    setIsPageBlurred(false);
  };

  const openModalThree = () => {
    setModalThreeOpen(true);
    setIsPageBlurred(true);
  };

  const closeModalThree = () => {
    setModalThreeOpen(false);
    setIsPageBlurred(false);
  };

  const openProfileModal = () => {
    setProfileModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeProfileModal = () => {
    setProfileModalOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && modalOneOpen) {
        closeModalOne();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalOneOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && openCompanyModal) {
        closeCompanyModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openCompanyModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && modalTwoOpen) {
        closeModalTwo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalTwoOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && modalThreeOpen) {
        closeModalThree();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalThreeOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && profileModalOpen) {
        closeProfileModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [profileModalOpen]);

  useEffect(() => {
    fetchJobListings();
    fetchLinkPosts();
    fetchPdfPosts();
  }, []);

  const fetchJobListings = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const email = userData?.email;

      if (!email) {
        console.error("User email not found in local storage");
        return;
      }

      const response = await fetch(`${apiUrl}/api/joblistings`, {
        headers: {
          email: email,
        },
      });
      const data = await response.json();

      setJobListings(data);
    } catch (error) {
      console.error("Error fetching job listings:", error);
    }
  };

  const fetchLinkPosts = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const email = userData?.email;

      if (!email) {
        console.error("User email not found in local storage");
        return;
      }

      const response = await fetch(`${apiUrl}/api/linkposts`, {
        headers: {
          email: email,
        },
      });
      const data = await response.json();

      setLinkPosts(data);
    } catch (error) {
      console.error("Error fetching link posts:", error);
    }
  };

  const fetchPdfPosts = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const email = userData?.email;

      if (!email) {
        console.error("User email not found in local storage");
        return;
      }

      const response = await fetch(`${apiUrl}/api/upload-pdf`, {
        headers: {
          email: email,
        },
      });
      const data = await response.json();

      setPdfPosts(data);
    } catch (error) {
      console.error("Error fetching PDF posts:", error);
    }
  };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      setSelectedItemId(null); // Reset selectedItemId when closing dropdown
      setSelectedJobTitle(null);
    }
  };

  const handleItemClick = (itemId, job_title) => {
    setSelectedItemId(itemId);
    setSelectedJobTitle(job_title);
    toggleDropdown();
  };

  useEffect(() => {
    const compareAndSetDefaults = () => {
      if (jobListings.length > 0 && !selectedItemId) {
        setSelectedItemId(jobListings[0]._id);
        setSelectedJobTitle(jobListings[0].jobTitle);
      } else if (linkPosts.length > 0 && !selectedItemId) {
        setSelectedItemId(linkPosts[0]._id);
        setSelectedJobTitle(linkPosts[0].jobTitle);
      } else if (pdfPosts.length > 0 && !selectedItemId) {
        setSelectedItemId(pdfPosts[0]._id);
        setSelectedJobTitle(pdfPosts[0].jobTitle);
      }
    };

    compareAndSetDefaults();
  }, [jobListings, linkPosts, pdfPosts, selectedItemId]);

  return (
    <div className="company-page">
      <NavbarCompany />
      <div className={isPageBlurred ? "blur-background" : "container"}>
        <div className="row">
          <div className="col-lg-5 col-md-12 col-12 cards-to-upload">
            <div className="section">
              <div className="card-1">
                <h2
                  style={{
                    color: "#22b4ed",
                    fontSize: "1.7rem",
                    userSelect: "none",
                  }}
                >
                  Upload Company Details
                </h2>
                <div className="company-details-logo">
                  <button
                    className="btn btn-primary"
                    style={{
                      marginTop: "2rem",
                      backgroundColor: "#22b4ed",
                      border: "#ff904b",
                    }}
                    onClick={openCompanyModal}
                  >
                    Upload details
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{
                      marginTop: "2rem",
                      backgroundColor: "#22b4ed",
                      border: "#ff904b",
                    }}
                    onClick={openProfileModal}
                  >
                    Upload Logo
                  </button>
                </div>
              </div>
              <div className="card-1 card2">
                <h2
                  style={{
                    color: "#22b4ed",
                    fontSize: "1.7rem",
                    userSelect: "none",
                  }}
                >
                  Jobs Posting
                </h2>
                <p style={{ userSelect: "none" }}>
                  Upload vacancies and requisite experiences
                </p>
                <div className="row ">
                  <div className="col-4">
                    <button
                      className="btn btn-secondary"
                      style={{
                        marginTop: "2rem",
                        backgroundColor: "#22b4ed",
                        border: "#ff904b",
                      }}
                      onClick={openModalOne}
                    >
                      Post Job
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-secondary"
                      style={{
                        marginTop: "2rem",
                        backgroundColor: "#22b4ed",
                        border: "#ff904b",
                      }}
                      onClick={openModalTwo}
                    >
                      Post Link
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-secondary"
                      style={{
                        marginTop: "2rem",
                        backgroundColor: "#22b4ed",
                        border: "#ff904b",
                      }}
                      onClick={openModalThree}
                    >
                      Post PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <br style={{ userSelect: "none" }} />
              <div className="section">
                <h3 className="section-headingg">
                  How It Works: Follow These Steps
                </h3>
                <div className="steps-container">
                  <div className="step-one">
                    <div>
                      <IoBusinessOutline />
                    </div>
                    <div className="steps-text">Fill company details.</div>
                  </div>
                  <div className="step-two">
                    <div>
                      <GrDocumentPerformance />
                    </div>
                    <div className="steps-text">
                      Post jobs via Form, Link or PDF.
                    </div>
                  </div>
                  <div className="step-three">
                    <div>
                      <LuUserCheck2 />
                    </div>
                    <div className="steps-text">
                      Finalize applied candidates.
                    </div>
                  </div>
                </div>
              </div>
              <br style={{ userSelect: "none" }} />
              <br style={{ userSelect: "none" }} />
              <br style={{ userSelect: "none" }} />
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-12 matched-candidate-section">
            <div className="section d-flex justify-content-between align-items-center">
              <h3 className="section-heading matched-candidates">
                Matched Candidates
              </h3>
              <button
                className="btn btn-sm btn-primary"
                style={{
                  marginTop: "20px",
                  backgroundColor: "#ff904b",
                  border: "#ff904b",
                }}
                onClick={toggleDropdown}
              >
                <i className="bi bi-arrow-right">
                  <IoIosArrowDropdown size={24} />
                </i>
              </button>
              {dropdownOpen && (
              <div
                className="dropdown-menu"
                style={{ display: "block", marginTop: "0.5rem" }}
              >
                
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            )}
            </div>

            <div className="matched-candidates-div"></div>
          </div> */}

          <div className="col-lg-7 col-md-12 col-12 matched-candidate-section">
            <div className="section-company d-flex justify-content-between align-items-center">
              <h3
                className="section-heading matched-candidates"
                style={{ userSelect: "none" }}
              >
                Matched Candidates -{" "}
                {/* <div className="matched-candidates-search-bar">
                  <div>Matched Candidates </div> 
                  <div className="company-search-bar-div">
                    <div className="company-search">
                      <input 
                        placeholder="Search..."
                        type="text"
                        className="input-search-bar"
                      />
                      <button type="submit">Search</button>
                    </div>
                  </div>
                </div> */}
                <span className="matched-job-title">{selectedJobTitle}</span>
              </h3>
              <div className="dropdown" style={{ position: "relative" }}>
                <button
                  className="btn btn-sm btn-primary"
                  style={{
                    marginTop: "25px",
                    backgroundColor: "#22b4ed",
                    border: "#ff904b",
                  }}
                  onClick={toggleDropdown}
                  data-tooltip-id="my-tooltip1"
                  data-tooltip-content="Select Job to View Applicants"
                  data-tooltip-delay-show={10}
                  data-tooltip-place="top-start"
                >
                  <i className="bi bi-arrow-right">
                    <IoIosArrowDropdown size={24} />
                  </i>
                </button>
                <Tooltip id="my-tooltip1" effect="solid" />
                {dropdownOpen && (
                  <div
                    className="dropdown-menu"
                    style={{
                      display: "block",
                      position: "absolute",
                      left: "-530%",
                      top: "100%",
                      zIndex: 1000,
                      backgroundColor: "#fff",
                      border: "1px solid #ddd",
                      borderRadius: "0.5rem",
                      padding: "0.5rem 0",
                      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
                      transition: "opacity 0.3s ease-in-out",
                    }}
                  >
                    {jobListings.length === 0 &&
                    linkPosts.length === 0 &&
                    pdfPosts.length === 0 ? (
                      <div
                        className="dropdown-item"
                        style={{ color: "red", cursor: "default" }}
                      >
                        No Jobs posted
                      </div>
                    ) : (
                      <>
                        {jobListings.map((job) => (
                          <div
                            key={job._id}
                            className="dropdown-item dropdown-element"
                            onClick={() =>
                              handleItemClick(job._id, job.jobTitle)
                            }
                          >
                            {job.jobTitle}
                          </div>
                        ))}
                        {linkPosts.map((job) => (
                          <div
                            key={job._id}
                            className="dropdown-item dropdown-element"
                            onClick={() =>
                              handleItemClick(job._id, job.jobTitle)
                            }
                          >
                            {job.jobTitle}
                          </div>
                        ))}
                        {pdfPosts.map((job) => (
                          <div
                            key={job._id}
                            className="dropdown-item dropdown-element"
                            onClick={() =>
                              handleItemClick(job._id, job.jobTitle)
                            }
                          >
                            {job.jobTitle}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="matched-candidates-div">
              <MatchedCandidate
                selectedItemId={selectedItemId}
                selectedJobTitle={selectedJobTitle}
              />
            </div>
          </div>

          {/* <div className="col-lg-6 col-md-6 col-12">
            <div className="section">
              <h3 className="section-headingg">Steps</h3>
              <div className="steps-container">
                <div className="step-one">
                  <div>
                    <IoBusinessOutline />
                  </div>
                  <div className="steps-text">Fill company details.</div>
                </div>
                <div className="step-two">
                  <div>
                    <GrDocumentPerformance />
                  </div>
                  <div className="steps-text">
                    Post jobs via Form, Link or PDF.
                  </div>
                </div>
                <div className="step-three">
                  <div>
                    <LuUserCheck2 />
                  </div>
                  <div className="steps-text">Finalize applied candidates.</div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <CompanyDetailsModal
        modalOpen={CompanyModalOpen}
        closeModal={closeCompanyModal}
      />
      <JobPostModal modalOneOpen={modalOneOpen} closeModalOne={closeModalOne} />
      <LinkPostModal
        modalTwoOpen={modalTwoOpen}
        closeModalTwo={closeModalTwo}
      />
      <PdfPostModal
        modalThreeOpen={modalThreeOpen}
        closeModalThree={closeModalThree}
      />
      <CompanyProfilePicture
        profileModalOpen={profileModalOpen}
        closeProfileModal={closeProfileModal}
      />
      <CompanyFooter />
    </div>
  );
}

export default Company;
