// import React from "react";
// import "./Vision.css";
// import img5 from "../static/img-2.png";
// import img6 from "../static/img-3.png";
// import { Link } from "react-router-dom";

// function Vision() {
//   return (
//     <div className="vision-container">
//       <section className="vision-section">
//         <h1>Our Vision</h1>
//         <p>
//           We strive to innovate and improve the recruitment process, ensuring a
//           perfect fit between employers and job seekers.
//         </p>
//       </section>

//       <section className="what-we-do-section">
//         <h1>What do we do?</h1>
//         <div className="vision-cards-container">
//           <div className="vision-card">
//             <div className="vision-card-content">
//               <h1>For Employers</h1>
//               <p>
//                 We offer maritime companies a comprehensive platform to post job
//                 listings, manage applications, and find the best talent. Whether
//                 you’re looking for experienced seafarers, skilled engineers, or
//                 specialized maritime professionals, Jobs Maritime makes the
//                 recruitment process seamless and efficient.
//               </p>
//               <Link to="/">Explore more -&gt;</Link>
//             </div>
//             <img src={img5} alt="For Employers" />
//           </div>
//           <div className="vision-card">
//             <div className="vision-card-content">
//               <h1>For Job Seekers</h1>
//               <p>
//                 As a candidate, you can explore a wide range of job
//                 opportunities in the maritime industry, from deckhands to
//                 captains, and from marine engineers to shore-based positions.
//                 Our platform allows you to create a detailed profile, upload
//                 your resume, and apply to jobs that match your skills.
//               </p>
//               <Link to="/">Explore more -&gt;</Link>
//             </div>
//             <img src={img6} alt="For Job Seekers" />
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default Vision;

import React from "react";
import "./Vision.css";
import img5 from "../static/img-2.png";
import img6 from "../static/img-3.png";
import { Link } from "react-router-dom";

function Vision() {
  return (
    <div className="vision-container">
      <section className="vision-section">
        <h1>Our Vision</h1>
        <p>
          We strive to innovate and improve the recruitment process, ensuring a
          perfect fit between employers and job seekers.
        </p>
      </section>

      <section className="what-we-do-section">
        <h1>Services</h1>
        <div className="vision-cards-container">
          <div className="vision-card">
            <div className="vision-card-content">
              <h3>For Employers</h3>
              <ul>
                <li>Streamline job postings and manage applications.</li>
                <li>
                  Connect with top maritime talent including seafarers,
                  engineers, and other professionals.
                </li>
                <li>Make the hiring process smooth and efficient.</li>
              </ul>
              <Link to="/">Explore more -&gt;</Link>
            </div>
            <img src={img5} alt="For Employers" />
          </div>
          <div className="vision-card">
            <div className="vision-card-content">
              <h3>For Job Seekers</h3>
              <ul>
                <li>
                  Get personalized job recommendations with our AI-powered bot.
                </li>
                <li>
                  Explore a range of maritime opportunities from deckhands to
                  captains and engineers.
                </li>
                <li>
                  Create a profile, upload your resume, and apply to roles that
                  match your skills.
                </li>
              </ul>
              <Link to="/">Explore more -&gt;</Link>
            </div>
            <img src={img6} alt="For Job Seekers" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Vision;
