import React, { useState, useCallback } from "react";
import axios from "axios";
import Cropper from "react-easy-crop";
import getCroppedImg from "../cropImage.js"; // Utility function to get the cropped image
import { toast } from "react-toastify";
import "./CompanyProfilePictureEditModal.css";
const apiUrl = process.env.REACT_APP_API_URL;

function CompanyProfilePictureEditModal({ modalOpen, closeModal }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userEmail = userData?.email;
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const modalDisplay = modalOpen ? "block" : "none";

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const formData = new FormData();
      formData.append("image", croppedImage);

      const response = await axios.put(
        `${apiUrl}/api/upload-company-profile/${userEmail}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Profile picture updated successfully!");
        closeModal();
      } else {
        toast.error("Failed to update profile picture.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image.");
    }
  };

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-l modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Edit your profile picture</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p style={{ userSelect: "none" }}>
                <strong>Note - </strong>Please upload image of size less than 2
                MB.
              </p>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageSrc && (
                <div className="crop-container">
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={uploadImage}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyProfilePictureEditModal;
