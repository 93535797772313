import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import email from "../../Assets/footer-assets/icons8-email-clr.png";
import instagram from "../../Assets/footer-assets/icons8-instagram-clr1.png";
import linkedin from "../../Assets/footer-assets/icons8-linkedin-100.png";
import location from "../../Assets/footer-assets/icons8-location-clr.png";
import phone from "../../Assets/footer-assets/icons8-phone1-clr.png";
import x from "../../Assets/footer-assets/icons8-x-1.png";

const Footer = () => {
  const handlePhoneClick = () => {
    window.location.href = "tel:+918130971696";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:jobsmaritimegpt@gmail.com";
  };

  const handleLocationClick = () => {
    window.location.href =
      "https://www.google.com/maps?q=110+Hilmanton+Reading+RG6+4HJ,+UK";
  };

  return (
    <div className="footer" id="footer">
      <div className="footer-content ">
        <div className="footer-logo">
          <p>JOBS MARITIME</p>
        </div>
        <div className="footer-social-icons">
          <a
            href="https://www.linkedin.com/in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="Facebook" />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} alt="Twitter" />
          </a>
          <a
            href="https://instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="LinkedIn" />
          </a>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <Link to="/applicant">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/applicant/forums">Forum</Link>
            </li>
            <li>
              {/* <a href="/feedback" target="_blank" rel="noopener noreferrer">
                Feedback
              </a> */}
              <Link to="/feedback">Feedback</Link>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <ul>
            <li onClick={handlePhoneClick}>
              <img src={phone} alt="Phone" /> +91-8130971696
            </li>
            <li onClick={handleEmailClick}>
              <img src={email} alt="Email" /> jobsmaritimegpt@gmail.com
            </li>
            <li onClick={handleLocationClick}>
              <img src={location} alt="Location" /> 110 Hilmanton Reading RG6
              4HJ, UK
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <p className="footer-copywrite">
        Copyright © 2024 Jobs-Maritime. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
