import React, { useState, useEffect } from "react";
import "./LinkPostModal.css";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

function LinkPostModal({ modalTwoOpen, closeModalTwo }) {
  const [formData, setFormData] = useState({
    jobTitle: "",
    companyName: "",
    applicationDeadline: "",
    link: "",
  });

  const [postedDate, setPostedDate] = useState(new Date());
  const [companyDetailsExist, setCompanyDetailsExist] = useState(false);

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [postedBy, setPostedBy] = useState(userData?.email);

  useEffect(() => {
    async function fetchCompanyDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/company-details/${postedBy}`
        );
        if (response.status === 200) {
          setCompanyDetailsExist(true);
        } else {
          setCompanyDetailsExist(false);
          throw new Error("Company details not found");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
        toast.error(
          "Company details not found. Please upload company details before posting job.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }
    if (postedBy) {
      fetchCompanyDetails();
    }
  }, [postedBy]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPostedDate(new Date());
    }, 1000); // Update every second

    // Clean up the interval
    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async () => {
    if (!companyDetailsExist) {
      toast.error("You need to upload company details before posting a link.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    // Show confirmation dialog
    const confirmed = window.confirm("Are you sure you want to submit?");

    // Check if user confirmed
    if (confirmed) {
      try {
        const response = await fetch(`${apiUrl}/api/linkposts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, postedDate, postedBy }),
        });

        if (!response.ok) {
          throw new Error("Failed to post link");
        }

        setFormData({
          jobTitle: "",
          companyName: "",
          applicationDeadline: "",
          link: "",
        });

        toast.success("Link posted successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } catch (error) {
        console.error("Error posting link:", error);
        toast.error("Failed to post job listing. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      // User clicked "Cancel", do nothing
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const modalDisplay = modalTwoOpen ? "block" : "none";

  return (
    <>
      {modalTwoOpen && (
        <div className="modal-overlay" onClick={closeModalTwo}></div>
      )}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Enter link details</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalTwo}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    placeholder="Job Title"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Company Name"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="date"
                    className="form-control"
                    name="applicationDeadline"
                    value={formData.applicationDeadline}
                    onChange={handleChange}
                    placeholder="Application Deadline"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="url"
                    className="form-control"
                    name="link"
                    value={formData.link}
                    onChange={handleChange}
                    placeholder="Link"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModalTwo}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleSubmit}
              >
                Post Link
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      </div>
    </>
  );
}

export default LinkPostModal;
