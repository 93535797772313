import React from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import "./ApplicantDetailsModal.css";

function ApplicantDetailsModal({
  modalThreeOpen,
  closeModalThree,
  selectedCvPost,
  profileImages,
  downloadPdf,
  openGmail,
}) {
  const modalDisplay = modalThreeOpen ? "block" : "none";

  // Helper function to check if a value exists
  const hasValue = (value) => value && value.trim() !== "";

  return (
    <>
      {modalThreeOpen && (
        <div className="modal-overlay" onClick={closeModalThree}></div>
      )}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-fullscreen-lg-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Candidate Details</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalThree}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedCvPost ? (
                <div className="details-applicant-details-container">
                  {/* Profile Picture and Name Section */}
                  <div className="details-profile-header">
                    {profileImages[selectedCvPost.email] ? (
                      <img
                        src={profileImages[selectedCvPost.email]}
                        alt="Profile"
                        className="details-company-side-applicant-profile-image"
                      />
                    ) : (
                      <FaRegCircleUser className="details-default-profile-icon" />
                    )}
                    <div className="details-name-section">
                      <h2>
                        {selectedCvPost.name} {selectedCvPost.lastName}
                      </h2>
                      {hasValue(selectedCvPost.email) && (
                        <p>
                          <strong>Email:</strong> {selectedCvPost.email}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Additional Details Section */}
                  <div className="details-other-details">
                    <div className="details-detail-column">
                      {/* {hasValue(selectedCvPost.email) && (
                      <p>
                        <strong>Email:</strong> {selectedCvPost.email}
                      </p>
                    )} */}

                      {hasValue(selectedCvPost.mobile) && (
                        <p>
                          <strong>Mobile:</strong> {selectedCvPost.mobile}
                        </p>
                      )}
                      {hasValue(selectedCvPost.nationality) && (
                        <p>
                          <strong>Nationality:</strong>{" "}
                          {selectedCvPost.nationality}
                        </p>
                      )}
                      {hasValue(selectedCvPost.address) && (
                        <p>
                          <strong>Address:</strong> {selectedCvPost.address}
                        </p>
                      )}
                      {hasValue(selectedCvPost.dob) && (
                        <p>
                          <strong>Date of Birth:</strong> {selectedCvPost.dob}
                        </p>
                      )}
                      {hasValue(selectedCvPost.education) && (
                        <p>
                          <strong>Education:</strong> {selectedCvPost.education}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Recent Sea Service Section */}
                  {hasValue(selectedCvPost.engineType) ||
                  hasValue(selectedCvPost.vesselName) ||
                  hasValue(selectedCvPost.typeOfVessel) ||
                  hasValue(selectedCvPost.rankOrPosition) ||
                  hasValue(selectedCvPost.companyName) ||
                  hasValue(selectedCvPost.lookingForPosition) ? (
                    <>
                      <h2>Recent Sea Service</h2>
                      <div className="details-sea-service-details">
                        <div className="details-detail-column">
                          {hasValue(selectedCvPost.engineType) && (
                            <p>
                              <strong>Engine Type:</strong>{" "}
                              {selectedCvPost.engineType}
                            </p>
                          )}
                          {hasValue(selectedCvPost.vesselName) && (
                            <p>
                              <strong>Vessel Name:</strong>{" "}
                              {selectedCvPost.vesselName}
                            </p>
                          )}
                          {hasValue(selectedCvPost.typeOfVessel) && (
                            <p>
                              <strong>Type of Vessel:</strong>{" "}
                              {selectedCvPost.typeOfVessel}
                            </p>
                          )}
                        </div>
                        <div className="details-detail-column">
                          {hasValue(selectedCvPost.rankOrPosition) && (
                            <p>
                              <strong>Rank/Position:</strong>{" "}
                              {selectedCvPost.rankOrPosition}
                            </p>
                          )}
                          {hasValue(selectedCvPost.companyName) && (
                            <p>
                              <strong>Company Name:</strong>{" "}
                              {selectedCvPost.companyName}
                            </p>
                          )}
                          {hasValue(selectedCvPost.lookingForPosition) && (
                            <p>
                              <strong>Looking for Position:</strong>{" "}
                              {selectedCvPost.lookingForPosition}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                <p>No details available.</p>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() =>
                  downloadPdf(selectedCvPost.cvFile, selectedCvPost.name)
                }
              >
                Download CV
              </button>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() => openGmail(selectedCvPost)}
              >
                Contact via Email
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModalThree}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicantDetailsModal;
