import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ApplicantLinkJobCard.css";
import { FaRegCircleUser } from "react-icons/fa6";
const apiUrl = process.env.REACT_APP_API_URL;

function ApplicantLinkJobCard({ props }) {
  const date = new Date(props.postedDate);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;

  const [companyDetail, setCompanyDetails] = useState([]);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    async function fetchCompanyDetails() {
      try {
        const response = await fetch(
          `${apiUrl}/api/company-details/${props.postedBy}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company details");
        }
        const companyDetails = await response.json();
        setCompanyDetails(companyDetails);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    }

    async function fetchCompanyImage() {
      const imageResponse = await fetch(
        `${apiUrl}/api/upload-company-profile/${props.postedBy}`
      );

      if (imageResponse.ok) {
        setProfileImage(URL.createObjectURL(await imageResponse.blob()));
      } else {
        console.error(
          "Failed to fetch profile image:",
          imageResponse.statusText
        );
      }
    }
    if (props.postedBy) {
      fetchCompanyDetails();
      fetchCompanyImage();
    }
  }, [props.postedBy]);

  return (
    <div>
      <Link
        to={{
          pathname: `/applicant-job-link/${props._id}`,
          state: { job: props },
        }}
        className="link-job-details-page"
      >
        <div className="applicant-job-card-div">
          <div className="applicant-job-card-title">
            {profileImage ? (
              <img
                src={profileImage}
                alt="Profile"
                className="applicant-side-company-profile-image"
              />
            ) : (
              <FaRegCircleUser />
            )}
            <h5>{props.jobTitle}</h5>
          </div>
          <div className="applicant-job-card-details">
            <div>
              <p>{companyDetail.companyName}</p>
            </div>

            <div className="applicant-job-card-details-inner-div">
              <div>
                <p>URL attached</p>
              </div>
              <div>
                <span>{formattedDate}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ApplicantLinkJobCard;
