// import React, { useState } from "react";
// import axios from "axios";
// import "./CVPostModal.css";
// import { toast } from "react-toastify";

// function CVPostOCRModal({ modalTwoOpen, closeModalTwo }) {
//   const userDataString = localStorage.getItem("user");
//   const userData = JSON.parse(userDataString);
//   const [postedBy, setPostedBy] = useState(userData?.email);
//   const [postedDate, setPostedDate] = useState(new Date());
//   const [pdfFile, setPdfFile] = useState(null);
//   const [name, setName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [gender, setGender] = useState("");
//   const [email, setEmail] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [experience, setExperience] = useState(0);

//   const modalDisplay = modalTwoOpen ? "block" : "none";

//   const handleUpload = (event) => {
//     setPdfFile(event.target.files[0]);
//   };

//   const handleSubmit = async () => {
//     const formData = new FormData();
//     formData.append("cv", pdfFile);
//     formData.append("postedDate", postedDate);
//     formData.append("postedBy", postedBy);
//     formData.append("name", name);
//     formData.append("lastName", lastName);
//     formData.append("gender", gender);
//     formData.append("email", email);
//     formData.append("mobile", mobile);
//     formData.append("experience", experience);

//     try {
//       await axios.post("http://localhost:5000/api/upload-cv", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       setPdfFile(null);
//       setName("");
//       setLastName("");
//       setGender("");
//       setEmail("");
//       setMobile("");
//       setExperience(0);
//       toast.success("CV uploaded successfully", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//     } catch (error) {
//       console.error("Error uploading CV:", error);
//       if (error.response && error.response.status === 409) {
//         toast.info("CV already uploaded for this user", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       } else {
//         const errorMessage =
//           error.response && error.response.data
//             ? error.response.data
//             : "Failed to upload CV. Please try again later.";
//         toast.error(errorMessage, {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       }
//     }
//   };

//   return (
//     <div className="modal" style={{ display: modalDisplay }}>
//       <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5">Upload CV</h1>
//             <button
//               type="button"
//               className="btn-close"
//               onClick={closeModalTwo}
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             <form>
//               <div className="mb-3 row">
//                 <label htmlFor="cvUpload" className="col-sm-3 col-form-label">
//                   Upload Resume/CV:
//                 </label>
//                 <div className="col-sm-9">
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="cvUpload"
//                     accept=".pdf"
//                     onChange={handleUpload}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="mb-3 row">
//                 <label htmlFor="name" className="col-sm-3 col-form-label">
//                   Name:
//                 </label>
//                 <div className="col-sm-9">
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     placeholder="Enter your name"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="mb-3 row">
//                 <label htmlFor="lastName" className="col-sm-3 col-form-label">
//                   Last Name:
//                 </label>
//                 <div className="col-sm-9">
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="lastName"
//                     placeholder="Enter your last name"
//                     value={lastName}
//                     onChange={(e) => setLastName(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="mb-4 row">
//                 <label htmlFor="gender" className="col-sm-3 col-form-label">
//                   Gender:
//                 </label>
//                 <div className="col-sm-9">
//                   <select
//                     className="form-select"
//                     id="gender"
//                     value={gender}
//                     onChange={(e) => setGender(e.target.value)}
//                     required
//                   >
//                     <option value="">Select gender</option>
//                     <option value="male">Male</option>
//                     <option value="female">Female</option>
//                     <option value="other">Other</option>
//                   </select>
//                 </div>
//               </div>
//               <div className="mb-3 row">
//                 <label htmlFor="email" className="col-sm-3 col-form-label">
//                   E-Mail:
//                 </label>
//                 <div className="col-sm-9">
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     placeholder="Enter your email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="mb-3 row">
//                 <label htmlFor="mobile" className="col-sm-3 col-form-label">
//                   Mobile:
//                 </label>
//                 <div className="col-sm-9">
//                   <input
//                     type="string"
//                     className="form-control"
//                     id="mobile"
//                     placeholder="Enter your mobile number"
//                     value={mobile}
//                     onChange={(e) => setMobile(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="mb-3 row">
//                 <label htmlFor="Experience" className="col-sm-3 col-form-label">
//                   Experience in Years:
//                 </label>
//                 <div className="col-sm-9">
//                   <input
//                     type="number"
//                     className="form-control"
//                     id="Experience"
//                     placeholder="Enter your Experience in years"
//                     value={experience}
//                     onChange={(e) => setExperience(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//             </form>
//           </div>
//           <div className="modal-footer">
//             <button
//               type="button"
//               className="btn btn-outline-danger"
//               onClick={closeModalTwo}
//             >
//               Close
//             </button>
//             <button
//               type="button"
//               className="btn btn-outline-primary"
//               onClick={handleSubmit}
//             >
//               Upload
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CVPostOCRModal;

import React, { useState } from "react";
import axios from "axios";
import "./CVPostModal.css";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

function CVPostOCRModal({ modalTwoOpen, closeModalTwo }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [postedBy, setPostedBy] = useState(userData?.email);
  const [postedDate, setPostedDate] = useState(new Date());
  const [pdfFile, setPdfFile] = useState(null);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [education, setEducation] = useState("");
  const [engineType, setEngineType] = useState("");
  const [vesselName, setVesselName] = useState("");
  const [typeOfVessel, setTypeOfVessel] = useState("");
  const [rankOrPosition, setRankOrPosition] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [lookingForPosition, setLookingForPosition] = useState("");

  const [step, setStep] = useState(1); // Step state to track the current step

  const modalDisplay = modalTwoOpen ? "block" : "none";

  const handleUpload = (event) => {
    setPdfFile(event.target.files[0]);
  };

  //   const handleExtract = async () => {
  //     const formData = new FormData();
  //     formData.append("cv", pdfFile);

  //     try {
  //       const response = await axios.post(
  //         "http://localhost:5000/api/get-cv-info",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const data = response.data.document.entities;

  //       // Set extracted data to state
  //       data.forEach((entity) => {
  //         switch (entity.type) {
  //           case "first-name":
  //             setName(entity.mentionText);
  //             break;
  //           case "last-name":
  //             setLastName(entity.mentionText);
  //             break;
  //           case "nationality":
  //             setNationality(entity.mentionText);
  //             break;
  //           case "mobile-no":
  //             setMobile(entity.mentionText);
  //             break;
  //           case "address":
  //             setAddress(entity.mentionText);
  //             break;
  //           case "e-mail":
  //             setEmail(entity.mentionText);
  //             break;
  //           case "date-of-birth":
  //             setDob(entity.mentionText);
  //             break;
  //           case "education":
  //             setEducation(entity.mentionText);
  //             break;
  //           default:
  //             break;
  //         }
  //       });

  //       setStep(2); // Move to the next step
  //     } catch (error) {
  //       console.error("Error extracting CV:", error);
  //       toast.error("Failed to extract CV. Please try again later.", {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     }
  //   };

  const handleExtract = async () => {
    const formData = new FormData();
    formData.append("cv", pdfFile);

    toast.promise(
      axios
        .post(`${apiUrl}/api/get-cv-info`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const data = response.data.document.entities;

          // Set extracted data to state
          data.forEach((entity) => {
            switch (entity.type) {
              case "first-name":
                setName(entity.mentionText);
                break;
              case "last-name":
                setLastName(entity.mentionText);
                break;
              case "nationality":
                setNationality(entity.mentionText);
                break;
              case "mobile-no":
                setMobile(entity.mentionText);
                break;
              case "address":
                setAddress(entity.mentionText);
                break;
              case "e-mail":
                setEmail(entity.mentionText);
                break;
              case "date-of-birth":
                // Convert 'dd-mm-yyyy' to 'yyyy-mm-dd' format
                //   const [day, month, year] = entity.mentionText.split("-");
                //   setDob(`${year}-${month}-${day}`);

                const dobText = entity.mentionText;
                let formattedDob = "";

                // Check for dd-mm-yyyy format
                if (/^\d{2}-\d{2}-\d{4}$/.test(dobText)) {
                  const [day, month, year] = dobText.split("-");
                  formattedDob = `${year}-${month}-${day}`;
                }
                // Check for dd/mm/yyyy format
                else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dobText)) {
                  const [day, month, year] = dobText.split("/");
                  formattedDob = `${year}-${month}-${day}`;
                } else {
                  formattedDob = dobText; // Default to original text if format is unrecognized
                }

                setDob(formattedDob);
                break;
              case "education":
                setEducation(entity.mentionText);
                break;
              case "engine-type":
                setEngineType(entity.mentionText);
                break;
              case "vessel-name":
                setVesselName(entity.mentionText);
                break;
              case "type-of-vessel":
                setTypeOfVessel(entity.mentionText);
                break;
              case "rank-or-position":
                setRankOrPosition(entity.mentionText);
                break;
              case "company-name":
                setCompanyName(entity.mentionText);
                break;
              case "looking-for-position":
                setLookingForPosition(entity.mentionText);
                break;
              default:
                break;
            }
          });

          setStep(2); // Move to the next step
        })
        .catch((error) => {
          console.error("Error extracting CV:", error);
          throw new Error("Failed to extract CV. Please try again later.");
        }),
      {
        pending: "Processing your CV...",
        success: "CV processed successfully!",
        error: {
          render({ data }) {
            return data.message;
          },
        },
      },
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("cv", pdfFile);
    formData.append("postedDate", postedDate.toISOString());
    formData.append("postedBy", postedBy);
    formData.append("name", name);
    formData.append("lastName", lastName);
    formData.append("nationality", nationality);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("education", education);
    formData.append("engineType", engineType);
    formData.append("vesselName", vesselName);
    formData.append("typeOfVessel", typeOfVessel);
    formData.append("rankOrPosition", rankOrPosition);
    formData.append("companyName", companyName);
    formData.append("lookingForPosition", lookingForPosition);

    try {
      await axios.post(`${apiUrl}/api/upload-extracted-cv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPdfFile(null);
      setName("");
      setLastName("");
      setNationality("");
      setMobile("");
      setAddress("");
      setEmail("");
      setDob("");
      setEducation("");
      toast.success("CV uploaded successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      closeModalTwo(); // Close the modal after successful upload
      setStep(1);
    } catch (error) {
      console.error("Error uploading CV:", error);
      if (error.response && error.response.status === 409) {
        toast.info("CV already uploaded for this user", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data
            : "Failed to upload CV. Please try again later.";
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <>
      {modalTwoOpen && (
        <div className="modal-overlay" onClick={closeModalTwo}></div>
      )}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">
                {step === 1 ? "Upload CV" : "Extracted CV Details"}
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalTwo}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {step === 1 ? (
                <form>
                  <div className="m-3 row">
                    <label
                      htmlFor="cvUpload"
                      className="col-sm-3 col-form-label"
                    >
                      Upload Resume/CV:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control"
                        id="cvUpload"
                        accept=".pdf"
                        onChange={handleUpload}
                        required
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <form>
                  <div className="mb-3 row">
                    <label htmlFor="name" className="col-sm-3 col-form-label">
                      First Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your first name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="lastName"
                      className="col-sm-3 col-form-label"
                    >
                      Last Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="nationality"
                      className="col-sm-3 col-form-label"
                    >
                      Nationality:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="nationality"
                        placeholder="Enter your nationality"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="mobile" className="col-sm-3 col-form-label">
                      Mobile No.:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="address"
                      className="col-sm-3 col-form-label"
                    >
                      Address:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="email" className="col-sm-3 col-form-label">
                      E-Mail:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="dob" className="col-sm-3 col-form-label">
                      Date of Birth:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="education"
                      className="col-sm-3 col-form-label"
                    >
                      Education:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="education"
                        placeholder="Enter your education"
                        value={education}
                        onChange={(e) => setEducation(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <h4 className="mb-3">Recent Sea Service : </h4>
                    <label
                      htmlFor="engineType"
                      className="col-sm-3 col-form-label"
                    >
                      Engine Type:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="engineType"
                        placeholder="Enter engine type"
                        value={engineType}
                        onChange={(e) => setEngineType(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="vesselName"
                      className="col-sm-3 col-form-label"
                    >
                      Vessel Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="vesselName"
                        placeholder="Enter vessel name"
                        value={vesselName}
                        onChange={(e) => setVesselName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="typeOfVessel"
                      className="col-sm-3 col-form-label"
                    >
                      Type of Vessel:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="typeOfVessel"
                        placeholder="Enter type of vessel"
                        value={typeOfVessel}
                        onChange={(e) => setTypeOfVessel(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="rankOrPosition"
                      className="col-sm-3 col-form-label"
                    >
                      Rank or Position:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="rankOrPosition"
                        placeholder="Enter rank or position"
                        value={rankOrPosition}
                        onChange={(e) => setRankOrPosition(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="companyName"
                      className="col-sm-3 col-form-label"
                    >
                      Company Name:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        placeholder="Enter company name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      htmlFor="lookingForPosition"
                      className="col-sm-3 col-form-label"
                    >
                      Looking for Position:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="lookingForPosition"
                        placeholder="Enter desired position"
                        value={lookingForPosition}
                        onChange={(e) => setLookingForPosition(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModalTwo}
              >
                Close
              </button>
              {step === 1 ? (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleExtract}
                >
                  Next
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CVPostOCRModal;
