import React, { useState, useEffect } from "react";
import "./Landing.css";
import LoginModal from "../Login/LoginModal";
import RegisterModal from "../Registration/RegisterModal";
import JobsCarousel from "./JobsCarousel/JobsCarousel";
import LandingImage from "../../Assets/landing-background.jpg";
import NavbarLanding from "../../Components/NavbarLanding/NavbarLanding";
import Footer from "../../Components/Footer/Footer";

function Landing() {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [isPageBlurred, setIsPageBlurred] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
    setIsPageBlurred(false);
  };

  const openRegisterModal = () => {
    setRegisterModalOpen(true);
    setIsPageBlurred(true);
  };

  const closeRegisterModal = () => {
    setRegisterModalOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && loginModalOpen) {
        closeLoginModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [loginModalOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && registerModalOpen) {
        closeRegisterModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [registerModalOpen]);

  // FAQ data
  const faqData = [
    {
      question: "How can I post a job as a company?",
      answer:
        "To post a job, simply sign up as a company, navigate to the dashboard, and click on the 'Post a Job' button. Fill in the required details about the job, and your posting will be live.",
    },
    {
      question: "Is it free for companies to post job openings?",
      answer:
        "Yes, it's completely free for companies to post job openings on our platform.",
    },
    {
      question: "Can applicants apply directly through the website?",
      answer:
        "Yes, applicants can apply directly to job postings through our website by submitting their resumes and cover letters.",
    },
    {
      question: "How can I search for jobs as an applicant?",
      answer:
        "As an applicant, you can search for jobs using our search bar, filtering options, or by browsing through categories on the homepage.",
    },
    {
      question: "Do I need to create an account to apply for jobs?",
      answer:
        "Yes, you need to create an account as an applicant to apply for jobs. This helps companies manage applications efficiently.",
    },
    {
      question: "Can I upload my resume to my profile?",
      answer:
        "Absolutely! You can upload your resume to your profile, making it easier for companies to review your qualifications.",
    },
    {
      question: "How can I edit my profile information?",
      answer:
        "To edit your profile information, simply log in, go to your dashboard, and select the 'Edit Profile' option. Make the necessary changes and save them.",
    },
    {
      question: "How can I improve my chances of getting hired?",
      answer:
        "To improve your chances of getting hired, make sure your profile is complete and up-to-date, customize your applications, and prepare well for interviews.",
    },
    {
      question: "Is there a limit to the number of jobs I can apply for?",
      answer:
        "No, there's no limit to the number of jobs you can apply for. Feel free to apply to as many relevant positions as you'd like.",
    },
    {
      question: "How does the chatbot feature work?",
      answer:
        "Our chatbot is designed to answer your questions about job opportunities, application procedures, and general inquiries. Simply initiate a conversation, and the bot will assist you accordingly.",
    },
    {
      question: "How can I search for job opportunities as an applicant?",
      answer:
        "As an applicant, you can search for job opportunities by using our search bar and filtering options. You can narrow down your search by criteria such as location, industry, job type, and more to find positions that match your qualifications and preferences.",
    },
  ];

  const toggleAnswer = (index) => {
    const answer = document.getElementById(`answer-${index}`);
    answer.classList.toggle("show");
  };

  // return (
  //     <div>
  //       <NavbarLanding />
  //       <div className={isPageBlurred ? "blur-background" : "landing-page"}>
  //         <div className="row">
  //           <div className="col-lg-6 col-md-6 col-12">
  //             <div className="landing-section-one">
  //               <div className="advertisement">
  //                 {/* <div className="advertisement-card job-card-one">
  //                   Job Advertisement
  //                 </div>
  //                 <div className="advertisement-card job-card-two">
  //                   Job Advertisement
  //                 </div>
  //                 <div className="advertisement-card job-card-three">
  //                   Job Advertisement
  //                 </div>
  //                 <div className="advertisement-card job-card-four">
  //                   Job Advertisement
  //                 </div> */}
  //                 <JobsCarousel />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="col-lg-6 col-md-6 col-12">
  //             <div className="landing-section-two">
  //               {/* <img src={LandingImage} alt="backgroud-image" className="image-backgroud-landing"/> */}
  //               <div className="landing-logo-title">
  //                 <h1>Jobs Maritime</h1>
  //               </div>
  //               <div className="login-section">
  //                 <div className="login-modal">
  //                   <div>
  //                     <p>Sail with JobsMaritime.</p>
  //                     <p>Register now and navigate your career path.</p>
  //                   </div>
  //                   <div className="action-button-section">
  //                     <div className="login-action-buttons">
  //                       <button
  //                         className="button-13"
  //                         role="button"
  //                         onClick={openRegisterModal}
  //                       >
  //                         Register
  //                       </button>
  //                       <button
  //                         className="button-13"
  //                         role="button"
  //                         onClick={openLoginModal}
  //                       >
  //                         Login
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="col-lg-6 col-md-6 col-12">
  //             <div
  //               className="landing-section-three"
  //               style={{ userSelect: "none" }}
  //             >
  //               <h3 style={{ color: "#22b4ed" }}>About JobsMaritime</h3>
  //               <br />
  //               <p>
  //                 JobsMaritime is a comprehensive online platform connecting
  //                 companies in the maritime industry with qualified job seekers.
  //               </p>

  //               <p className="mt-2">
  //                 User-friendly platform for posting and finding maritime jobs.
  //               </p>
  //               <p className="mt-2">
  //                 Promoting diversity and inclusion in the maritime industry.
  //               </p>
  //             </div>
  //           </div>
  //           <div className="col-lg-6 col-md-6 col-12">
  //             <div className="landing-section-four">
  //               <h3>FAQs</h3>
  //               <br />

  //               {faqData.map((faq, index) => (
  //                 <div className="faq-card" key={index}>
  //                   <div className="question" onClick={() => toggleAnswer(index)}>
  //                     <p className="question-text">{faq.question}</p>
  //                     <button className="arrow-button">▼</button>
  //                   </div>
  //                   <div className="answer" id={`answer-${index}`}>
  //                     <p>{faq.answer}</p>
  //                   </div>
  //                 </div>
  //               ))}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <LoginModal modalOpen={loginModalOpen} closeModal={closeLoginModal} />
  //       <RegisterModal
  //         modalTwoOpen={registerModalOpen}
  //         closeModalTwo={closeRegisterModal}
  //       />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className={isPageBlurred ? "blur-background" : "landing-page"}>
        <NavbarLanding />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-12">
              <section
                className="section first-section"
                style={{ userSelect: "none" }}
              >
                <h3 style={{ color: "#22b4ed" }}>About Jobs Maritime</h3>
                <br />
                <p>
                  JobsMaritime is a comprehensive online platform connecting
                  companies in the maritime industry with qualified job seekers.
                  It is an user-friendly platform for posting and finding
                  maritime jobs, which promotes diversity and inclusion in the
                  maritime industry.
                </p>
                <div className="action-button-section">
                  <div className="login-action-buttons">
                    <button
                      className="button-13"
                      role="button"
                      onClick={openRegisterModal}
                    >
                      Register
                    </button>
                    <button
                      className="button-13"
                      role="button"
                      onClick={openLoginModal}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </section>

              {/* Second Section */}
              <section className="section second-section">
                <h3 className="second-section-title">
                  Frequently Asked Questions
                </h3>
                <div className="faq-container">
                  {faqData.map((faq, index) => (
                    <div className="faq-card" key={index}>
                      <div
                        className="question"
                        onClick={() => toggleAnswer(index)}
                      >
                        <p className="question-text">{faq.question}</p>
                        <button className="arrow-button">▼</button>
                      </div>
                      <div className="answer" id={`answer-${index}`}>
                        <p>{faq.answer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
            <div className="col-lg-6 col-12">
              <section className="section third-section">
                <div className="advertisement">
                  <JobsCarousel />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <LoginModal modalOpen={loginModalOpen} closeModal={closeLoginModal} />
      <RegisterModal
        modalTwoOpen={registerModalOpen}
        closeModalTwo={closeRegisterModal}
      />
      <Footer />
    </>
  );
}

export default Landing;
