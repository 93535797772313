import "./Team.css";
import img4 from "../static/keshaw.png";
import img5 from "../static/chirag.png";
import img6 from "../static/img-6.png";
import img7 from "../static/sid.png";
import img8 from "../static/sunny.png";
import img9 from "../static/shweta.png";
import img10 from "../static/pranab.png";

const teamMembers = [
  {
    name: "Sunil Arora",
    description:
      "We aim to redefine maritime recruitment by leveraging innovative strategies that align the industry demands with top-tier talent, driving both growth and excellence.",
    img: img8,
  },
  {
    name: "Shweta Bux",
    description:
      "We focus on building meaningful career paths for maritime professionals, ensuring that job seekers have access to opportunities that match their skills and ambitions.",
    img: img9,
  },
  {
    name: "Pranab Roy",
    description:
      "We are committed to creating a robust talent pipeline for maritime employers, helping them find the right people to navigate the industry challenges and future needs.",
    img: img10,
  },
  {
    name: "Keshaw Soni",
    description:
      "We strive to foster innovation in maritime recruitment, using data-driven insights to connect professionals with the best career opportunities in the industry.",
    img: img4,
  },
  {
    name: "Siddharth Jangid",
    description:
      "We are focused on creating seamless recruitment experiences, ensuring that both job seekers and employers find the perfect match to drive success in the maritime sector.",
    img: img7,
  },
  {
    name: "Chirag Kumar",
    description:
      "We are dedicated to building strong, lasting relationships within the maritime community, helping professionals and companies grow together through our platform.",
    img: img5,
  },
];

function Team() {
  return (
    <div className="team-section">
      <h1>Our Vision</h1>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.img} alt={member.name} className="avatar" />
            <div className="member-info">
              <h3>{member.name}</h3>
              <p>{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
