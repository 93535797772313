// import React, { useState, useEffect } from "react";
// import "./PreferencesEditModal.css";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";

// function PreferencesEditModal({ modalOpen, closeModal }) {
//   const userDataString = localStorage.getItem("user");
//   const userData = JSON.parse(userDataString);
//   const modalDisplay = modalOpen ? "block" : "none";
//   return (
//     <div className="modal" style={{ display: modalDisplay }}>
//       <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5">Edit Preferences</h1>
//             <button
//               type="button"
//               className="btn-close"
//               onClick={closeModal}
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             <h3>Update Preferences here</h3>
//           </div>
//           <div className="modal-footer">
//             <button
//               type="button"
//               className="btn btn-outline-danger"
//               onClick={closeModal}
//             >
//               Close
//             </button>
//             <button type="button" className="btn btn-outline-primary">
//               Save
//             </button>
//           </div>
//         </div>
//       </div>
//       <ToastContainer
//         position="top-center"
//         autoClose={3000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//       />
//     </div>
//   );
// }

// export default PreferencesEditModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

function PreferencesEditModal({ modalOpen, closeModal }) {
  const [selectedJobsPreferences, setSelectedJobsPreferences] = useState([]);
  const [jobsPreferencesList, setJobsPreferencesList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataString = localStorage.getItem("user");
        const userData = JSON.parse(userDataString);
        const userId = userData?.email;

        if (!userId) return;

        const response = await axios.get(
          `${apiUrl}/api/applicant-preferences/${userId}`
        );
        const data = response.data;
        setSelectedJobsPreferences(data.jobPreferences);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error(
            "Preferences not found. Please set your preferences first.",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        } else {
          toast.error("Failed to fetch preferences. Please try again later.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    };

    setJobsPreferencesList([
      "Captain",
      "Chief Officer (First Mate)",
      "Second Officer (Navigation Officer)",
      "Third Officer (Deck Officer)",
      "Deck Cadet",
      "Chief Engineer",
      "Second Engineer",
      "Third Engineer",
      "Engineering Cadet",
      "Electro-Technical Officer (ETO)",
      "Able Seaman (AB)",
      "Ordinary Seaman (OS)",
      "Boatswain (Bosun)",
      "Pumpman",
      "Fitter",
      "Motorman",
      "Electrician",
      "Chief Steward",
      "Steward",
      "Cook",
      "Messman",
      "Ship Agent",
      "Ship Broker",
      "Marine Surveyor",
      "Port Captain",
      "Harbor Pilot",
      "Tug Master",
      "Marine Superintendent",
      "Marine Mechanic",
      "Ship Chandler",
      "Crane Operator",
      "Ship Inspector",
      "Marine Biologist (for research vessels)",
      "Salvage Master",
      "Shipyard Worker",
      "Naval Architect",
      "Marine Electrician",
      "Marine Welder",
      "Marine Painter",
      "Marine Carpenter",
      "Ship Designer",
      "Marine Safety Officer",
      "Marine Environmental Specialist",
      "Maritime Lawyer",
      "Port Engineer",
      "Marine Logistics Coordinator",
      "Marine Traffic Controller",
      "Marine Operations Manager",
      "Marine Insurance Underwriter",
      "Marine Communications Officer",
    ]);

    fetchData();
  }, []);

  const handleJobSelection = (e) => {
    const value = e.target.value;
    if (!selectedJobsPreferences.includes(value)) {
      setSelectedJobsPreferences([...selectedJobsPreferences, value]);
    }
  };

  const addJob = (jobToAdd) => {
    setSelectedJobsPreferences([...selectedJobsPreferences, jobToAdd]);
  };

  const removeJob = (jobToRemove) => {
    // Remove the selected job preference
    setSelectedJobsPreferences(
      selectedJobsPreferences.filter((job) => job !== jobToRemove)
    );
  };

  const updatePreferences = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const userId = userData?.email;

      if (!userId) return;

      await axios.put(`${apiUrl}/api/applicant-preferences/${userId}`, {
        jobPreferences: selectedJobsPreferences,
      });

      toast.success("Job preferences saved successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error updating preferences:", error);
      if (error.response && error.response.status === 404) {
        toast.error(
          "Applicant preferences not found. Please set your preferences first.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else {
        toast.error("Failed to save job preferences. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalOpen ? "block" : "none" }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Edit Preferences</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <select
                multiple
                style={{
                  width: "100%",
                  height: "200px",
                  userSelect: "none",
                }} // Adjust the width and height as needed
                onChange={handleJobSelection}
                // value={selectedJobsPreferences} // Ensure selected options are correctly displayed
              >
                {jobsPreferencesList.map((job, index) => (
                  <option key={index} value={job}>
                    {job}
                  </option>
                ))}
              </select>
              <div
                className="selected-jobs-container"
                style={{ marginTop: "10px" }}
              >
                {selectedJobsPreferences.map((job, index) => (
                  <div key={index} className="selected-job-card">
                    <span className="selected-job-name">{job}</span>
                    <button
                      className="remove-btn"
                      onClick={() => removeJob(job)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <Button variant="outline-danger" onClick={closeModal}>
                Close
              </Button>
              <Button variant="outline-primary" onClick={updatePreferences}>
                Save
              </Button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      </div>
    </>
  );
}

export default PreferencesEditModal;
