// import React, { useState } from "react";
// import axios from "axios";
// import "./ApplicantProfilePicture.css";
// import { toast } from "react-toastify";
// import imageCompression from 'browser-image-compression';

// function ApplicantProfilePicture({ profileModalOpen, closeProfileModal }) {
//   const userDataString = localStorage.getItem("user");
//   const userData = JSON.parse(userDataString);
//   const userEmail = userData?.email;
//   const [imageFile, setImageFile] = useState(null);
//   const [postedDate, setPostedDate] = useState(new Date());
//   const modalDisplay = profileModalOpen ? "block" : "none";

//   const handleImageChange = (event) => {
//     setImageFile(event.target.files[0]);
//   };

//   const handleUpload = async () => {
//     if (!imageFile) {
//       toast.error("Please select an image file.");
//       return;
//     }

//     try {
//       const options = {
//         maxSizeMB: 2,
//         maxWidthOrHeight: 1920,
//         useWebWorker: true
//       };

//       const compressedFile = await imageCompression(imageFile, options);

//       const formData = new FormData();
//       formData.append("image", compressedFile);
//       formData.append("userEmail", userEmail);
//       formData.append("postedDate", postedDate.toISOString());

//       const response = await axios.post("http://localhost:5000/api/upload-applicant-profile", formData, {
//         headers: { 'Content-Type': 'multipart/form-data' }
//       });

//       if (response.status === 200) {
//         toast.success("Image uploaded successfully.");
//         closeProfileModal();
//       } else {
//         toast.error("Failed to upload image.");
//       }
//     } catch (error) {
//       console.error("Error uploading image:", error);
//       toast.error("Failed to upload image.");
//     }
//   };

//   return (
//     <div className="modal" style={{ display: modalDisplay }}>
//       <div className="modal-dialog modal-l modal-dialog-scrollable modal-fullscreen-md-down">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5">Upload Profile Picture</h1>
//             <button
//               type="button"
//               className="btn-close"
//               onClick={closeProfileModal}
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             <input type="file" className="form-control" onChange={handleImageChange} />
//           </div>
//           <div className="modal-footer">
//             <button
//               type="button"
//               className="btn btn-outline-danger"
//               onClick={closeProfileModal}
//             >
//               Close
//             </button>
//             <button type="button" className="btn btn-outline-primary" onClick={handleUpload}>
//               Upload
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ApplicantProfilePicture;

import React, { useState, useCallback } from "react";
import axios from "axios";
import Cropper from "react-easy-crop";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import getCroppedImg from "./cropImage";
import "./ApplicantProfilePicture.css";
const apiUrl = process.env.REACT_APP_API_URL;

function ApplicantProfilePicture({ profileModalOpen, closeProfileModal }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userEmail = userData?.email;
  const [imageFile, setImageFile] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [postedDate, setPostedDate] = useState(new Date());
  const modalDisplay = profileModalOpen ? "block" : "none";

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    setImageFile(file);
  };

  const handleUpload = async () => {
    if (!imageFile) {
      toast.error("Please select an image file.");
      return;
    }

    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(croppedImage, options);

      const formData = new FormData();
      formData.append("image", compressedFile);
      formData.append("userEmail", userEmail);
      formData.append("postedDate", postedDate.toISOString());

      const response = await axios.post(
        `${apiUrl}/api/upload-applicant-profile`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        toast.success("Image uploaded successfully.");
        closeProfileModal();
      } else {
        toast.error("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Failed to upload image.");
    }
  };

  return (
    <>
      {profileModalOpen && (
        <div className="modal-overlay" onClick={closeProfileModal}></div>
      )}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-l modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Upload Profile Picture</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeProfileModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p style={{ userSelect: "none" }}>
                <strong>Note - </strong>Please upload image of size less than 2
                MB.
              </p>
              <input
                type="file"
                className="form-control"
                onChange={handleImageChange}
              />
              {imageSrc && (
                <div className="crop-container">
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeProfileModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicantProfilePicture;
