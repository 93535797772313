import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import NavbarApplicant from "../NavbarApplicant/NavbarApplicant";
import "./Alternates.css";

import Data from "./job_details_JobsInMaritime.csv";
import Data1 from "./job_details_seamanjobsite.csv";
import Data2 from "./job_details_rigzone.csv";
function Alternates() {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isGoClicked, setIsGoClicked] = useState(false);
  const [isGoClicked1, setIsGoClicked1] = useState(false);
  const [isGoClicked2, setIsGoClicked2] = useState(false);
  const [data1, setData1] = useState([]);
  const [showData1, setShowData1] = useState(false);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [data2, setData2] = useState([]);
  const [showData2, setShowData2] = useState(false);
  const [searchTerm2, setSearchTerm2] = useState("");

  const [suggestions, setSuggestions] = useState([]);
  const [suggestions1, setSuggestions1] = useState([]);
  const [suggestions2, setSuggestions2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(Data);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvData = decoder.decode(result.value);
      const parsedData = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
      }).data;
      setData(parsedData);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(Data1);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvData = decoder.decode(result.value);
      const parsedData = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
      }).data;
      setData1(parsedData);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(Data2);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvData = decoder.decode(result.value);
      const parsedData = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
      }).data;
      setData2(parsedData);
    };
    fetchData();
  }, []);
  useEffect(() => {
    // Generate suggestions based on current search term
    if (searchTerm.trim() !== "") {
      const filteredSuggestions = data.filter((row) =>
        row["Job Title"].toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]); // Clear suggestions when search term is empty
    }
  }, [searchTerm, data]);

  useEffect(() => {
    // Generate suggestions based on current search term
    if (searchTerm1.trim() !== "") {
      const filteredSuggestions = data1.filter((row) =>
        row["Job Title"].toLowerCase().includes(searchTerm1.toLowerCase())
      );
      setSuggestions1(filteredSuggestions);
    } else {
      setSuggestions1([]); // Clear suggestions when search term is empty
    }
  }, [searchTerm1, data1]);
  useEffect(() => {
    // Generate suggestions based on current search term
    if (searchTerm2.trim() !== "") {
      const filteredSuggestions = data2.filter((row) =>
        row["Job Title"].toLowerCase().includes(searchTerm2.toLowerCase())
      );
      setSuggestions2(filteredSuggestions);
    } else {
      setSuggestions2([]); // Clear suggestions when search term is empty
    }
  }, [searchTerm2, data2]);

  const toggleShowData = () => {
    setShowData(!showData);
  };
  const toggleShowData1 = () => {
    setShowData1(!showData1);
  };
  const toggleShowData2 = () => {
    setShowData2(!showData2);
  };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === "") {
      setIsGoClicked(false); // Reset when search term is cleared
    }
  };
  const handleGoClick = () => {
    setSearchTerm(searchTerm);
    setIsGoClicked(true);
    setSuggestions([]);
  };
  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion["Job Title"]); // Update search term with clicked suggestion
  };
  const handleSearchChange1 = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm1(newSearchTerm);
    if (newSearchTerm === "") {
      setIsGoClicked1(false); // Reset when search term is cleared
    }
  };
  const handleGoClick1 = () => {
    setSearchTerm1(searchTerm1);
    setIsGoClicked1(true);
    setSuggestions1([]);
  };
  const handleSuggestionClick1 = (suggestion) => {
    setSearchTerm1(suggestion["Job Title"]); // Update search term with clicked suggestion
  };
  const handleSearchChange2 = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm2(newSearchTerm);
    if (newSearchTerm === "") {
      setIsGoClicked2(false); // Reset when search term is cleared
    }
  };
  const handleGoClick2 = () => {
    setSearchTerm2(searchTerm2);
    setIsGoClicked2(true);
    setSuggestions2([]); // Clear suggestions when "Go" is clicked
  };
  const handleSuggestionClick2 = (suggestion) => {
    setSearchTerm2(suggestion["Job Title"]); // Update search term with clicked suggestion
  };
  const filteredData = isGoClicked
    ? data.filter((row) =>
        row["Job Title"].toLowerCase().includes(searchTerm.toLowerCase())
      )
    : data;
  const filteredData1 = isGoClicked1
    ? data1.filter((row) =>
        row["Job Title"].toLowerCase().includes(searchTerm1.toLowerCase())
      )
    : data1;
  const filteredData2 = isGoClicked2
    ? data2.filter((row) =>
        row["Job Title"].toLowerCase().includes(searchTerm2.toLowerCase())
      )
    : data2;

  return (
    <>
      <NavbarApplicant />
      <div className="App">
        <div className="card">
          <div className="card-header">
            <h2>Archive 1</h2>
            <div className="other-page-search">
              <input
                type="text"
                value={searchTerm2}
                onChange={handleSearchChange2}
                placeholder="Search..."
                // onClick={toggleShowData2}
              />
              <button type="submit" onClick={handleGoClick2}>
                Go
              </button>
              {suggestions2.length > 0 && (
                <ul className="suggestions">
                  {suggestions2.map((row, index) => (
                    <li key={index} onClick={() => handleSuggestionClick2(row)}>
                      {row["Job Title"]}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button className="dropdown-icon" onClick={toggleShowData2}>
              {showData2 ? "-" : "+"}
            </button>
          </div>
          {showData2 && filteredData2.length > 0 && (
            <div className="card-content">
              <table className="table">
                <thead>
                  <tr>
                    <th>Job Title</th>
                    <th>Job Location</th>

                    <th>Job Posted On</th>

                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData2.map((row, index) => (
                    <tr key={index}>
                      <td>{row["Job Title"]}</td>
                      <td>{row["Job Location"]}</td>

                      <td>{row["Job Posted On"]}</td>

                      <td>
                        <a
                          href={row["Job Link"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Job
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="close-icon-container">
                <button
                  className="dropdown-close-icon"
                  onClick={toggleShowData2}
                >
                  ^
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="App">
        <div className="card">
          <div className="card-header">
            <h2>Archive 2</h2>
            <div className="other-page-search">
              <input
                type="text"
                value={searchTerm1}
                onChange={handleSearchChange1}
                placeholder="Search..."
                // onClick={toggleShowData1}
              />
              <button type="submit" onClick={handleGoClick1}>
                {" "}
                Go
              </button>
              {suggestions1.length > 0 && (
                <ul className="suggestions">
                  {suggestions1.map((row, index) => (
                    <li key={index} onClick={() => handleSuggestionClick1(row)}>
                      {row["Job Title"]}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button className="dropdown-icon" onClick={toggleShowData1}>
              {showData1 ? "-" : "+"}
            </button>
          </div>
          {showData1 && filteredData1.length > 0 && (
            <div className="card-content">
              <table className="table">
                <thead>
                  <tr>
                    <th>Job Title</th>
                    <th>Company Name</th>
                    <th>Positions Available</th>
                    <th>Job Posted On</th>
                    <th>Last Date</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData1.map((row, index) => (
                    <tr key={index}>
                      <td>{row["Job Title"]}</td>
                      <td>{row["Company Name"]}</td>
                      <td>{row["Positions Available"]}</td>
                      <td>{row["Job Posted On"]}</td>
                      <td>{row["Last Date"]}</td>
                      <td>
                        <a
                          href={row["Job Link"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Job
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="close-icon-container">
                <button
                  className="dropdown-close-icon"
                  onClick={toggleShowData1}
                >
                  ^
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="App">
        <div className="card">
          <div className="card-header">
            <h2>Archive 3</h2>
            <div className="other-page-search">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
              />
              <button type="submit" onClick={handleGoClick}>
                Go
              </button>
              {suggestions.length > 0 && (
                <ul className="suggestions">
                  {suggestions.map((row, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(row)}>
                      {row["Job Title"]}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <button className="dropdown-icon" onClick={toggleShowData}>
              {showData ? "-" : "+"}
            </button>
          </div>
          {showData && filteredData.length > 0 && (
            <div className="card-content">
              <table className="table">
                <thead>
                  <tr>
                    <th>Job Title</th>
                    <th>Job Location</th>
                    <th>Job Type</th>
                    <th>Job Posted On</th>
                    <th>Last Date</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((row, index) => (
                    <tr key={index}>
                      <td>{row["Job Title"]}</td>
                      <td>{row["Job Location"]}</td>
                      <td>{row["Job Type"]}</td>
                      <td>{row["Job Posted On"]}</td>
                      <td>{row["Last Date"]}</td>
                      <td>
                        <a
                          href={row["Link"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Job
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="close-icon-container-last">
                <button
                  className="dropdown-close-icon"
                  onClick={toggleShowData}
                >
                  ^
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Alternates;
