// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import "./JobsCarousel.css";

// const JobsCarousel = () => {
//   const colors = [
//     // "#FFF3E0",
//     // "#FCE4EC",
//     // "#E3F2FD",
//     // "#F0F4C3",
//     // "#D1C4E9",
//     // "#DCEDC8",
//     // "#FFCCBC",
//     // "#BBDEFB",
//     // "#FFE0B2",
//     // "#C8E6C9",
//     "#FFD695",
//     "#FFC0D5",
//     "#ACDCFF",
//     "#F2F8AB",
//     "#D0B7FF",
//     "#E2FFC1",
//     "#FFC0AD",
//     "#A3FFF9",
//     "#F8CC8A",
//     "#B0FFB3",
//   ];

//   const [jobAndLinkPosts, setJobAndLinkPosts] = useState([]);
//   const carouselRef = useRef(null);
//   const navigate = useNavigate();
//   const [hoveredIndex, setHoveredIndex] = useState(null);

//   useEffect(() => {
//     fetchJobAndLinkPosts();
//   }, []);

//   const fetchJobAndLinkPosts = async () => {
//     try {
//       const jobListingsResponse = await fetch(
//         "http://localhost:5000/api/joblistings"
//       );
//       const jobListingsData = await jobListingsResponse.json();

//       const linkPostsResponse = await fetch(
//         "http://localhost:5000/api/linkposts"
//       );
//       const linkPostsData = await linkPostsResponse.json();

//       //const mergedData = [...jobListingsData, ...linkPostsData]

//       const mergedData = [...jobListingsData];
//       mergedData.sort(
//         (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
//       );

//       setJobAndLinkPosts(mergedData);
//     } catch (error) {
//       console.error("Error fetching job listings and link posts:", error);
//     }
//   };

//   const handleCardClick = (item) => {
//     // Redirect to login page
//     navigate("/");
//   };

//   const handleCardHover = (index) => {
//     setHoveredIndex(index);
//   };

//   useEffect(() => {
//     const handleMouseEnter = () => {
//       if (carouselRef.current) {
//         carouselRef.current.style.animationPlayState = "paused";
//       }
//     };

//     const handleMouseLeave = () => {
//       if (carouselRef.current) {
//         carouselRef.current.style.animationPlayState = "running";
//       }
//     };

//     if (carouselRef.current) {
//       carouselRef.current.addEventListener("mouseenter", handleMouseEnter);
//       carouselRef.current.addEventListener("mouseleave", handleMouseLeave);

//       return () => {
//         if (carouselRef.current) {
//           carouselRef.current.removeEventListener(
//             "mouseenter",
//             handleMouseEnter
//           );
//           carouselRef.current.removeEventListener(
//             "mouseleave",
//             handleMouseLeave
//           );
//         }
//       };
//     }
//   }, []);

//   const darkenColor = (color) => {
//     // Function to darken a color
//     const hex = color.substring(1); // remove #
//     const rgb = parseInt(hex, 16); // convert rrggbb to decimal
//     const r = (rgb >> 16) & 0xff; // extract red
//     const g = (rgb >> 8) & 0xff; // extract green
//     const b = (rgb >> 0) & 0xff; // extract blue

//     // darken color
//     const darkenedColor = `rgb(${Math.floor(r * 0.8)}, ${Math.floor(
//       g * 0.8
//     )}, ${Math.floor(b * 0.8)})`;

//     return darkenedColor;
//   };

//   return (
//     <div className="job-carousel-container">
//       <div className="job-carousel" ref={carouselRef}>
//         {jobAndLinkPosts.map((item, index) => (
//           <div
//             key={index}
//             className="ad-job-card"
//             style={{
//               backgroundColor: colors[index % colors.length],
//               border: `2px solid ${
//                 hoveredIndex === index
//                   ? darkenColor(colors[index % colors.length])
//                   : colors[index % colors.length]
//               }`,
//             }}
//             onClick={() => handleCardClick(item)}
//             onMouseEnter={() => handleCardHover(index)}
//             onMouseLeave={() => handleCardHover(null)}
//           >
//             <div className="ad-content">
//               <div className="add-card-info">
//                 <div>
//                   <h4>{item.companyName}</h4>
//                 </div>
//                 <div>{item.jobTitle}</div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default JobsCarousel;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./JobsCarousel.css";
import LoginModalTwo from "../../Login/LoginModalTwo";
import { FaRegCircleUser } from "react-icons/fa6";
const apiUrl = process.env.REACT_APP_API_URL;

const JobsCarousel = () => {
  const colors = [
    // "#FFF3E0",
    // "#FCE4EC",
    // "#E3F2FD",
    // "#F0F4C3",
    // "#D1C4E9",
    // "#DCEDC8",
    // "#FFCCBC",
    // "#BBDEFB",
    // "#FFE0B2",
    // "#C8E6C9",
    "#FFD695",
    "#FFC0D5",
    "#ACDCFF",
    "#F2F8AB",
    "#D0B7FF",
    "#E2FFC1",
    "#FFC0AD",
    "#A3FFF9",
    "#F8CC8A",
    "#B0FFB3",
  ];

  const [jobAndLinkPosts, setJobAndLinkPosts] = useState([]);
  const carouselRef = useRef(null);
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [loginModalTwoOpen, setLoginModalTwoOpen] = useState(false);
  const [isPageBlurred, setIsPageBlurred] = useState(false);
  const [jobId, setJobId] = useState("");
  const [profileImages, setProfileImages] = useState({});

  const openLoginModalTwo = () => {
    setLoginModalTwoOpen(true);
    setIsPageBlurred(true);
  };

  const closeLoginModalTwo = () => {
    setLoginModalTwoOpen(false);
    setIsPageBlurred(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && loginModalTwoOpen) {
        closeLoginModalTwo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [loginModalTwoOpen]);

  useEffect(() => {
    fetchJobAndLinkPosts();
  }, []);

  const fetchJobAndLinkPosts = async () => {
    try {
      const jobListingsResponse = await fetch(
        `${apiUrl}/api/joblistings`
        // "http://localhost:5000/api/joblistings"
      );
      const jobListingsData = await jobListingsResponse.json();

      const linkPostsResponse = await fetch(
        `${apiUrl}/api/joblistings`
        // "http://localhost:5000/api/linkposts"
      );
      const linkPostsData = await linkPostsResponse.json();

      //const mergedData = [...jobListingsData, ...linkPostsData]

      const mergedData = [...jobListingsData];
      mergedData.sort(
        (a, b) => new Date(b.postedDate) - new Date(a.postedDate)
      );

      setJobAndLinkPosts(mergedData);

      const profileImagesData = {};
      for (const post of mergedData) {
        try {
          const imageResponse = await fetch(
            `${apiUrl}/api/upload-company-profile/${post.postedBy}`
          );
          if (imageResponse.ok) {
            const blob = await imageResponse.blob();
            profileImagesData[post.postedBy] = URL.createObjectURL(blob);
          } else {
            console.error(
              "Failed to fetch profile image:",
              imageResponse.statusText
            );
          }
        } catch (error) {
          console.error("Error fetching profile image:", error);
        }
      }
      setProfileImages(profileImagesData);
    } catch (error) {
      console.error("Error fetching job listings and link posts:", error);
    }
  };

  const handleCardClick = (item) => {
    // Redirect to login page
    openLoginModalTwo();
    setJobId(item._id);
    console.log(item._id);
  };

  const handleCardHover = (index) => {
    setHoveredIndex(index);
  };

  useEffect(() => {
    const handleMouseEnter = () => {
      if (carouselRef.current) {
        carouselRef.current.style.animationPlayState = "paused";
      }
    };

    const handleMouseLeave = () => {
      if (carouselRef.current) {
        carouselRef.current.style.animationPlayState = "running";
      }
    };

    if (carouselRef.current) {
      carouselRef.current.addEventListener("mouseenter", handleMouseEnter);
      carouselRef.current.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        if (carouselRef.current) {
          carouselRef.current.removeEventListener(
            "mouseenter",
            handleMouseEnter
          );
          carouselRef.current.removeEventListener(
            "mouseleave",
            handleMouseLeave
          );
        }
      };
    }
  }, []);

  const darkenColor = (color) => {
    // Function to darken a color
    const hex = color.substring(1); // remove #
    const rgb = parseInt(hex, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    // darken color
    const darkenedColor = `rgb(${Math.floor(r * 0.8)}, ${Math.floor(
      g * 0.8
    )}, ${Math.floor(b * 0.8)})`;

    return darkenedColor;
  };

  return (
    <div className="job-carousel-container">
      <div className="job-carousel" ref={carouselRef}>
        {jobAndLinkPosts.map((item, index) => (
          <div
            key={index}
            className="ad-job-card"
            style={{
              backgroundColor: colors[index % colors.length],
              border: `2px solid ${
                hoveredIndex === index
                  ? darkenColor(colors[index % colors.length])
                  : colors[index % colors.length]
              }`,
            }}
            onClick={() => handleCardClick(item)}
            onMouseEnter={() => handleCardHover(index)}
            onMouseLeave={() => handleCardHover(null)}
          >
            <div className="ad-content">
              <div className="add-card-info">
                <div className="ad-company-name-logo">
                  {profileImages[item.postedBy] ? (
                    <img
                      src={profileImages[item.postedBy]}
                      alt="Profile"
                      className="landing-page-job-carousel-profile-image"
                    />
                  ) : (
                    <FaRegCircleUser />
                  )}
                  <h4>{item.companyName}</h4>
                </div>
                <div className="job-title">{item.jobTitle}</div>
              </div>
            </div>
          </div>
        ))}
        {jobAndLinkPosts.map((item, index) => (
          <div
            key={index}
            className="ad-job-card"
            style={{
              backgroundColor: colors[index % colors.length],
              border: `2px solid ${
                hoveredIndex === index
                  ? darkenColor(colors[index % colors.length])
                  : colors[index % colors.length]
              }`,
            }}
            onClick={() => handleCardClick(item)}
            onMouseEnter={() => handleCardHover(index)}
            onMouseLeave={() => handleCardHover(null)}
          >
            <div className="ad-content">
              <div className="add-card-info">
                <div className="ad-company-name-logo">
                  {profileImages[item.postedBy] ? (
                    <img
                      src={profileImages[item.postedBy]}
                      alt="Profile"
                      className="landing-page-job-carousel-profile-image"
                    />
                  ) : (
                    <FaRegCircleUser />
                  )}
                  <h4>{item.companyName}</h4>
                </div>
                <div className="job-title">{item.jobTitle}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <LoginModalTwo
        modalTwoOpen={loginModalTwoOpen}
        closeModalTwo={closeLoginModalTwo}
        jobId={jobId}
      />
    </div>
  );
};

export default JobsCarousel;
