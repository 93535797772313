import React, { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
import NavbarApplicant from "../../../Components/NavbarApplicant/NavbarApplicant";
import "./ResumeBuilder.css";
import { AIChatSession } from "./Service/AIModal";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const prompt =
  "Job Title: {jobTitle} , According on job title give me list of summery for 3 experienced level, Mid Level and Fresher level in 3-4 lines in array format, With summery and experience_level Field in JSON Format";

const ResumeBuilder = () => {
  const [formData, setFormData] = useState({});
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userEmail = userData.email;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [summary, setSummary] = useState("");
  const [step, setStep] = useState(1);
  const [aiGeneratedSummeryList, setAiGenerateSummeryList] = useState();
  const [experiences, setExperiences] = useState([
    {
      id: Date.now(),
      positionTitle: "",
      companyName: "",
      nameOfVessel: "",
      flagOfVessel: "",
      typeOfVessel: "",
      typeOfEngine: "",
      dwt: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [educations, setEducations] = useState([
    {
      id: Date.now(),
      university: "",
      degree: "",
      major: "",
      startDate: "",
      endDate: "",
      description: "",
    },
  ]);
  const [certificates, setCertificates] = useState([
    {
      id: Date.now(),
      name: "",
      number: "",
      placeOfIssue: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchResume = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/get-resume?userEmail=${userEmail}`
        );
        if (response.data.resume) {
          const resume = response.data.resume;

          // Format startDate and endDate in experiences
          const formattedExperiences = resume.experiences.map((exp) => ({
            ...exp,
            startDate: exp.startDate
              ? new Date(exp.startDate).toISOString().split("T")[0]
              : "",
            endDate: exp.endDate
              ? new Date(exp.endDate).toISOString().split("T")[0]
              : "",
          }));

          const formattedEducations = resume.educations.map((edu) => ({
            ...edu,
            startDate: edu.startDate
              ? new Date(edu.startDate).toISOString().split("T")[0]
              : "",
            endDate: edu.endDate
              ? new Date(edu.endDate).toISOString().split("T")[0]
              : "",
          }));

          const formattedCertificates = resume.certificates.map((cer) => ({
            ...cer,
            startDate: cer.startDate
              ? new Date(cer.startDate).toISOString().split("T")[0]
              : "",
            endDate: cer.endDate
              ? new Date(cer.endDate).toISOString().split("T")[0]
              : "",
          }));

          setFirstName(resume.firstName);
          setLastName(resume.lastName);
          setJobTitle(resume.jobTitle);
          setAddress(resume.address);
          setContact(resume.contact);
          setEmail(resume.email);
          setSummary(resume.summary);
          setExperiences(formattedExperiences); // Set formatted experiences
          setEducations(formattedEducations);
          setCertificates(formattedCertificates);
          setIsUpdating(true);
        }
      } catch (error) {
        console.error("Error fetching resume:", error);
      }
    };

    fetchResume();
  }, [userEmail, apiUrl]);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const GenerateSummeryFromAI = async () => {
    const loadingToast = toast.loading("Fetching summary from AI...", {
      position: "top-center",
      theme: "dark",
    });
    try {
      const PROMPT = prompt.replace("{jobTitle}", jobTitle);
      console.log(PROMPT);

      const result = await AIChatSession.sendMessage(PROMPT);

      // Parse JSON response directly from result.response
      console.log(JSON.parse(result.response.text()));

      setAiGenerateSummeryList(JSON.parse(result.response.text()));
      // Dismiss loading toast and show success toast
      toast.dismiss(loadingToast);
      toast.success("Summary form AI fetched successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error generating summary from AI:", error);
      toast.error("Error generating summary from AI:", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleExperienceChange = (index, event) => {
    const { name, value } = event.target;
    const updatedExperiences = [...experiences];
    updatedExperiences[index][name] = value;
    setExperiences(updatedExperiences);
  };
  const addExperience = () => {
    setExperiences([
      ...experiences,
      {
        id: Date.now(),
        positionTitle: "",
        companyName: "",
        city: "",
        state: "",
        startDate: "",
        endDate: "",
        summary: "",
      },
    ]);
  };

  const removeExperience = (index) => {
    const updatedExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(updatedExperiences);
  };
  const handleEducationChange = (index, event) => {
    const { name, value } = event.target;
    const updatedEducations = [...educations];
    updatedEducations[index][name] = value;
    setEducations(updatedEducations);
  };

  const addEducation = () => {
    setEducations([
      ...educations,
      {
        id: Date.now(),
        university: "",
        degree: "",
        major: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ]);
  };

  const removeEducation = (index) => {
    const updatedEducations = educations.filter((_, i) => i !== index);
    setEducations(updatedEducations);
  };

  const handleCertificateChange = (index, event) => {
    const { name, value } = event.target;
    const updatedCertificates = [...certificates];
    updatedCertificates[index][name] = value;
    setCertificates(updatedCertificates);
  };

  const addCertificate = () => {
    setCertificates([
      ...certificates,
      {
        id: Date.now(),
        name: "",
        number: "",
        placeOfIssue: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const removeCertificate = (index) => {
    const updatedCertificates = certificates.filter((_, i) => i !== index);
    setCertificates(updatedCertificates);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const combinedData = {
      personalInfo: {
        userEmail,
        firstName,
        lastName,
        jobTitle,
        address,
        contact,
        email,
        summary,
      },
      experiences,
      educations,
      certificates,
    };

    try {
      const url = `${apiUrl}/api/${
        isUpdating ? "update-resume" : "post-resume"
      }`;
      const response = await axios.post(url, combinedData);
      console.log(
        `${isUpdating ? "Resume updated" : "Resume submitted"} successfully:`,
        response.data
      );

      toast.success(
        `${isUpdating ? "Resume updated" : "Resume submitted"} successfully!`,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      // Reset form fields if necessary
      setFormData({});
      setFirstName("");
      setLastName("");
      setJobTitle("");
      setAddress("");
      setContact("");
      setEmail("");
      setSummary("");
      setExperiences([
        {
          id: Date.now(),
          positionTitle: "",
          companyName: "",
          city: "",
          state: "",
          startDate: "",
          endDate: "",
          summary: "",
        },
      ]);
      setEducations([
        {
          id: Date.now(),
          university: "",
          degree: "",
          major: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ]);
      setCertificates([
        {
          id: Date.now(),
          name: "",
          number: "",
          placeOfIssue: "",
          startDate: "",
          endDate: "",
        },
      ]);
      setStep(1);
    } catch (error) {
      console.error("Error submitting/updating resume:", error);
    }
  };

  const handleDownload = (event) => {
    event.preventDefault(); // Prevent form submission

    const element = document.querySelector(".pdf-content-wrapper"); // Select the section you want to convert to PDF
    element.style.padding = "30px";
    const options = {
      filename: `${firstName}_${lastName}_Resume.pdf`, // Customize the filename
      margin: 0,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Generate PDF
    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {
        // Restore styles after PDF generation
        element.style.padding = "0";
      });
  };

  return (
    <div>
      <NavbarApplicant />

      <div className="resume-builder-container">
        {/* Input Section */}

        <div className="input-section">
          {step === 1 && (
            <>
              <h2>Personal Detail</h2>
              <p>Get Started with the basic information</p>
            </>
          )}
          {step === 2 && (
            <>
              <h2>Summary</h2>
              <p>Add Summary for your job title</p>
            </>
          )}
          {step === 3 && (
            <>
              <h2>Professional Experience</h2>
              <p>Add Your previous Job experience</p>
            </>
          )}
          {step === 4 && (
            <>
              <h2>Education</h2>
              <p>Add Your educational details</p>
            </>
          )}
          {step === 5 && (
            <>
              <h2>Certification</h2>
              <p>Add Your top professional Certificates</p>
            </>
          )}

          <form onSubmit={handleFormSubmit}>
            {step === 1 && (
              <>
                <div className="userName">
                  <div>
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Enter your First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Enter your Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label>Job Title</label>
                  <input
                    type="text"
                    name="jobTitle"
                    placeholder="Enter Job Title"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                  />
                </div>
                <div>
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter your address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="userContactInfo">
                  <div>
                    <label>Contact Number</label>
                    <input
                      type="tel"
                      name="contact"
                      placeholder="Enter your contact number"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <div>
                  <div className="summary-header">
                    <label>Add Summary</label>
                    <button
                      type="button"
                      className="ai-button"
                      onClick={() => GenerateSummeryFromAI()}
                    >
                      Generate from AI
                    </button>
                  </div>
                  <div className="summary-container">
                    <textarea
                      type="text"
                      name="summary"
                      placeholder="Enter your professional summary"
                      value={summary}
                      onChange={(e) => setSummary(e.target.value)}
                    />
                  </div>
                  {aiGeneratedSummeryList && (
                    <div className="suggestions-container my-5">
                      <h2 className="font-bold text-lg">Suggestions</h2>
                      {aiGeneratedSummeryList.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => setSummary(item.summary)}
                          className="p-5 shadow-lg my-4 rounded-lg cursor-pointer"
                        >
                          <h2 className="font-bold my-1 text-primary">
                            Level: {item.experience_level}
                          </h2>
                          <p style={{ cursor: "pointer" }}>{item.summary}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
            {step === 3 && (
              <>
                {experiences.map((experience, index) => (
                  <div key={experience.id} className="experience-section">
                    <div className="experienceGroupOne">
                      <div>
                        <label>Rank</label>
                        <input
                          type="text"
                          name="positionTitle"
                          placeholder="Rank"
                          value={experience.positionTitle}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>Company Name</label>
                        <input
                          type="text"
                          name="companyName"
                          placeholder="Company Name"
                          value={experience.companyName}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                    </div>

                    <div className="experienceGroupOne">
                      <div>
                        <label>Name of Vessel</label>
                        <input
                          type="text"
                          name="nameOfVessel"
                          placeholder="Name of Vessel"
                          value={experience.nameOfVessel}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>Flag of Vessel</label>
                        <input
                          type="text"
                          name="flagOfVessel"
                          placeholder="Flag of Vessel"
                          value={experience.flagOfVessel}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                    </div>

                    <div className="experienceGroupOne">
                      <div>
                        <label>Type of Vessel</label>
                        <input
                          type="text"
                          name="typeOfVessel"
                          placeholder="Type of Vessel"
                          value={experience.typeOfVessel}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>DWT</label>
                        <input
                          type="text"
                          name="dwt"
                          placeholder="DWT"
                          value={experience.dwt}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Type of Engine</label>
                      <input
                        type="text"
                        name="typeOfEngine"
                        placeholder="Flag of Vessel"
                        value={experience.typeOfEngine}
                        onChange={(e) => handleExperienceChange(index, e)}
                      />
                    </div>
                    <div className="experienceGroupOne">
                      <div>
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="startDate"
                          value={experience.startDate || ""}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <input
                          type="date"
                          name="endDate"
                          value={experience.endDate}
                          onChange={(e) => handleExperienceChange(index, e)}
                        />
                      </div>
                    </div>

                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeExperience(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addExperience}
                  className="add-experience-button"
                >
                  Add More Experience
                </button>
              </>
            )}
            {step === 4 && (
              <>
                {educations.map((education, index) => (
                  <div key={education.id} className="education-section">
                    <div>
                      <label>University Name</label>
                      <input
                        type="text"
                        name="university"
                        placeholder="University Name"
                        value={education.university}
                        onChange={(e) => handleEducationChange(index, e)}
                      />
                    </div>
                    <div className="educationGroupOne">
                      <div>
                        <label>Degree</label>
                        <input
                          type="text"
                          name="degree"
                          placeholder="Degree"
                          value={education.degree}
                          onChange={(e) => handleEducationChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>Major</label>
                        <input
                          type="text"
                          name="major"
                          placeholder="Major"
                          value={education.major}
                          onChange={(e) => handleEducationChange(index, e)}
                        />
                      </div>
                    </div>
                    <div className="educationGroupOne">
                      <div>
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="startDate"
                          value={education.startDate}
                          onChange={(e) => handleEducationChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <input
                          type="date"
                          name="endDate"
                          value={education.endDate}
                          onChange={(e) => handleEducationChange(index, e)}
                        />
                      </div>
                    </div>

                    <div>
                      <label>Description</label>
                      <textarea
                        name="description"
                        placeholder="Description"
                        value={education.description}
                        onChange={(e) => handleEducationChange(index, e)}
                      />
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeEducation(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addEducation}
                  className="add-education-button"
                >
                  Add More Education
                </button>
              </>
            )}
            {step === 5 && (
              <>
                {certificates.map((certificate, index) => (
                  <div key={certificate.id} className="education-section">
                    <div>
                      <label>Certificate Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Certificate Name"
                        value={certificate.name}
                        onChange={(e) => handleCertificateChange(index, e)}
                      />
                    </div>
                    <div className="educationGroupOne">
                      <div>
                        <label>Number</label>
                        <input
                          type="text"
                          name="number"
                          placeholder="Enter Certificate Number"
                          value={certificate.number}
                          onChange={(e) => handleCertificateChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>Place of Issue</label>
                        <input
                          type="text"
                          name="placeOfIssue"
                          placeholder="Place of Issue"
                          value={certificate.placeOfIssue}
                          onChange={(e) => handleCertificateChange(index, e)}
                        />
                      </div>
                    </div>
                    <div className="educationGroupOne">
                      <div>
                        <label>Date of Issue</label>
                        <input
                          type="date"
                          name="startDate"
                          value={certificate.startDate}
                          onChange={(e) => handleCertificateChange(index, e)}
                        />
                      </div>
                      <div>
                        <label>Date of Expire</label>
                        <input
                          type="date"
                          name="endDate"
                          value={certificate.endDate}
                          onChange={(e) => handleCertificateChange(index, e)}
                        />
                      </div>
                    </div>

                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeCertificate(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addCertificate}
                  className="add-education-button"
                >
                  Add More Certificate
                </button>
              </>
            )}

            {/* Buttons */}
            <div className="button-group">
              {step > 1 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="prev-button"
                >
                  Previous
                </button>
              )}
              {step < 5 && (
                <button
                  type="button"
                  onClick={nextStep}
                  className="next-button"
                >
                  Next
                </button>
              )}

              {step === 5 && (
                <div className="button-group-step">
                  <button
                    className="resume-builder-download-button"
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                  <button
                    type="submit"
                    className="save-button"
                    onClick={handleFormSubmit}
                  >
                    {isUpdating ? "Update" : "Save"}
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
        <button className="preview-resume-button" onClick={openModal}>
          Preview Resume
        </button>
        {/* PDF Preview Section */}
        <div
          className={`pdf-preview-section ${isModalOpen ? "modal-open" : ""}`}
        >
          {/* PDF preview content goes here */}
          <div className="pdf-content-wrapper">
            <div className="header">
              <h2 className="name">
                {firstName || "James"} {lastName || "Carter"}
              </h2>
              <h2 className="resume-job-title">
                {jobTitle || "Chief Officer"}
              </h2>
              <p className="contact-info">
                {address || "525 N tryon Street, NC 28117"}
              </p>
              <p className="contact-info">
                {contact || "(123)-456-7890"} | {email || "exmaple@gmail.com"}
              </p>
            </div>

            <div className="summary">
              <h2 className="section-title">Summary</h2>
              <p className="summary-content">
                {summary ||
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
              </p>
            </div>

            <div className="experience">
              <h2 className="section-title">Professional Experience</h2>
              <table className="experience-table">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Company</th>
                    <th>Name of Vessel</th>
                    <th>Flag of Vessel</th>
                    <th>Type of Vessel</th>
                    <th>Type of Engine</th>
                    <th>DWT</th>
                    <th>From</th>
                    <th>To</th>
                  </tr>
                </thead>
                <tbody>
                  {experiences.map((experience, index) => (
                    <tr key={index}>
                      <td>{experience.positionTitle || "Eng. Cadet"}</td>
                      <td>{experience.companyName || "M.O.L"}</td>
                      <td>{experience.nameOfVessel || "Sunlight Ace"}</td>
                      <td>{experience.flagOfVessel || "Bahamas"}</td>
                      <td>{experience.typeOfVessel || "Car Carrier"}</td>
                      <td>{experience.typeOfEngine || "MAN BsW, 15130 KWt"}</td>
                      <td>{experience.dwt || "18855"}</td>
                      <td>{experience.startDate || "28/03/2014"}</td>
                      <td>{experience.endDate || "19/06/2014"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="education">
              <h2 className="section-title">Education</h2>
              {educations.map((education, index) => (
                <div key={index} className="education-item">
                  <h3 className="university-name">
                    {education.university || "University of North Carolina"}
                  </h3>
                  <p className="degree-info">
                    {education.degree || "Bachelor of Science"} in{" "}
                    {education.major || "Computer Science"}
                    <span className="date-range">
                      {" "}
                      ({education.startDate || "Aug 2014"} -{" "}
                      {education.endDate || "May 2018"})
                    </span>
                  </p>

                  <p className="education-description">
                    {education.description ||
                      "Focused on software development, algorithms, and data structures. Completed a capstone project on machine learning applications in data analysis."}
                  </p>
                </div>
              ))}
            </div>

            <div className="experience">
              <h2 className="section-title">Professional Certificates</h2>
              <table className="experience-table">
                <thead>
                  <tr>
                    <th>Certificates</th>
                    <th>Number</th>
                    <th>Place of Issue</th>
                    <th>Date of Issue</th>
                    <th>Date of Expire</th>
                  </tr>
                </thead>
                <tbody>
                  {certificates.map((certificate, index) => (
                    <tr key={index}>
                      <td>{certificate.name || "Basic Safety Training"}</td>
                      <td>{certificate.number || "1337203"}</td>
                      <td>
                        {certificate.placeOfIssue || "Black Sea Training"}
                      </td>
                      <td>{certificate.startDate || "28/03/2014"}</td>
                      <td>{certificate.endDate || "19/06/2014"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="pdf-preview-modal">
            <div className="pdf-preview-content">
              {/* PDF preview content goes here */}
              <div className="pdf-content-wrapper">
                <div className="header">
                  <h2 className="name">
                    {firstName || "James"} {lastName || "Carter"}
                  </h2>
                  <h2 className="resume-job-title">
                    {jobTitle || "Full Stack developer"}
                  </h2>
                  <p className="contact-info">
                    {address || "525 N tryon Street, NC 28117"}
                  </p>
                  <p className="contact-info">
                    {contact || "(123)-456-7890"} |{" "}
                    {email || "exmaple@gmail.com"}
                  </p>
                </div>

                <div className="summary">
                  <h2 className="section-title">Summary</h2>
                  <p className="summary-content">
                    {summary ||
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
                  </p>
                </div>

                <div className="experience">
                  <h2 className="section-title">Professional Experience</h2>
                  <table className="experience-table">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Company</th>
                        <th>Name of Vessel</th>
                        <th>Flag of Vessel</th>
                        <th>Type of Vessel</th>
                        <th>Type of Engine</th>
                        <th>DWT</th>
                        <th>From</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {experiences.map((experience, index) => (
                        <tr key={index}>
                          <td>{experience.rank || "Eng. Cadet"}</td>
                          <td>{experience.company || "M.O.L"}</td>
                          <td>{experience.nameOfVessel || "Sunlight Ace"}</td>
                          <td>{experience.flagOfVessel || "Bahamas"}</td>
                          <td>{experience.typeOfVessel || "Car Carrier"}</td>
                          <td>
                            {experience.typeOfEngine || "MAN BsW, 15130 KWt"}
                          </td>
                          <td>{experience.dwt || "18855"}</td>
                          <td>{experience.startDate || "28/03/2014"}</td>
                          <td>{experience.endDate || "19/06/2014"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="education">
                  <h2 className="section-title">Education</h2>
                  {educations.map((education, index) => (
                    <div key={index} className="education-item">
                      <h3 className="university-name">
                        {education.university || "University of North Carolina"}
                      </h3>
                      <p className="degree-info">
                        {education.degree || "Bachelor of Science"} in{" "}
                        {education.major || "Computer Science"}
                        <span className="date-range">
                          {" "}
                          ({education.startDate || "Aug 2014"} -{" "}
                          {education.endDate || "May 2018"})
                        </span>
                      </p>

                      <p className="education-description">
                        {education.description ||
                          "Focused on software development, algorithms, and data structures. Completed a capstone project on machine learning applications in data analysis."}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="experience">
                  <h2 className="section-title">Professional Certificates</h2>
                  <table className="experience-table">
                    <thead>
                      <tr>
                        <th>Certificates</th>
                        <th>Number</th>
                        <th>Place of Issue</th>
                        <th>Date of Issue</th>
                        <th>Date of Expire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {certificates.map((certificate, index) => (
                        <tr key={index}>
                          <td>{certificate.name || "Basic Safety Training"}</td>
                          <td>{certificate.number || "1337203"}</td>
                          <td>
                            {certificate.placeOfIssue || "Black Sea Training"}
                          </td>
                          <td>{certificate.startDate || "28/03/2014"}</td>
                          <td>{certificate.endDate || "19/06/2014"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <button className="close-modal-button" onClick={closeModal}>
              Close Preview
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeBuilder;
