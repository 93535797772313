import React, { useState, useEffect } from "react";
import "./FormPostModal.css";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

function FormPostModal({ modalOpen, closeModal }) {
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const modalDisplay = modalOpen ? "block" : "none";

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    fathersName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    maritalStatus: "",
    email: "",
    mobile: "",
    address: "",
    height: "",
    weight: "",
    education: [],
    certificates: [],
    experience: [],
    postedDate: new Date(), // Initialize postedDate with current date
    postedBy: userData?.email, // Set postedBy to user's email from localStorage
  });

  const [educationFields, setEducationFields] = useState([
    { institute: "", degree: "", fromDate: "", toDate: "" },
  ]);
  const [certificateFields, setCertificateFields] = useState([
    { issuedDate: "", validUntil: "", issuedPlace: "", certificateID: "" },
  ]);
  const [experienceFields, setExperienceFields] = useState([
    { rank: "", vesselName: "", vesselType: "", company: "", engineType: "" },
  ]);

  const addEducationField = () => {
    setEducationFields([
      ...educationFields,
      { institute: "", degree: "", fromDate: "", toDate: "" },
    ]);
  };

  const addCertificateField = () => {
    setCertificateFields([
      ...certificateFields,
      { issuedDate: "", validUntil: "", issuedPlace: "", certificateID: "" },
    ]);
  };

  const addExperienceField = () => {
    setExperienceFields([
      ...experienceFields,
      { rank: "", vesselName: "", vesselType: "", company: "", engineType: "" },
    ]);
  };

  const isFormValid = () => {
    // Check if any required fields are empty
    const requiredFields = [
      "name",
      "lastName",
      "fathersName",
      "dateOfBirth",
      "placeOfBirth",
      "maritalStatus",
      "email",
      "mobile",
      "address",
      "height",
      "weight",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field} is required.`);
        return false;
      }
    }
    return true;
  };

  const handleSave = async () => {
    if (!isFormValid()) {
      return;
    }

    const updatedFormData = {
      ...formData,
      education: educationFields,
      certificates: certificateFields,
      experience: experienceFields,
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/applicantFormDetails`,
        updatedFormData
      );
      if (response.status === 201) {
        setFormData({
          name: "",
          lastName: "",
          fathersName: "",
          dateOfBirth: "",
          placeOfBirth: "",
          maritalStatus: "",
          email: "",
          mobile: "",
          address: "",
          height: "",
          weight: "",
          education: [],
          certificates: [],
          experience: [],
          postedDate: new Date(),
          postedBy: userData?.email,
        });
        setEducationFields([
          { institute: "", degree: "", fromDate: "", toDate: "" },
        ]);
        setCertificateFields([
          {
            issuedDate: "",
            validUntil: "",
            issuedPlace: "",
            certificateID: "",
          },
        ]);
        setExperienceFields([
          {
            rank: "",
            vesselName: "",
            vesselType: "",
            company: "",
            engineType: "",
          },
        ]);
        toast.success("Form data saved successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error saving form data:", error);
      toast.error("Failed to save form data. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      {modalOpen && <div className="modal-overlay" onClick={closeModal}></div>}
      <div className="modal" style={{ display: modalDisplay }}>
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Enter personal information</h1>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Name:</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Last Name:</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your last name"
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData({ ...formData, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Father's Name:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your father's name"
                      value={formData.fathersName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fathersName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Date of Birth:
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="date"
                      className="form-control"
                      value={formData.dateOfBirth}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          dateOfBirth: e.target.value,
                        })
                      }
                    />
                  </div>
                  <label className="col-sm-3 col-form-label">
                    Place of Birth:
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your place of birth"
                      value={formData.placeOfBirth}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          placeOfBirth: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Marital Status:
                  </label>
                  <div className="col-sm-3">
                    <select
                      className="form-select"
                      value={formData.maritalStatus}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          maritalStatus: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                      <option value="widowed">Widowed</option>
                    </select>
                  </div>
                  <label className="col-sm-3 col-form-label">E-Mail:</label>
                  <div className="col-sm-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Mobile:</label>
                  <div className="col-sm-3">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      value={formData.mobile}
                      onChange={(e) =>
                        setFormData({ ...formData, mobile: e.target.value })
                      }
                    />
                  </div>
                  <label className="col-sm-3 col-form-label">Address:</label>
                  <div className="col-sm-3">
                    <textarea
                      className="form-control"
                      placeholder="Enter your address"
                      value={formData.address}
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Height:</label>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter your height"
                      value={formData.height}
                      onChange={(e) =>
                        setFormData({ ...formData, height: e.target.value })
                      }
                    />
                  </div>
                  <label className="col-sm-3 col-form-label">Weight:</label>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter your weight"
                      value={formData.weight}
                      onChange={(e) =>
                        setFormData({ ...formData, weight: e.target.value })
                      }
                    />
                  </div>
                </div>

                {/* Education */}
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">EDUCATION:</label>
                  <div className="col-sm-9">
                    {educationFields.map((field, index) => (
                      <div className="row mb-3" key={index}>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Institute"
                            value={field.institute}
                            onChange={(e) => {
                              const updatedFields = [...educationFields];
                              updatedFields[index].institute = e.target.value;
                              setEducationFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Stream/Degree/Course"
                            value={field.degree}
                            onChange={(e) => {
                              const updatedFields = [...educationFields];
                              updatedFields[index].degree = e.target.value;
                              setEducationFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Year from"
                            value={field.fromDate}
                            onChange={(e) => {
                              const updatedFields = [...educationFields];
                              updatedFields[index].fromDate = e.target.value;
                              setEducationFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Year to"
                            value={field.toDate}
                            onChange={(e) => {
                              const updatedFields = [...educationFields];
                              updatedFields[index].toDate = e.target.value;
                              setEducationFields(updatedFields);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={addEducationField}
                    >
                      Add Education
                    </button>
                  </div>
                </div>

                {/* Certificates */}
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    CERTIFICATE:
                  </label>
                  <div className="col-sm-9">
                    {certificateFields.map((field, index) => (
                      <div className="row mb-3" key={index}>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Issued Date"
                            value={field.issuedDate}
                            onChange={(e) => {
                              const updatedFields = [...certificateFields];
                              updatedFields[index].issuedDate = e.target.value;
                              setCertificateFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Valid Until"
                            value={field.validUntil}
                            onChange={(e) => {
                              const updatedFields = [...certificateFields];
                              updatedFields[index].validUntil = e.target.value;
                              setCertificateFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Issued Place"
                            value={field.issuedPlace}
                            onChange={(e) => {
                              const updatedFields = [...certificateFields];
                              updatedFields[index].issuedPlace = e.target.value;
                              setCertificateFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Certificate ID"
                            value={field.certificateID}
                            onChange={(e) => {
                              const updatedFields = [...certificateFields];
                              updatedFields[index].certificateID =
                                e.target.value;
                              setCertificateFields(updatedFields);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={addCertificateField}
                    >
                      Add Certificate
                    </button>
                  </div>
                </div>

                {/* Experience */}
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">EXPERIENCE:</label>
                  <div className="col-sm-9">
                    {experienceFields.map((field, index) => (
                      <div className="row mb-3" key={index}>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Rank"
                            value={field.rank}
                            onChange={(e) => {
                              const updatedFields = [...experienceFields];
                              updatedFields[index].rank = e.target.value;
                              setExperienceFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name of Vessel"
                            value={field.vesselName}
                            onChange={(e) => {
                              const updatedFields = [...experienceFields];
                              updatedFields[index].vesselName = e.target.value;
                              setExperienceFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type of vessel"
                            value={field.vesselType}
                            onChange={(e) => {
                              const updatedFields = [...experienceFields];
                              updatedFields[index].vesselType = e.target.value;
                              setExperienceFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Shipping Company / Crewing Agency"
                            value={field.company}
                            onChange={(e) => {
                              const updatedFields = [...experienceFields];
                              updatedFields[index].company = e.target.value;
                              setExperienceFields(updatedFields);
                            }}
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Engine Type"
                            value={field.engineType}
                            onChange={(e) => {
                              const updatedFields = [...experienceFields];
                              updatedFields[index].engineType = e.target.value;
                              setExperienceFields(updatedFields);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={addExperienceField}
                    >
                      Add Experience
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      </div>
    </>
  );
}

export default FormPostModal;
